// @flow

import { combineReducers } from 'redux';
import modalReducers from '@tmatt-tech/react_common/dist/redux/ui/modal/modalReducers';
import formReducers from "redux/ui/form/formReducers";

export default combineReducers({
  modal: modalReducers,
  form: formReducers,
});
