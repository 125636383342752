import { all, call, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  DOWNLOAD_DISPATCHING_ORDER_SEA_FREIGHTS,
  DOWNLOAD_PROCESSING_ORDER_SEA_FREIGHTS,
  LIST_ORDER_SEA_FREIGHTS,
  LIST_ORDER_SEA_FREIGHTS_CAN_BE_MERGED,
  LIST_UNCONTAINED_ORDER_SEA_FREIGHTS
} from "redux/model/orderSeaFreights/orderSeaFreights/constants";
import {
  apiDownloadDispatchingOrderSeaFreights,
  apiDownloadProcessingOrderSeaFreights,
  apiListOrderSeaFreights,
  apiListOrderSeaFreightsCanBeMerged,
  apiListUncontainedOrderSeaFreights
} from "api/orderSeaFreight/apiOrderSeaFreight";
import {
  listOrderSeaFreightsFailed,
  listOrderSeaFreightsSuccess
} from "redux/model/orderSeaFreights/orderSeaFreights/orderSeaFreightsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";
import fileDownload from "js-file-download";


function* orderSeaFreightsSaga() {
  yield all([
    fork(watchListOrderSeaFreights),
    fork(watchListUncontainedOrderSeaFreights),
    fork(watchListOrderSeaFreightsCanBeMerged),
    fork(watchDownloadProcessingOrderSeaFreights),
    fork(watchDownloadDispatchingOrderSeaFreights),
  ]);
}

// List OrderSeaFreights
export function* watchListOrderSeaFreights() {
  yield takeEvery(LIST_ORDER_SEA_FREIGHTS, sagaListOrderSeaFreights);
}

export function* sagaListOrderSeaFreights({ payload }) {
  try {
    const response = yield call(apiListOrderSeaFreights, payload);

    const { orderSeaFreights, totalSize } = response.data;

    yield put(listOrderSeaFreightsSuccess({ orderSeaFreights, totalSize }));

  } catch (e) {
    yield put(listOrderSeaFreightsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// List Uncontained OrderSeaFreights
export function* watchListUncontainedOrderSeaFreights() {
  yield takeEvery(LIST_UNCONTAINED_ORDER_SEA_FREIGHTS, sagaListUncontainedOrderSeaFreights);
}

export function* sagaListUncontainedOrderSeaFreights({ payload }) {
  try {
    const response = yield call(apiListUncontainedOrderSeaFreights);

    const { orderSeaFreights } = response.data;

    yield put(listOrderSeaFreightsSuccess({ orderSeaFreights }));

  } catch (e) {
    yield put(listOrderSeaFreightsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// List OrderSeaFreights Can Be Merged
export function* watchListOrderSeaFreightsCanBeMerged() {
  yield takeEvery(LIST_ORDER_SEA_FREIGHTS_CAN_BE_MERGED, sagaListOrderSeaFreightsCanBeMerged);
}

export function* sagaListOrderSeaFreightsCanBeMerged({ payload }) {
  try {
    const response = yield call(apiListOrderSeaFreightsCanBeMerged, payload);

    const { orderSeaFreights } = response.data;

    yield put(listOrderSeaFreightsSuccess({ orderSeaFreights }));

  } catch (e) {
    yield put(listOrderSeaFreightsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// Download Processing Order Sea Freights
export function* watchDownloadProcessingOrderSeaFreights() {
  yield takeLeading(DOWNLOAD_PROCESSING_ORDER_SEA_FREIGHTS, sagaDownloadProcessingOrderSeaFreights);
}

export function* sagaDownloadProcessingOrderSeaFreights({ payload }) {
  try {
    const response = yield call(apiDownloadProcessingOrderSeaFreights, payload);
    yield call(
      fileDownload,
      response.data,
      `待处理订单.xlsx`
    );

  } catch (e) {
    console.log(generateErrorMessage(e));
    throwFrontError(e);
  }
}

// Download Dispatching Order Sea Freights
export function* watchDownloadDispatchingOrderSeaFreights() {
  yield takeLeading(DOWNLOAD_DISPATCHING_ORDER_SEA_FREIGHTS, sagaDownloadDispatchingOrderSeaFreights);
}

export function* sagaDownloadDispatchingOrderSeaFreights({ payload }) {
  try {
    const response = yield call(apiDownloadDispatchingOrderSeaFreights, payload);
    yield call(
      fileDownload,
      response.data,
      `待发货订单.xlsx`
    );

  } catch (e) {
    console.log(generateErrorMessage(e));
    throwFrontError(e);
  }
}

export default orderSeaFreightsSaga;
