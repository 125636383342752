import {
  FETCH_SEA_FREIGHT_EXPORTER,
  FETCH_SEA_FREIGHT_EXPORTER_FAILED,
  FETCH_SEA_FREIGHT_EXPORTER_SUCCESS,
  INIT_SEA_FREIGHT_EXPORTER
} from "redux/model/seaFreightExporters/seaFreightExporter/constants";

const INIT_STATE = {
  seaFreightExporter: {},
  loading: true,
  error: null,
};

const seaFreightExporterReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_SEA_FREIGHT_EXPORTER:
      return INIT_STATE;

    case FETCH_SEA_FREIGHT_EXPORTER:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_SEA_FREIGHT_EXPORTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        seaFreightExporter: action.payload.seaFreightExporter,
      };

    case FETCH_SEA_FREIGHT_EXPORTER_FAILED:
      return {
        ...state,
        seaFreightExporter: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default seaFreightExporterReducers;
