import { api, extractIdFrom, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_LINE_ITEM_API,
  INDEX_LINE_ITEMS_API,
  FETCH_LINE_ITEM_API,
  DELETE_LINE_ITEM_API,
  UPDATE_LINE_ITEM_API, TRANSFER_LINE_ITEM_API
} from "api/lineItem/constants";

export const apiListLineItems = () => {
  const { url, method } = INDEX_LINE_ITEMS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateLineItem = ({ lineItem }) => {
  const { url, method } = CREATE_LINE_ITEM_API;

  return api({
    method,
    url,
    data: lineItemParams(lineItem),
  });
};

export const apiFetchLineItem = ({ lineItemId }) => {
  const { url, method } = FETCH_LINE_ITEM_API;

  return api({
    method,
    url: generateRoute(url, lineItemId),
  });
};

export const apiUpdateLineItem = ({ lineItem }) => {
  const { url, method } = UPDATE_LINE_ITEM_API;

  return api({
    method,
    url: generateRoute(url, lineItem.id),
    data: lineItemParams(lineItem),
  });
};

export const apiDeleteLineItem = ({ lineItem }) => {
  const { url, method } = DELETE_LINE_ITEM_API;

  return api({
    method,
    url: generateRoute(url, lineItem.id),
  });
};

export const apiTransferLineItem = ({ lineItem, order }) => {
  const { url, method } = TRANSFER_LINE_ITEM_API;

  return api({
    method,
    url: generateRoute(url, lineItem.id),
    data: {
      order_id: extractIdFrom(order.id)
    }
  });
};

const lineItemParams = (lineItem) => {
  return {}
};
