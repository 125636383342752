import {
  INIT_ORDER_DECLARATIONS,
  LIST_ORDER_DECLARATIONS,
  LIST_ORDER_DECLARATIONS_FAILED,
  LIST_ORDER_DECLARATIONS_SUCCESS
} from "redux/model/orderDeclarations/orderDeclarations/constants";

export const initOrderDeclarations = () => ({
  type: INIT_ORDER_DECLARATIONS,
  payload: {}
});

export const listOrderDeclarations = () => ({
  type: LIST_ORDER_DECLARATIONS,
  payload: {}
});

export const listOrderDeclarationsSuccess = ({ orderDeclarations }) => ({
  type: LIST_ORDER_DECLARATIONS_SUCCESS,
  payload: { orderDeclarations }
});

export const listOrderDeclarationsFailed = ({ error }) => ({
  type: LIST_ORDER_DECLARATIONS_FAILED,
  payload: { error }
});
