import {
  ASSIGN_EXPORT_INTERNATIONAL_BATCH_UPLOAD_DATA_FILE,
  FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD,
  FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD_FAILED,
  FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS,
  INIT_EXPORT_INTERNATIONAL_BATCH_UPLOAD
} from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUpload/constants";

const INIT_STATE = {
  exportInternationalBatchUpload: {},
  loading: false,
  error: null,
};

const exportInternationalBatchUploadReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_EXPORT_INTERNATIONAL_BATCH_UPLOAD:
      return INIT_STATE;

    case FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        exportInternationalBatchUpload: action.payload.exportInternationalBatchUpload,
      };

    case FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD_FAILED:
      return {
        ...state,
        exportInternationalBatchUpload: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_EXPORT_INTERNATIONAL_BATCH_UPLOAD_DATA_FILE:
      return {
        ...state,
        exportInternationalBatchUpload: {
          ...state.exportInternationalBatchUpload,
          dataFile: action.payload.dataFile,
        },
      };

    default:
      return { ...state };
  }
};

export default exportInternationalBatchUploadReducers;
