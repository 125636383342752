import {
  ERROR_FORM_ORDER_DECLARATION,
  LOADING_FORM_ORDER_DECLARATION,
  RESET_FORM_ORDER_DECLARATION
} from "redux/ui/form/orderDeclaration/constants";
import {
  CREATE_ORDER_DECLARATION,
  CREATE_ORDER_DECLARATION_FAILED,
  CREATE_ORDER_DECLARATION_SUCCESS,
  UPDATE_ORDER_DECLARATION, UPDATE_ORDER_DECLARATION_FAILED, UPDATE_ORDER_DECLARATION_SUCCESS
} from "redux/model/orderDeclarations/orderDeclaration/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formOrderDeclarationReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_ORDER_DECLARATION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_ORDER_DECLARATION:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_ORDER_DECLARATION:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_ORDER_DECLARATION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_ORDER_DECLARATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_ORDER_DECLARATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_ORDER_DECLARATION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_ORDER_DECLARATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_ORDER_DECLARATION_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formOrderDeclarationReducers;
