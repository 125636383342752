export const INIT_PACK_BATCH_UPLOAD = 'INIT_PACK_BATCH_UPLOAD';

export const FETCH_PACK_BATCH_UPLOAD_SUCCESS = 'FETCH_PACK_BATCH_UPLOAD_SUCCESS';

export const ASSIGN_PACK_BATCH_UPLOAD_DATA_FILE = 'ASSIGN_PACK_BATCH_UPLOAD_DATA_FILE';

export const CREATE_PACK_BATCH_UPLOAD = 'CREATE_PACK_BATCH_UPLOAD';
export const CREATE_PACK_BATCH_UPLOAD_FAILED = 'CREATE_PACK_BATCH_UPLOAD_FAILED';

export const DOWNLOAD_PACK_BATCH_UPLOAD_CSV = 'DOWNLOAD_PACK_BATCH_UPLOAD_CSV';
