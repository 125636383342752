import _ from 'lodash';
import {
  CREATE_CLIENT_SUCCESS,
  DELETE_CLIENT_SUCCESS,
  UPDATE_CLIENT_SUCCESS
} from "redux/model/clients/client/constants";
import {
  INIT_CLIENTS,
  LIST_CLIENTS,
  LIST_CLIENTS_FAILED,
  LIST_CLIENTS_SUCCESS
} from "redux/model/clients/clients/constants";

const INIT_STATE = {
  clients: {},
  loading: true,
  error: null,
};

const clientsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_CLIENTS:
      return INIT_STATE;

    case LIST_CLIENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_CLIENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        clients: _.mapKeys(action.payload.clients, 'id'),
      };

    case LIST_CLIENTS_FAILED:
      return {
        ...state,
        clients: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: {
          [action.payload.client.id]: action.payload.client,
          ...state.clients,
        },
      };

    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: {
          ...state.clients,
          [action.payload.client.id]: action.payload.client,
        },
      };

    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        clients: _.omit(state.clients, action.payload.client.id)
      };

    default:
      return { ...state };
  }
};

export default clientsReducers;
