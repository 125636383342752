import _ from 'lodash';
import {
  CREATE_LINE_ITEM_DECLARATION_SUCCESS,
  DELETE_LINE_ITEM_DECLARATION_SUCCESS,
  NEW_LINE_ITEM_DECLARATION,
  PULL_LINE_ITEM_DECLARATION,
  SET_LINE_ITEM_DECLARATION,
  UPDATE_LINE_ITEM_DECLARATION_SUCCESS
} from "redux/model/lineItemDeclarations/lineItemDeclaration/constants";
import {
  INIT_LINE_ITEM_DECLARATIONS,
  LIST_LINE_ITEM_DECLARATIONS,
  LIST_LINE_ITEM_DECLARATIONS_FAILED,
  LIST_LINE_ITEM_DECLARATIONS_SUCCESS
} from "redux/model/lineItemDeclarations/lineItemDeclarations/constants";
import { isNew } from "@tmatt-tech/react_common";

const INIT_STATE = {
  lineItemDeclarations: {},
  loading: true,
  error: null,
};

const lineItemDeclarationsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_LINE_ITEM_DECLARATIONS:
      return INIT_STATE;

    case LIST_LINE_ITEM_DECLARATIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_LINE_ITEM_DECLARATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        lineItemDeclarations: _.mapKeys(action.payload.lineItemDeclarations, 'id'),
      };

    case LIST_LINE_ITEM_DECLARATIONS_FAILED:
      return {
        ...state,
        lineItemDeclarations: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_LINE_ITEM_DECLARATION_SUCCESS:
      return {
        ...state,
        lineItemDeclarations: {
          [action.payload.lineItemDeclaration.id]: action.payload.lineItemDeclaration,
          ...state.lineItemDeclarations,
        },
      };

    case UPDATE_LINE_ITEM_DECLARATION_SUCCESS:
      return {
        ...state,
        lineItemDeclarations: {
          ...state.lineItemDeclarations,
          [action.payload.lineItemDeclaration.id]: action.payload.lineItemDeclaration,
        },
      };

    case DELETE_LINE_ITEM_DECLARATION_SUCCESS:
      return {
        ...state,
        lineItemDeclarations: _.omit(state.lineItemDeclarations, action.payload.lineItemDeclaration.id)
      };

    case NEW_LINE_ITEM_DECLARATION:
      return {
        ...state,
        lineItemDeclarations: {
          ...state.lineItemDeclarations,
          [action.payload.lineItemDeclaration.id]: {
            ...action.payload.lineItemDeclaration,
            destroy: false,
          },
        },
      };

    case PULL_LINE_ITEM_DECLARATION:
      if (isNew(action.payload.lineItemDeclaration)) {
        return {
          ...state,
          lineItemDeclarations: _.omit(state.lineItemDeclarations, action.payload.lineItemDeclaration.id),
        };
      }

      return {
        ...state,
        lineItemDeclarations: {
          ...state.lineItemDeclarations,
          [action.payload.lineItemDeclaration.id]: {
            ...state.lineItemDeclarations[action.payload.lineItemDeclaration.id],
            destroy: true,
          },
        },
      };

    case SET_LINE_ITEM_DECLARATION:
      return {
        ...state,
        lineItemDeclarations: {
          ...state.lineItemDeclarations,
          [action.payload.lineItemDeclaration.id]: action.payload.lineItemDeclaration,
        },
      };

    default:
      return { ...state };
  }
};

export default lineItemDeclarationsReducers;
