import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_RECEIPT_ADDRESSES } from "redux/model/receiptAddresses/receiptAddresses/constants";
import { apiListReceiptAddresses } from "api/receiptAddress/apiReceiptAddress";
import { listReceiptAddressesFailed, listReceiptAddressesSuccess } from "redux/model/receiptAddresses/receiptAddresses/receiptAddressesActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* receiptAddressesSaga() {
  yield all([
    fork(watchListReceiptAddresses),
  ]);
}

// List ReceiptAddresses
export function* watchListReceiptAddresses() {
  yield takeEvery(LIST_RECEIPT_ADDRESSES, sagaListReceiptAddresses);
}

export function* sagaListReceiptAddresses() {
  try {
    const response = yield call(apiListReceiptAddresses);

    const { receiptAddresses } = response.data;

    yield put(listReceiptAddressesSuccess({ receiptAddresses }));

  } catch (e) {
    yield put(listReceiptAddressesFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default receiptAddressesSaga;
