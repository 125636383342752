import {
  ERROR_FORM_SEA_FREIGHT_EXPORTER,
  LOADING_FORM_SEA_FREIGHT_EXPORTER,
  RESET_FORM_SEA_FREIGHT_EXPORTER
} from "redux/ui/form/seaFreightExporter/constants";
import {
  CREATE_SEA_FREIGHT_EXPORTER,
  CREATE_SEA_FREIGHT_EXPORTER_FAILED,
  CREATE_SEA_FREIGHT_EXPORTER_SUCCESS,
  UPDATE_SEA_FREIGHT_EXPORTER, UPDATE_SEA_FREIGHT_EXPORTER_FAILED, UPDATE_SEA_FREIGHT_EXPORTER_SUCCESS
} from "redux/model/seaFreightExporters/seaFreightExporter/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formSeaFreightExporterReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_SEA_FREIGHT_EXPORTER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_SEA_FREIGHT_EXPORTER:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_SEA_FREIGHT_EXPORTER:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_SEA_FREIGHT_EXPORTER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_SEA_FREIGHT_EXPORTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_SEA_FREIGHT_EXPORTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_SEA_FREIGHT_EXPORTER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_SEA_FREIGHT_EXPORTER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_SEA_FREIGHT_EXPORTER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formSeaFreightExporterReducers;
