import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import { CREATE_CLIENT, DELETE_CLIENT, FETCH_CLIENT, UPDATE_CLIENT } from "redux/model/clients/client/constants";
import { apiCreateClient, apiDeleteClient, apiFetchClient, apiUpdateClient } from "api/client/apiClient";
import {
  createClientFailed,
  createClientSuccess,
  deleteClientFailed,
  deleteClientSuccess,
  fetchClientFailed,
  fetchClientSuccess,
  initClient,
  updateClientFailed,
  updateClientSuccess
} from "redux/model/clients/client/clientActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_CLIENTS_PATH } from "routes/client/constants";
import { listTransactionsSuccess } from "redux/model/transactions/transactions/transactionsActions";
import { listReceiptAddressesSuccess } from "redux/model/receiptAddresses/receiptAddresses/receiptAddressesActions";


function* clientSaga() {
  yield all([
    fork(watchFetchClient),
    fork(watchUpdateClient),
    fork(watchCreateClient),
    fork(watchDeleteClient),
  ]);
}

// Fetch Client
export function* watchFetchClient() {
  yield takeEvery(FETCH_CLIENT, sagaFetchClient);
}

export function* sagaFetchClient({ payload }) {
  try {
    const response = yield call(apiFetchClient, payload);

    const { client, transactions, receiptAddresses } = response.data;
    yield put(fetchClientSuccess({ client }));
    yield put(listTransactionsSuccess({ transactions }));
    yield put(listReceiptAddressesSuccess({ receiptAddresses }));

  } catch (e) {
    yield put(fetchClientFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Client
export function* watchCreateClient() {
  yield takeLeading(CREATE_CLIENT, sagaCreateClient);
}

export function* sagaCreateClient({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateClient, payload);

    const { client } = response.data;
    yield put(createClientSuccess({ client }));
    yield put(toastSaved({ name: `Client` }));


    history.push(generateRoute(INDEX_CLIENTS_PATH));
    yield put(initClient());


  } catch (e) {
    yield put(createClientFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Client
export function* watchUpdateClient() {
  yield takeLeading(UPDATE_CLIENT, sagaUpdateClient);
}

export function* sagaUpdateClient({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateClient, payload);

    const { client } = response.data;
    yield put(updateClientSuccess({ client }));
    yield put(toastSaved({ name: 'Client' }));
    history.push(generateRoute(INDEX_CLIENTS_PATH));
    yield put(initClient());

  } catch (e) {
    yield put(updateClientFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Client
export function* watchDeleteClient() {
  yield takeLeading(DELETE_CLIENT, sagaDeleteClient);
}

export function* sagaDeleteClient({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteClient, payload);

    const { client } = response.data;
    yield put(deleteClientSuccess({ client }));
    yield put(toastDeleted({ name: 'Client' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteClientFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default clientSaga;
