import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_PACK_DECLARATION,
  DELETE_PACK_DECLARATION,
  FETCH_PACK_DECLARATION,
  UPDATE_PACK_DECLARATION
} from "redux/model/packDeclarations/packDeclaration/constants";
import {
  apiCreatePackDeclaration,
  apiDeletePackDeclaration,
  apiFetchPackDeclaration,
  apiUpdatePackDeclaration
} from "api/packDeclaration/apiPackDeclaration";
import {
  createPackDeclarationFailed,
  createPackDeclarationSuccess,
  deletePackDeclarationFailed,
  deletePackDeclarationSuccess,
  fetchPackDeclarationFailed,
  fetchPackDeclarationSuccess,
  initPackDeclaration,
  updatePackDeclarationFailed,
  updatePackDeclarationSuccess
} from "redux/model/packDeclarations/packDeclaration/packDeclarationActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_PACK_DECLARATIONS_PATH } from "routes/packDeclaration/constants";


function* packDeclarationSaga() {
  yield all([
    fork(watchFetchPackDeclaration),
    fork(watchUpdatePackDeclaration),
    fork(watchCreatePackDeclaration),
    fork(watchDeletePackDeclaration),
  ]);
}

// Fetch PackDeclaration
export function* watchFetchPackDeclaration() {
  yield takeEvery(FETCH_PACK_DECLARATION, sagaFetchPackDeclaration);
}

export function* sagaFetchPackDeclaration({ payload }) {
  try {
    const response = yield call(apiFetchPackDeclaration, payload);

    const { packDeclaration } = response.data;
    yield put(fetchPackDeclarationSuccess({ packDeclaration }));

  } catch (e) {
    yield put(fetchPackDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create PackDeclaration
export function* watchCreatePackDeclaration() {
  yield takeLeading(CREATE_PACK_DECLARATION, sagaCreatePackDeclaration);
}

export function* sagaCreatePackDeclaration({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreatePackDeclaration, payload);

    const { packDeclaration } = response.data;
    yield put(createPackDeclarationSuccess({ packDeclaration }));
    yield put(toastSaved({ name: `PackDeclaration` }));


    history.push(generateRoute(INDEX_PACK_DECLARATIONS_PATH));
    yield put(initPackDeclaration());


  } catch (e) {
    yield put(createPackDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update PackDeclaration
export function* watchUpdatePackDeclaration() {
  yield takeLeading(UPDATE_PACK_DECLARATION, sagaUpdatePackDeclaration);
}

export function* sagaUpdatePackDeclaration({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdatePackDeclaration, payload);

    const { packDeclaration } = response.data;
    yield put(updatePackDeclarationSuccess({ packDeclaration }));
    yield put(toastSaved({ name: 'PackDeclaration' }));
    history.push(generateRoute(INDEX_PACK_DECLARATIONS_PATH));
    yield put(initPackDeclaration());

  } catch (e) {
    yield put(updatePackDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete PackDeclaration
export function* watchDeletePackDeclaration() {
  yield takeLeading(DELETE_PACK_DECLARATION, sagaDeletePackDeclaration);
}

export function* sagaDeletePackDeclaration({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeletePackDeclaration, payload);

    const { packDeclaration } = response.data;
    yield put(deletePackDeclarationSuccess({ packDeclaration }));
    yield put(toastDeleted({ name: 'PackDeclaration' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deletePackDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default packDeclarationSaga;
