import {
  FETCH_LINE_ITEM,
  FETCH_LINE_ITEM_FAILED,
  FETCH_LINE_ITEM_SUCCESS,
  INIT_LINE_ITEM
} from "redux/model/lineItems/lineItem/constants";

const INIT_STATE = {
  lineItem: {},
  loading: true,
  error: null,
};

const lineItemReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_LINE_ITEM:
      return INIT_STATE;

    case FETCH_LINE_ITEM:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_LINE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        lineItem: action.payload.lineItem,
      };

    case FETCH_LINE_ITEM_FAILED:
      return {
        ...state,
        lineItem: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default lineItemReducers;
