import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_WECHAT_NOTICE,
  DELETE_WECHAT_NOTICE,
  FETCH_WECHAT_NOTICE,
  UPDATE_WECHAT_NOTICE
} from "redux/model/wechatNotices/wechatNotice/constants";
import {
  apiCreateWechatNotice,
  apiDeleteWechatNotice,
  apiFetchWechatNotice,
  apiUpdateWechatNotice
} from "api/wechatNotice/apiWechatNotice";
import {
  createWechatNoticeFailed,
  createWechatNoticeSuccess,
  deleteWechatNoticeFailed,
  deleteWechatNoticeSuccess,
  fetchWechatNoticeFailed,
  fetchWechatNoticeSuccess,
  initWechatNotice,
  updateWechatNoticeFailed,
  updateWechatNoticeSuccess
} from "redux/model/wechatNotices/wechatNotice/wechatNoticeActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_WECHAT_NOTICES_PATH } from "routes/wechatNotice/constants";


function* wechatNoticeSaga() {
  yield all([
    fork(watchFetchWechatNotice),
    fork(watchUpdateWechatNotice),
    fork(watchCreateWechatNotice),
    fork(watchDeleteWechatNotice),
  ]);
}

// Fetch WechatNotice
export function* watchFetchWechatNotice() {
  yield takeEvery(FETCH_WECHAT_NOTICE, sagaFetchWechatNotice);
}

export function* sagaFetchWechatNotice({ payload }) {
  try {
    const response = yield call(apiFetchWechatNotice, payload);

    const { wechatNotice } = response.data;
    yield put(fetchWechatNoticeSuccess({ wechatNotice }));

  } catch (e) {
    yield put(fetchWechatNoticeFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create WechatNotice
export function* watchCreateWechatNotice() {
  yield takeLeading(CREATE_WECHAT_NOTICE, sagaCreateWechatNotice);
}

export function* sagaCreateWechatNotice({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateWechatNotice, payload);

    const { wechatNotice } = response.data;
    yield put(createWechatNoticeSuccess({ wechatNotice }));
    yield put(toastSaved({ name: `WechatNotice` }));


    history.push(generateRoute(INDEX_WECHAT_NOTICES_PATH));
    yield put(initWechatNotice());


  } catch (e) {
    yield put(createWechatNoticeFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update WechatNotice
export function* watchUpdateWechatNotice() {
  yield takeLeading(UPDATE_WECHAT_NOTICE, sagaUpdateWechatNotice);
}

export function* sagaUpdateWechatNotice({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateWechatNotice, payload);

    const { wechatNotice } = response.data;
    yield put(updateWechatNoticeSuccess({ wechatNotice }));
    yield put(toastSaved({ name: 'WechatNotice' }));
    history.push(generateRoute(INDEX_WECHAT_NOTICES_PATH));
    yield put(initWechatNotice());

  } catch (e) {
    yield put(updateWechatNoticeFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete WechatNotice
export function* watchDeleteWechatNotice() {
  yield takeLeading(DELETE_WECHAT_NOTICE, sagaDeleteWechatNotice);
}

export function* sagaDeleteWechatNotice({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteWechatNotice, payload);

    const { wechatNotice } = response.data;
    yield put(deleteWechatNoticeSuccess({ wechatNotice }));
    yield put(toastDeleted({ name: 'WechatNotice' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteWechatNoticeFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default wechatNoticeSaga;
