import {
  FETCH_SEA_FREIGHT_CONSIGNEE,
  FETCH_SEA_FREIGHT_CONSIGNEE_FAILED,
  FETCH_SEA_FREIGHT_CONSIGNEE_SUCCESS,
  INIT_SEA_FREIGHT_CONSIGNEE
} from "redux/model/seaFreightConsignees/seaFreightConsignee/constants";

const INIT_STATE = {
  seaFreightConsignee: {},
  loading: true,
  error: null,
};

const seaFreightConsigneeReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_SEA_FREIGHT_CONSIGNEE:
      return INIT_STATE;

    case FETCH_SEA_FREIGHT_CONSIGNEE:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_SEA_FREIGHT_CONSIGNEE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        seaFreightConsignee: action.payload.seaFreightConsignee,
      };

    case FETCH_SEA_FREIGHT_CONSIGNEE_FAILED:
      return {
        ...state,
        seaFreightConsignee: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default seaFreightConsigneeReducers;
