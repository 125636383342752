import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_API,
  DELETE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_API,
  DOWNLOAD_EXPORT_INTERNATIONAL_BATCH_UPLOAD_CSV_API,
  FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD_API,
  INDEX_EXPORT_INTERNATIONAL_BATCH_UPLOADS_API,
  UPDATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_API
} from "api/exportInternationalBatchUpload/constants";

export const apiListExportInternationalBatchUploads = () => {
  const { url, method } = INDEX_EXPORT_INTERNATIONAL_BATCH_UPLOADS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateExportInternationalBatchUpload = ({ exportInternationalBatchUpload }) => {
  const { url, method } = CREATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_API;

  return api({
    method,
    url,
    data: exportInternationalBatchUploadParams({ exportInternationalBatchUpload }),
  });
};

export const apiFetchExportInternationalBatchUpload = ({ exportInternationalBatchUploadId }) => {
  const { url, method } = FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD_API;

  return api({
    method,
    url: generateRoute(url, exportInternationalBatchUploadId),
  });
};

export const apiUpdateExportInternationalBatchUpload = ({ exportInternationalBatchUpload }) => {
  const { url, method } = UPDATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_API;

  return api({
    method,
    url: generateRoute(url, exportInternationalBatchUpload.id),
    data: exportInternationalBatchUploadParams({ exportInternationalBatchUpload }),
  });
};

export const apiDeleteExportInternationalBatchUpload = ({ exportInternationalBatchUpload }) => {
  const { url, method } = DELETE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_API;

  return api({
    method,
    url: generateRoute(url, exportInternationalBatchUpload.id),
  });
};

export const apiDownloadExportInternationalBatchUploadCsv = () => {
  const { url, method, responseType } = DOWNLOAD_EXPORT_INTERNATIONAL_BATCH_UPLOAD_CSV_API;

  return api({
    method,
    url,
    responseType,
    transformResponse: [(data) => {
      return data;
    }]
  });
};

const exportInternationalBatchUploadParams = ({ exportInternationalBatchUpload }) => {
  const { dataFile } = exportInternationalBatchUpload;

  return {
    dataFile,
  }
};
