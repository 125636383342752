import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_SEA_FREIGHT_CONSIGNEE_API,
  INDEX_SEA_FREIGHT_CONSIGNEES_API,
  FETCH_SEA_FREIGHT_CONSIGNEE_API,
  DELETE_SEA_FREIGHT_CONSIGNEE_API,
  UPDATE_SEA_FREIGHT_CONSIGNEE_API
} from "api/seaFreightConsignee/constants";

export const apiListSeaFreightConsignees = () => {
  const { url, method } = INDEX_SEA_FREIGHT_CONSIGNEES_API;

  return api({
    method,
    url,
  });
};

export const apiCreateSeaFreightConsignee = ({ seaFreightConsignee }) => {
  const { url, method } = CREATE_SEA_FREIGHT_CONSIGNEE_API;

  return api({
    method,
    url,
    data: seaFreightConsigneeParams({ seaFreightConsignee }),
  });
};

export const apiFetchSeaFreightConsignee = ({ seaFreightConsigneeId }) => {
  const { url, method } = FETCH_SEA_FREIGHT_CONSIGNEE_API;

  return api({
    method,
    url: generateRoute(url, seaFreightConsigneeId),
  });
};

export const apiUpdateSeaFreightConsignee = ({ seaFreightConsignee }) => {
  const { url, method } = UPDATE_SEA_FREIGHT_CONSIGNEE_API;

  return api({
    method,
    url: generateRoute(url, seaFreightConsignee.id),
    data: seaFreightConsigneeParams({ seaFreightConsignee }),
  });
};

export const apiDeleteSeaFreightConsignee = ({ seaFreightConsignee }) => {
  const { url, method } = DELETE_SEA_FREIGHT_CONSIGNEE_API;

  return api({
    method,
    url: generateRoute(url, seaFreightConsignee.id),
  });
};

const seaFreightConsigneeParams = ({ seaFreightConsignee }) => {
  const {} = seaFreightConsignee;

  return {
  }
};
