
export const CREATE_COUPON_RULE_API = {
  url: `/coupon_rules`,
  method: 'post'
};

export const INDEX_COUPON_RULES_API = {
  url: `/coupon_rules`,
  method: 'get',
};

export const FETCH_COUPON_RULE_API = {
  url: `/coupon_rules/:id`,
  method: 'get',
};

export const UPDATE_COUPON_RULE_API = {
  url: `/coupon_rules/:id`,
  method: 'put'
};

export const DELETE_COUPON_RULE_API = {
  url: `/coupon_rules/:id`,
  method: 'delete'
};

export const BATCH_REDEEM_COUPON_RULE_API = {
  url: `/coupon_rules/:id/batch_redeem`,
  method: 'post'
};
