export const INIT_LINE_ITEM_DECLARATION = 'INIT_LINE_ITEM_DECLARATION';

export const FETCH_LINE_ITEM_DECLARATION = 'FETCH_LINE_ITEM_DECLARATION';
export const FETCH_LINE_ITEM_DECLARATION_SUCCESS = 'FETCH_LINE_ITEM_DECLARATION_SUCCESS';
export const FETCH_LINE_ITEM_DECLARATION_FAILED = 'FETCH_LINE_ITEM_DECLARATION_FAILED';

export const CREATE_LINE_ITEM_DECLARATION = 'CREATE_LINE_ITEM_DECLARATION';
export const CREATE_LINE_ITEM_DECLARATION_SUCCESS = 'CREATE_LINE_ITEM_DECLARATION_SUCCESS';
export const CREATE_LINE_ITEM_DECLARATION_FAILED = 'CREATE_LINE_ITEM_DECLARATION_FAILED';

export const UPDATE_LINE_ITEM_DECLARATION = 'UPDATE_LINE_ITEM_DECLARATION';
export const UPDATE_LINE_ITEM_DECLARATION_SUCCESS = 'UPDATE_LINE_ITEM_DECLARATION_SUCCESS';
export const UPDATE_LINE_ITEM_DECLARATION_FAILED = 'UPDATE_LINE_ITEM_DECLARATION_FAILED';

export const DELETE_LINE_ITEM_DECLARATION = 'DELETE_LINE_ITEM_DECLARATION';
export const DELETE_LINE_ITEM_DECLARATION_SUCCESS = 'DELETE_LINE_ITEM_DECLARATION_SUCCESS';
export const DELETE_LINE_ITEM_DECLARATION_FAILED = 'DELETE_LINE_ITEM_DECLARATION_FAILED';

export const NEW_LINE_ITEM_DECLARATION = 'NEW_LINE_ITEM_DECLARATION';
export const PULL_LINE_ITEM_DECLARATION = 'PULL_LINE_ITEM_DECLARATION';
export const SET_LINE_ITEM_DECLARATION = 'SET_LINE_ITEM_DECLARATION';