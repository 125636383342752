import {
  ERROR_FORM_CLIENT,
  LOADING_FORM_CLIENT,
  RESET_FORM_CLIENT
} from "redux/ui/form/client/constants";
import {
  CREATE_CLIENT,
  CREATE_CLIENT_FAILED,
  CREATE_CLIENT_SUCCESS,
  UPDATE_CLIENT, UPDATE_CLIENT_FAILED, UPDATE_CLIENT_SUCCESS
} from "redux/model/clients/client/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formClientReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_CLIENT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_CLIENT:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_CLIENT:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_CLIENT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_CLIENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_CLIENT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_CLIENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formClientReducers;
