import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_PACK_DECLARATIONS } from "redux/model/packDeclarations/packDeclarations/constants";
import { apiListPackDeclarations } from "api/packDeclaration/apiPackDeclaration";
import { listPackDeclarationsFailed, listPackDeclarationsSuccess } from "redux/model/packDeclarations/packDeclarations/packDeclarationsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* packDeclarationsSaga() {
  yield all([
    fork(watchListPackDeclarations),
  ]);
}

// List PackDeclarations
export function* watchListPackDeclarations() {
  yield takeEvery(LIST_PACK_DECLARATIONS, sagaListPackDeclarations);
}

export function* sagaListPackDeclarations() {
  try {
    const response = yield call(apiListPackDeclarations);

    const { packDeclarations } = response.data;

    yield put(listPackDeclarationsSuccess({ packDeclarations }));

  } catch (e) {
    yield put(listPackDeclarationsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default packDeclarationsSaga;
