
export const CREATE_SEA_FREIGHT_CONTAINER_API = {
  url: `/sea_freight_containers`,
  method: 'post'
};

export const INDEX_SEA_FREIGHT_CONTAINERS_API = {
  url: `/sea_freight_containers`,
  method: 'get',
};

export const FETCH_SEA_FREIGHT_CONTAINER_API = {
  url: `/sea_freight_containers/:id`,
  method: 'get',
};

export const UPDATE_SEA_FREIGHT_CONTAINER_API = {
  url: `/sea_freight_containers/:id`,
  method: 'put'
};

export const DELETE_SEA_FREIGHT_CONTAINER_API = {
  url: `/sea_freight_containers/:id`,
  method: 'delete'
};

export const LIST_SEA_FREIGHT_CONTAINER_CAN_BE_LOADED_API = {
  url: `/sea_freight_containers/list_can_be_loaded`,
  method: 'post'
};

export const DOWNLOAD_SEA_FREIGHT_CONTAINER_API = {
  url: `/sea_freight_containers/:id/download_excel`,
  method: 'get',
  responseType: 'arraybuffer',
};