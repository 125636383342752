import {
  ERROR_FORM_WAREHOUSE,
  LOADING_FORM_WAREHOUSE,
  RESET_FORM_WAREHOUSE
} from "redux/ui/form/warehouse/constants";
import {
  CREATE_WAREHOUSE,
  CREATE_WAREHOUSE_FAILED,
  CREATE_WAREHOUSE_SUCCESS,
  UPDATE_WAREHOUSE, UPDATE_WAREHOUSE_FAILED, UPDATE_WAREHOUSE_SUCCESS
} from "redux/model/warehouses/warehouse/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formWarehouseReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_WAREHOUSE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_WAREHOUSE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_WAREHOUSE:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_WAREHOUSE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_WAREHOUSE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_WAREHOUSE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_WAREHOUSE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formWarehouseReducers;
