import {
  INIT_PACK_DECLARATIONS,
  LIST_PACK_DECLARATIONS,
  LIST_PACK_DECLARATIONS_FAILED,
  LIST_PACK_DECLARATIONS_SUCCESS
} from "redux/model/packDeclarations/packDeclarations/constants";

export const initPackDeclarations = () => ({
  type: INIT_PACK_DECLARATIONS,
  payload: {}
});

export const listPackDeclarations = () => ({
  type: LIST_PACK_DECLARATIONS,
  payload: {}
});

export const listPackDeclarationsSuccess = ({ packDeclarations }) => ({
  type: LIST_PACK_DECLARATIONS_SUCCESS,
  payload: { packDeclarations }
});

export const listPackDeclarationsFailed = ({ error }) => ({
  type: LIST_PACK_DECLARATIONS_FAILED,
  payload: { error }
});
