import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_SEA_FREIGHT_EXPORTER,
  DELETE_SEA_FREIGHT_EXPORTER,
  FETCH_SEA_FREIGHT_EXPORTER,
  UPDATE_SEA_FREIGHT_EXPORTER
} from "redux/model/seaFreightExporters/seaFreightExporter/constants";
import {
  apiCreateSeaFreightExporter,
  apiDeleteSeaFreightExporter,
  apiFetchSeaFreightExporter,
  apiUpdateSeaFreightExporter
} from "api/seaFreightExporter/apiSeaFreightExporter";
import {
  createSeaFreightExporterFailed,
  createSeaFreightExporterSuccess,
  deleteSeaFreightExporterFailed,
  deleteSeaFreightExporterSuccess,
  fetchSeaFreightExporterFailed,
  fetchSeaFreightExporterSuccess,
  initSeaFreightExporter,
  updateSeaFreightExporterFailed,
  updateSeaFreightExporterSuccess
} from "redux/model/seaFreightExporters/seaFreightExporter/seaFreightExporterActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_SEA_FREIGHT_EXPORTERS_PATH } from "routes/seaFreightExporter/constants";


function* seaFreightExporterSaga() {
  yield all([
    fork(watchFetchSeaFreightExporter),
    fork(watchUpdateSeaFreightExporter),
    fork(watchCreateSeaFreightExporter),
    fork(watchDeleteSeaFreightExporter),
  ]);
}

// Fetch SeaFreightExporter
export function* watchFetchSeaFreightExporter() {
  yield takeEvery(FETCH_SEA_FREIGHT_EXPORTER, sagaFetchSeaFreightExporter);
}

export function* sagaFetchSeaFreightExporter({ payload }) {
  try {
    const response = yield call(apiFetchSeaFreightExporter, payload);

    const { seaFreightExporter } = response.data;
    yield put(fetchSeaFreightExporterSuccess({ seaFreightExporter }));

  } catch (e) {
    yield put(fetchSeaFreightExporterFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create SeaFreightExporter
export function* watchCreateSeaFreightExporter() {
  yield takeLeading(CREATE_SEA_FREIGHT_EXPORTER, sagaCreateSeaFreightExporter);
}

export function* sagaCreateSeaFreightExporter({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateSeaFreightExporter, payload);

    const { seaFreightExporter } = response.data;
    yield put(createSeaFreightExporterSuccess({ seaFreightExporter }));
    yield put(toastSaved({ name: `SeaFreightExporter` }));


    history.push(generateRoute(INDEX_SEA_FREIGHT_EXPORTERS_PATH));
    yield put(initSeaFreightExporter());


  } catch (e) {
    yield put(createSeaFreightExporterFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update SeaFreightExporter
export function* watchUpdateSeaFreightExporter() {
  yield takeLeading(UPDATE_SEA_FREIGHT_EXPORTER, sagaUpdateSeaFreightExporter);
}

export function* sagaUpdateSeaFreightExporter({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateSeaFreightExporter, payload);

    const { seaFreightExporter } = response.data;
    yield put(updateSeaFreightExporterSuccess({ seaFreightExporter }));
    yield put(toastSaved({ name: 'SeaFreightExporter' }));
    history.push(generateRoute(INDEX_SEA_FREIGHT_EXPORTERS_PATH));
    yield put(initSeaFreightExporter());

  } catch (e) {
    yield put(updateSeaFreightExporterFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete SeaFreightExporter
export function* watchDeleteSeaFreightExporter() {
  yield takeLeading(DELETE_SEA_FREIGHT_EXPORTER, sagaDeleteSeaFreightExporter);
}

export function* sagaDeleteSeaFreightExporter({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteSeaFreightExporter, payload);

    const { seaFreightExporter } = response.data;
    yield put(deleteSeaFreightExporterSuccess({ seaFreightExporter }));
    yield put(toastDeleted({ name: 'SeaFreightExporter' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteSeaFreightExporterFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default seaFreightExporterSaga;
