import { CREATE_PACK_BATCH_UPLOAD_API, DOWNLOAD_PACK_BATCH_UPLOAD_CSV_API } from "api/packBatchUpload/constants";
import { api } from "@tmatt-tech/react_common";

export const apiDownloadPackBatchUploadCsv = () => {
  const { url, method, responseType } = DOWNLOAD_PACK_BATCH_UPLOAD_CSV_API;

  return api({
    method,
    url,
    responseType,
    transformResponse: [(data) => {
      return data;
    }]
  });
};

export const apiCreatePackBatchUpload = ({ packBatchUpload }) => {
  const { url, method } = CREATE_PACK_BATCH_UPLOAD_API;

  return api({
    method,
    url,
    data: packBatchUploadParams({ packBatchUpload }),
  });
};

const packBatchUploadParams = ({ packBatchUpload }) => {
  const { dataFile } = packBatchUpload;

  return {
    dataFile,
  }
};
