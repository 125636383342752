import _ from 'lodash';
import {
  CREATE_PAYMENT_SUCCESS,
  DELETE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT_SUCCESS
} from "redux/model/payments/payment/constants";
import {
  INIT_PAYMENTS,
  LIST_PAYMENTS,
  LIST_PAYMENTS_FAILED,
  LIST_PAYMENTS_SUCCESS
} from "redux/model/payments/payments/constants";

const INIT_STATE = {
  payments: {},
  loading: true,
  error: null,
};

const paymentsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PAYMENTS:
      return INIT_STATE;

    case LIST_PAYMENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_PAYMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        payments: _.mapKeys(action.payload.payments, 'id'),
      };

    case LIST_PAYMENTS_FAILED:
      return {
        ...state,
        payments: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payments: {
          [action.payload.payment.id]: action.payload.payment,
          ...state.payments,
        },
      };

    case UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        payments: {
          ...state.payments,
          [action.payload.payment.id]: action.payload.payment,
        },
      };

    case DELETE_PAYMENT_SUCCESS:
      return {
        ...state,
        payments: _.omit(state.payments, action.payload.payment.id)
      };

    default:
      return { ...state };
  }
};

export default paymentsReducers;
