import { api, extractIdFrom, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_SEA_FREIGHT_CONTAINER_API,
  DELETE_SEA_FREIGHT_CONTAINER_API,
  DOWNLOAD_SEA_FREIGHT_CONTAINER_API,
  FETCH_SEA_FREIGHT_CONTAINER_API,
  INDEX_SEA_FREIGHT_CONTAINERS_API,
  LIST_SEA_FREIGHT_CONTAINER_CAN_BE_LOADED_API,
  UPDATE_SEA_FREIGHT_CONTAINER_API
} from "api/seaFreightContainer/constants";
import _ from 'lodash';

export const apiListSeaFreightContainers = () => {
  const { url, method } = INDEX_SEA_FREIGHT_CONTAINERS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateSeaFreightContainer = ({ seaFreightContainer }) => {
  const { url, method } = CREATE_SEA_FREIGHT_CONTAINER_API;

  return api({
    method,
    url,
    data: seaFreightContainerParams({ seaFreightContainer }),
  });
};

export const apiFetchSeaFreightContainer = ({ seaFreightContainerId }) => {
  const { url, method } = FETCH_SEA_FREIGHT_CONTAINER_API;

  return api({
    method,
    url: generateRoute(url, seaFreightContainerId),
  });
};

export const apiUpdateSeaFreightContainer = ({ seaFreightContainer }) => {
  const { url, method } = UPDATE_SEA_FREIGHT_CONTAINER_API;

  return api({
    method,
    url: generateRoute(url, seaFreightContainer.id),
    data: seaFreightContainerParams({ seaFreightContainer }),
  });
};

export const apiDeleteSeaFreightContainer = ({ seaFreightContainer }) => {
  const { url, method } = DELETE_SEA_FREIGHT_CONTAINER_API;

  return api({
    method,
    url: generateRoute(url, seaFreightContainer.id),
  });
};

export const apiListSeaFreightContainersCanBeLoaded = () => {
  const { url, method } = LIST_SEA_FREIGHT_CONTAINER_CAN_BE_LOADED_API;

  return api({
    method,
    url,
  });
};

export const apiDownloadSeaFreightContainer = ({ seaFreightContainer }) => {
  const { url, method, responseType } = DOWNLOAD_SEA_FREIGHT_CONTAINER_API;

  return api({
    method,
    url: generateRoute(url, seaFreightContainer.id),
    responseType,
    transformResponse: [(data) => {
      return data;
    }]
  });
};

const seaFreightContainerParams = ({ seaFreightContainer }) => {
  const {
    status,
    orderSeaFreightIds,
    seaFreightContainerDeclaration,
  } = seaFreightContainer;

  const {
    id,
    vesselName,
    voyageNumber,
    consignmentIdentifier,
    numericalLink,
  } = seaFreightContainerDeclaration;

  return {
    seaFreightContainer: {
      status,
      orderSeaFreightIds: _.map(orderSeaFreightIds, (id) => {
        return extractIdFrom(id);
      }),
      seaFreightContainerDeclarationAttributes: {
        id: extractIdFrom(id),
        vesselName,
        voyageNumber,
        consignmentIdentifier,
        numericalLink,
      }
    }
  }
};
