export const INIT_LINE_ITEM = 'INIT_LINE_ITEM';

export const FETCH_LINE_ITEM = 'FETCH_LINE_ITEM';
export const FETCH_LINE_ITEM_SUCCESS = 'FETCH_LINE_ITEM_SUCCESS';
export const FETCH_LINE_ITEM_FAILED = 'FETCH_LINE_ITEM_FAILED';

export const CREATE_LINE_ITEM = 'CREATE_LINE_ITEM';
export const CREATE_LINE_ITEM_SUCCESS = 'CREATE_LINE_ITEM_SUCCESS';
export const CREATE_LINE_ITEM_FAILED = 'CREATE_LINE_ITEM_FAILED';

export const UPDATE_LINE_ITEM = 'UPDATE_LINE_ITEM';
export const UPDATE_LINE_ITEM_SUCCESS = 'UPDATE_LINE_ITEM_SUCCESS';
export const UPDATE_LINE_ITEM_FAILED = 'UPDATE_LINE_ITEM_FAILED';

export const DELETE_LINE_ITEM = 'DELETE_LINE_ITEM';
export const DELETE_LINE_ITEM_SUCCESS = 'DELETE_LINE_ITEM_SUCCESS';
export const DELETE_LINE_ITEM_FAILED = 'DELETE_LINE_ITEM_FAILED';

export const TRANSFER_LINE_ITEM = 'TRANSFER_LINE_ITEM';
