import {
  CREATE_SEA_FREIGHT_CONSIGNEE,
  CREATE_SEA_FREIGHT_CONSIGNEE_FAILED,
  CREATE_SEA_FREIGHT_CONSIGNEE_SUCCESS,
  DELETE_SEA_FREIGHT_CONSIGNEE, DELETE_SEA_FREIGHT_CONSIGNEE_FAILED, DELETE_SEA_FREIGHT_CONSIGNEE_SUCCESS,
  FETCH_SEA_FREIGHT_CONSIGNEE,
  FETCH_SEA_FREIGHT_CONSIGNEE_FAILED,
  FETCH_SEA_FREIGHT_CONSIGNEE_SUCCESS,
  INIT_SEA_FREIGHT_CONSIGNEE,
  UPDATE_SEA_FREIGHT_CONSIGNEE,
  UPDATE_SEA_FREIGHT_CONSIGNEE_FAILED,
  UPDATE_SEA_FREIGHT_CONSIGNEE_SUCCESS
} from "redux/model/seaFreightConsignees/seaFreightConsignee/constants";

export const initSeaFreightConsignee = () => ({
  type: INIT_SEA_FREIGHT_CONSIGNEE,
  payload: {}
});

export const createSeaFreightConsignee = ({ seaFreightConsignee }) => ({
  type: CREATE_SEA_FREIGHT_CONSIGNEE,
  payload: { seaFreightConsignee }
});

export const createSeaFreightConsigneeSuccess = ({ seaFreightConsignee }) => ({
  type: CREATE_SEA_FREIGHT_CONSIGNEE_SUCCESS,
  payload: { seaFreightConsignee }
});

export const createSeaFreightConsigneeFailed = ({ error }) => ({
  type: CREATE_SEA_FREIGHT_CONSIGNEE_FAILED,
  payload: { error }
});

export const fetchSeaFreightConsignee = ({ seaFreightConsigneeId }) => ({
  type: FETCH_SEA_FREIGHT_CONSIGNEE,
  payload: { seaFreightConsigneeId }
});

export const fetchSeaFreightConsigneeSuccess = ({ seaFreightConsignee }) => ({
  type: FETCH_SEA_FREIGHT_CONSIGNEE_SUCCESS,
  payload: { seaFreightConsignee }
});

export const fetchSeaFreightConsigneeFailed = ({ error }) => ({
  type: FETCH_SEA_FREIGHT_CONSIGNEE_FAILED,
  payload: { error }
});

export const updateSeaFreightConsignee = ({ seaFreightConsignee }) => ({
  type: UPDATE_SEA_FREIGHT_CONSIGNEE,
  payload: { seaFreightConsignee }
});

export const updateSeaFreightConsigneeSuccess = ({ seaFreightConsignee }) => ({
  type: UPDATE_SEA_FREIGHT_CONSIGNEE_SUCCESS,
  payload: { seaFreightConsignee }
});

export const updateSeaFreightConsigneeFailed = ({ error }) => ({
  type: UPDATE_SEA_FREIGHT_CONSIGNEE_FAILED,
  payload: { error }
});

export const deleteSeaFreightConsignee = ({ seaFreightConsignee }) => ({
  type: DELETE_SEA_FREIGHT_CONSIGNEE,
  payload: { seaFreightConsignee }
});

export const deleteSeaFreightConsigneeSuccess = ({ seaFreightConsignee }) => ({
  type: DELETE_SEA_FREIGHT_CONSIGNEE_SUCCESS,
  payload: { seaFreightConsignee }
});

export const deleteSeaFreightConsigneeFailed = ({ error }) => ({
  type: DELETE_SEA_FREIGHT_CONSIGNEE_FAILED,
  payload: { error }
});
