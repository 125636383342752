import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_TRANSACTION,
  DELETE_TRANSACTION, FETCH_NEW_TRANSACTION,
  FETCH_TRANSACTION,
  UPDATE_TRANSACTION, UPDATE_TRANSACTION_STATUS
} from "redux/model/transactions/transaction/constants";
import {
  apiCreateTransaction,
  apiDeleteTransaction, apiFetchNewTransaction,
  apiFetchTransaction,
  apiUpdateTransaction, apiUpdateTransactionStatus
} from "api/transaction/apiTransaction";
import {
  createTransactionFailed,
  createTransactionSuccess,
  deleteTransactionFailed,
  deleteTransactionSuccess,
  fetchTransactionFailed,
  fetchTransactionSuccess,
  initTransaction,
  updateTransactionFailed,
  updateTransactionSuccess
} from "redux/model/transactions/transaction/transactionActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeFirstLayerModal,
  closeSecondLayerModal, startFirstLayerModalListening,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_TRANSACTIONS_PATH } from "routes/transaction/constants";
import { listLineAttachmentsSuccess } from "redux/model/lineAttachments/lineAttachments/lineAttachmentsActions";
import { listClientsSuccess } from "redux/model/clients/clients/clientsActions";
import { fetchCurrencyExchangeSuccess } from "redux/model/currencyExchanges/currencyExchange/currencyExchangeActions";


function* transactionSaga() {
  yield all([
    fork(watchFetchTransaction),
    fork(watchFetchNewTransaction),
    fork(watchUpdateTransaction),
    fork(watchCreateTransaction),
    fork(watchDeleteTransaction),
    fork(watchUpdateTransactionStatus),
  ]);
}

// Fetch Transaction
export function* watchFetchTransaction() {
  yield takeEvery(FETCH_TRANSACTION, sagaFetchTransaction);
}

export function* sagaFetchTransaction({ payload }) {
  try {
    const response = yield call(apiFetchTransaction, payload);

    const { transaction, lineAttachments } = response.data;
    yield put(fetchTransactionSuccess({ transaction }));
    yield put(listLineAttachmentsSuccess({ lineAttachments }));

  } catch (e) {
    yield put(fetchTransactionFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Fetch New Transaction
export function* watchFetchNewTransaction() {
  yield takeEvery(FETCH_NEW_TRANSACTION, sagaFetchNewTransaction);
}

export function* sagaFetchNewTransaction({ payload }) {
  try {
    const response = yield call(apiFetchNewTransaction);

    const { clients, currencyExchange } = response.data;
    yield put(listClientsSuccess({ clients }));
    yield put(fetchCurrencyExchangeSuccess({ currencyExchange }));

  } catch (e) {
    yield put(fetchTransactionFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Transaction
export function* watchCreateTransaction() {
  yield takeLeading(CREATE_TRANSACTION, sagaCreateTransaction);
}

export function* sagaCreateTransaction({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateTransaction, payload);

    const { transaction } = response.data;
    yield put(createTransactionSuccess({ transaction }));
    yield put(toastSaved({ name: `Transaction` }));


    history.push(generateRoute(INDEX_TRANSACTIONS_PATH));
    yield put(initTransaction());


  } catch (e) {
    yield put(createTransactionFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Transaction
export function* watchUpdateTransaction() {
  yield takeLeading(UPDATE_TRANSACTION, sagaUpdateTransaction);
}

export function* sagaUpdateTransaction({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateTransaction, payload);

    const { transaction } = response.data;
    yield put(updateTransactionSuccess({ transaction }));
    yield put(toastSaved({ name: 'Transaction' }));
    history.push(generateRoute(INDEX_TRANSACTIONS_PATH));
    yield put(initTransaction());

  } catch (e) {
    yield put(updateTransactionFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Transaction Status
export function* watchUpdateTransactionStatus() {
  yield takeLeading(UPDATE_TRANSACTION_STATUS, sagaUpdateTransactionStatus);
}

export function* sagaUpdateTransactionStatus({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateTransactionStatus, payload);

    const { transaction } = response.data;
    yield put(updateTransactionSuccess({ transaction }));
    yield put(toastSaved({ name: 'Transaction' }));
    yield put(initTransaction());

    yield put(startFirstLayerModalListening());
    yield put(closeFirstLayerModal());

  } catch (e) {
    yield put(updateTransactionFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Transaction
export function* watchDeleteTransaction() {
  yield takeLeading(DELETE_TRANSACTION, sagaDeleteTransaction);
}

export function* sagaDeleteTransaction({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteTransaction, payload);

    const { transaction } = response.data;
    yield put(deleteTransactionSuccess({ transaction }));
    yield put(toastDeleted({ name: 'Transaction' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteTransactionFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default transactionSaga;
