import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_PAYMENT,
  DELETE_PAYMENT,
  FETCH_PAYMENT,
  UPDATE_PAYMENT
} from "redux/model/payments/payment/constants";
import {
  apiCreatePayment,
  apiDeletePayment,
  apiFetchPayment,
  apiUpdatePayment
} from "api/payment/apiPayment";
import {
  createPaymentFailed,
  createPaymentSuccess,
  deletePaymentFailed,
  deletePaymentSuccess,
  fetchPaymentFailed,
  fetchPaymentSuccess,
  initPayment,
  updatePaymentFailed,
  updatePaymentSuccess
} from "redux/model/payments/payment/paymentActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_PAYMENTS_PATH } from "routes/payment/constants";


function* paymentSaga() {
  yield all([
    fork(watchFetchPayment),
    fork(watchUpdatePayment),
    fork(watchCreatePayment),
    fork(watchDeletePayment),
  ]);
}

// Fetch Payment
export function* watchFetchPayment() {
  yield takeEvery(FETCH_PAYMENT, sagaFetchPayment);
}

export function* sagaFetchPayment({ payload }) {
  try {
    const response = yield call(apiFetchPayment, payload);

    const { payment } = response.data;
    yield put(fetchPaymentSuccess({ payment }));

  } catch (e) {
    yield put(fetchPaymentFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Payment
export function* watchCreatePayment() {
  yield takeLeading(CREATE_PAYMENT, sagaCreatePayment);
}

export function* sagaCreatePayment({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreatePayment, payload);

    const { payment } = response.data;
    yield put(createPaymentSuccess({ payment }));
    yield put(toastSaved({ name: `Payment` }));


    history.push(generateRoute(INDEX_PAYMENTS_PATH));
    yield put(initPayment());


  } catch (e) {
    yield put(createPaymentFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Payment
export function* watchUpdatePayment() {
  yield takeLeading(UPDATE_PAYMENT, sagaUpdatePayment);
}

export function* sagaUpdatePayment({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdatePayment, payload);

    const { payment } = response.data;
    yield put(updatePaymentSuccess({ payment }));
    yield put(toastSaved({ name: 'Payment' }));
    history.push(generateRoute(INDEX_PAYMENTS_PATH));
    yield put(initPayment());

  } catch (e) {
    yield put(updatePaymentFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Payment
export function* watchDeletePayment() {
  yield takeLeading(DELETE_PAYMENT, sagaDeletePayment);
}

export function* sagaDeletePayment({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeletePayment, payload);

    const { payment } = response.data;
    yield put(deletePaymentSuccess({ payment }));
    yield put(toastDeleted({ name: 'Payment' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deletePaymentFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default paymentSaga;
