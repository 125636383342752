import {
  ASSIGN_TRANSACTION_FIELDS,
  FETCH_NEW_TRANSACTION,
  FETCH_TRANSACTION,
  FETCH_TRANSACTION_FAILED,
  FETCH_TRANSACTION_SUCCESS,
  INIT_TRANSACTION
} from "redux/model/transactions/transaction/constants";

const INIT_STATE = {
  transaction: {},
  loading: true,
  error: null,
};

const transactionReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_TRANSACTION:
      return INIT_STATE;

    case FETCH_TRANSACTION:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_TRANSACTION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        transaction: action.payload.transaction,
      };

    case FETCH_TRANSACTION_FAILED:
      return {
        ...state,
        transaction: {},
        loading: false,
        error: action.payload.error,
      };

    case FETCH_NEW_TRANSACTION:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ASSIGN_TRANSACTION_FIELDS:
      return {
        ...state,
        transaction: {
          ...state.transaction,
          ...action.payload,
        },
      };

    default:
      return { ...state };
  }
};

export default transactionReducers;
