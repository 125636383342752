
export const CREATE_CLIENT_API = {
  url: `/clients`,
  method: 'post'
};

export const INDEX_CLIENTS_API = {
  url: `/clients`,
  method: 'get',
};

export const FETCH_CLIENT_API = {
  url: `/clients/:id`,
  method: 'get',
};

export const UPDATE_CLIENT_API = {
  url: `/clients/:id`,
  method: 'put'
};

export const DELETE_CLIENT_API = {
  url: `/clients/:id`,
  method: 'delete'
};
