
export const CREATE_ORDER_DECLARATION_API = {
  url: `/order_declarations`,
  method: 'post'
};

export const INDEX_ORDER_DECLARATIONS_API = {
  url: `/order_declarations`,
  method: 'get',
};

export const FETCH_ORDER_DECLARATION_API = {
  url: `/order_declarations/:id`,
  method: 'get',
};

export const UPDATE_ORDER_DECLARATION_API = {
  url: `/order_declarations/:id`,
  method: 'put'
};

export const DELETE_ORDER_DECLARATION_API = {
  url: `/order_declarations/:id`,
  method: 'delete'
};
