import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_SEA_FREIGHT_CONSIGNEE,
  DELETE_SEA_FREIGHT_CONSIGNEE,
  FETCH_SEA_FREIGHT_CONSIGNEE,
  UPDATE_SEA_FREIGHT_CONSIGNEE
} from "redux/model/seaFreightConsignees/seaFreightConsignee/constants";
import {
  apiCreateSeaFreightConsignee,
  apiDeleteSeaFreightConsignee,
  apiFetchSeaFreightConsignee,
  apiUpdateSeaFreightConsignee
} from "api/seaFreightConsignee/apiSeaFreightConsignee";
import {
  createSeaFreightConsigneeFailed,
  createSeaFreightConsigneeSuccess,
  deleteSeaFreightConsigneeFailed,
  deleteSeaFreightConsigneeSuccess,
  fetchSeaFreightConsigneeFailed,
  fetchSeaFreightConsigneeSuccess,
  initSeaFreightConsignee,
  updateSeaFreightConsigneeFailed,
  updateSeaFreightConsigneeSuccess
} from "redux/model/seaFreightConsignees/seaFreightConsignee/seaFreightConsigneeActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_SEA_FREIGHT_CONSIGNEES_PATH } from "routes/seaFreightConsignee/constants";


function* seaFreightConsigneeSaga() {
  yield all([
    fork(watchFetchSeaFreightConsignee),
    fork(watchUpdateSeaFreightConsignee),
    fork(watchCreateSeaFreightConsignee),
    fork(watchDeleteSeaFreightConsignee),
  ]);
}

// Fetch SeaFreightConsignee
export function* watchFetchSeaFreightConsignee() {
  yield takeEvery(FETCH_SEA_FREIGHT_CONSIGNEE, sagaFetchSeaFreightConsignee);
}

export function* sagaFetchSeaFreightConsignee({ payload }) {
  try {
    const response = yield call(apiFetchSeaFreightConsignee, payload);

    const { seaFreightConsignee } = response.data;
    yield put(fetchSeaFreightConsigneeSuccess({ seaFreightConsignee }));

  } catch (e) {
    yield put(fetchSeaFreightConsigneeFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create SeaFreightConsignee
export function* watchCreateSeaFreightConsignee() {
  yield takeLeading(CREATE_SEA_FREIGHT_CONSIGNEE, sagaCreateSeaFreightConsignee);
}

export function* sagaCreateSeaFreightConsignee({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateSeaFreightConsignee, payload);

    const { seaFreightConsignee } = response.data;
    yield put(createSeaFreightConsigneeSuccess({ seaFreightConsignee }));
    yield put(toastSaved({ name: `SeaFreightConsignee` }));


    history.push(generateRoute(INDEX_SEA_FREIGHT_CONSIGNEES_PATH));
    yield put(initSeaFreightConsignee());


  } catch (e) {
    yield put(createSeaFreightConsigneeFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update SeaFreightConsignee
export function* watchUpdateSeaFreightConsignee() {
  yield takeLeading(UPDATE_SEA_FREIGHT_CONSIGNEE, sagaUpdateSeaFreightConsignee);
}

export function* sagaUpdateSeaFreightConsignee({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateSeaFreightConsignee, payload);

    const { seaFreightConsignee } = response.data;
    yield put(updateSeaFreightConsigneeSuccess({ seaFreightConsignee }));
    yield put(toastSaved({ name: 'SeaFreightConsignee' }));
    history.push(generateRoute(INDEX_SEA_FREIGHT_CONSIGNEES_PATH));
    yield put(initSeaFreightConsignee());

  } catch (e) {
    yield put(updateSeaFreightConsigneeFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete SeaFreightConsignee
export function* watchDeleteSeaFreightConsignee() {
  yield takeLeading(DELETE_SEA_FREIGHT_CONSIGNEE, sagaDeleteSeaFreightConsignee);
}

export function* sagaDeleteSeaFreightConsignee({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteSeaFreightConsignee, payload);

    const { seaFreightConsignee } = response.data;
    yield put(deleteSeaFreightConsigneeSuccess({ seaFreightConsignee }));
    yield put(toastDeleted({ name: 'SeaFreightConsignee' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteSeaFreightConsigneeFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default seaFreightConsigneeSaga;
