import {
  ERROR_FORM_PAYMENT,
  LOADING_FORM_PAYMENT,
  RESET_FORM_PAYMENT
} from "redux/ui/form/payment/constants";
import {
  CREATE_PAYMENT,
  CREATE_PAYMENT_FAILED,
  CREATE_PAYMENT_SUCCESS,
  UPDATE_PAYMENT, UPDATE_PAYMENT_FAILED, UPDATE_PAYMENT_SUCCESS
} from "redux/model/payments/payment/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formPaymentReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_PAYMENT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_PAYMENT:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_PAYMENT:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_PAYMENT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_PAYMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_PAYMENT:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_PAYMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formPaymentReducers;
