import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_SEA_FREIGHT_CONSIGNEES } from "redux/model/seaFreightConsignees/seaFreightConsignees/constants";
import { apiListSeaFreightConsignees } from "api/seaFreightConsignee/apiSeaFreightConsignee";
import { listSeaFreightConsigneesFailed, listSeaFreightConsigneesSuccess } from "redux/model/seaFreightConsignees/seaFreightConsignees/seaFreightConsigneesActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* seaFreightConsigneesSaga() {
  yield all([
    fork(watchListSeaFreightConsignees),
  ]);
}

// List SeaFreightConsignees
export function* watchListSeaFreightConsignees() {
  yield takeEvery(LIST_SEA_FREIGHT_CONSIGNEES, sagaListSeaFreightConsignees);
}

export function* sagaListSeaFreightConsignees() {
  try {
    const response = yield call(apiListSeaFreightConsignees);

    const { seaFreightConsignees } = response.data;

    yield put(listSeaFreightConsigneesSuccess({ seaFreightConsignees }));

  } catch (e) {
    yield put(listSeaFreightConsigneesFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default seaFreightConsigneesSaga;
