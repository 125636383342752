
export const CREATE_LINE_ITEM_DECLARATION_API = {
  url: `/lineItemDeclarations`,
  method: 'post'
};

export const INDEX_LINE_ITEM_DECLARATIONS_API = {
  url: `/lineItemDeclarations`,
  method: 'get',
};

export const FETCH_LINE_ITEM_DECLARATION_API = {
  url: `/lineItemDeclarations/:id`,
  method: 'get',
};

export const UPDATE_LINE_ITEM_DECLARATION_API = {
  url: `/lineItemDeclarations/:id`,
  method: 'put'
};

export const DELETE_LINE_ITEM_DECLARATION_API = {
  url: `/lineItemDeclarations/:id`,
  method: 'delete'
};
