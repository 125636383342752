import {
  ASSIGN_LINE_ITEM_DECLARATION_DESCRIPTION_ZH,
  ASSIGN_LINE_ITEM_DECLARATION_QUANTITY,
  ASSIGN_LINE_ITEM_DECLARATION_UNIT_PRICE,
  CREATE_LINE_ITEM_DECLARATION,
  CREATE_LINE_ITEM_DECLARATION_FAILED,
  CREATE_LINE_ITEM_DECLARATION_SUCCESS,
  DELETE_LINE_ITEM_DECLARATION,
  DELETE_LINE_ITEM_DECLARATION_FAILED,
  DELETE_LINE_ITEM_DECLARATION_SUCCESS,
  FETCH_LINE_ITEM_DECLARATION,
  FETCH_LINE_ITEM_DECLARATION_FAILED,
  FETCH_LINE_ITEM_DECLARATION_SUCCESS,
  INIT_LINE_ITEM_DECLARATION,
  NEW_LINE_ITEM_DECLARATION,
  PULL_LINE_ITEM_DECLARATION, SET_LINE_ITEM_DECLARATION,
  UPDATE_LINE_ITEM_DECLARATION,
  UPDATE_LINE_ITEM_DECLARATION_FAILED,
  UPDATE_LINE_ITEM_DECLARATION_SUCCESS
} from "redux/model/lineItemDeclarations/lineItemDeclaration/constants";

export const initLineItemDeclaration = () => ({
  type: INIT_LINE_ITEM_DECLARATION,
  payload: {}
});

export const createLineItemDeclaration = ({ lineItemDeclaration }) => ({
  type: CREATE_LINE_ITEM_DECLARATION,
  payload: { lineItemDeclaration }
});

export const createLineItemDeclarationSuccess = ({ lineItemDeclaration }) => ({
  type: CREATE_LINE_ITEM_DECLARATION_SUCCESS,
  payload: { lineItemDeclaration }
});

export const createLineItemDeclarationFailed = ({ error }) => ({
  type: CREATE_LINE_ITEM_DECLARATION_FAILED,
  payload: { error }
});

export const fetchLineItemDeclaration = ({ lineItemDeclarationId }) => ({
  type: FETCH_LINE_ITEM_DECLARATION,
  payload: { lineItemDeclarationId }
});

export const fetchLineItemDeclarationSuccess = ({ lineItemDeclaration }) => ({
  type: FETCH_LINE_ITEM_DECLARATION_SUCCESS,
  payload: { lineItemDeclaration }
});

export const fetchLineItemDeclarationFailed = ({ error }) => ({
  type: FETCH_LINE_ITEM_DECLARATION_FAILED,
  payload: { error }
});

export const updateLineItemDeclaration = ({ lineItemDeclaration }) => ({
  type: UPDATE_LINE_ITEM_DECLARATION,
  payload: { lineItemDeclaration }
});

export const updateLineItemDeclarationSuccess = ({ lineItemDeclaration }) => ({
  type: UPDATE_LINE_ITEM_DECLARATION_SUCCESS,
  payload: { lineItemDeclaration }
});

export const updateLineItemDeclarationFailed = ({ error }) => ({
  type: UPDATE_LINE_ITEM_DECLARATION_FAILED,
  payload: { error }
});

export const deleteLineItemDeclaration = ({ lineItemDeclaration }) => ({
  type: DELETE_LINE_ITEM_DECLARATION,
  payload: { lineItemDeclaration }
});

export const deleteLineItemDeclarationSuccess = ({ lineItemDeclaration }) => ({
  type: DELETE_LINE_ITEM_DECLARATION_SUCCESS,
  payload: { lineItemDeclaration }
});

export const deleteLineItemDeclarationFailed = ({ error }) => ({
  type: DELETE_LINE_ITEM_DECLARATION_FAILED,
  payload: { error }
});

export const newLineItemDeclaration = ({ lineItemDeclaration }) => ({
  type: NEW_LINE_ITEM_DECLARATION,
  payload: { lineItemDeclaration }
});

export const pullLineItemDeclaration = ({ lineItemDeclaration }) => ({
  type: PULL_LINE_ITEM_DECLARATION,
  payload: { lineItemDeclaration }
});

export const setLineItemDeclaration = ({ lineItemDeclaration }) => ({
  type: SET_LINE_ITEM_DECLARATION,
  payload: { lineItemDeclaration }
});