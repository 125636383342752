import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  extractClassNameFrom,
  generateErrorMessage,
  generateRoute,
  history,
  throwFrontError
} from "@tmatt-tech/react_common";
import { CREATE_PACK, DELETE_PACK, FETCH_PACK, SPLIT_PACK, UPDATE_PACK } from "redux/model/packs/pack/constants";
import { apiCreatePack, apiDeletePack, apiFetchPack, apiSplitPack, apiUpdatePack } from "api/pack/apiPack";
import {
  createPackFailed,
  createPackSuccess,
  deletePackFailed,
  deletePackSuccess,
  fetchPackFailed,
  fetchPackSuccess,
  initPack,
  updatePackFailed,
  updatePackSuccess
} from "redux/model/packs/pack/packActions";
import {
  toastDefault,
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeFirstLayerModal,
  closeSecondLayerModal,
  startFirstLayerModalListening,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_PACKS_PATH } from "routes/pack/constants";
import { updateOrderPackFailed, updateOrderPackSuccess } from "redux/model/orderPacks/orderPack/orderPackActions";
import { listLineItemsSuccess } from "redux/model/lineItems/lineItems/lineItemsActions";
import { listPacksSuccess } from "redux/model/packs/packs/packsActions";
import { listLineAttachmentsSuccess } from "redux/model/lineAttachments/lineAttachments/lineAttachmentsActions";
import { listClientsSuccess } from "redux/model/clients/clients/clientsActions";
import { updateOrderSeaFreightSuccess } from "redux/model/orderSeaFreights/orderSeaFreight/orderSeaFreightActions";
import { listPackDeclarationsSuccess } from "redux/model/packDeclarations/packDeclarations/packDeclarationsActions";


function* packSaga() {
  yield all([
    fork(watchFetchPack),
    fork(watchUpdatePack),
    fork(watchCreatePack),
    fork(watchDeletePack),
    fork(watchSplitPack),
  ]);
}

// Fetch Pack
export function* watchFetchPack() {
  yield takeEvery(FETCH_PACK, sagaFetchPack);
}

export function* sagaFetchPack({ payload }) {
  try {
    const response = yield call(apiFetchPack, payload);

    const { pack, lineAttachments, clients, packDeclarations } = response.data;
    yield put(fetchPackSuccess({ pack }));
    yield put(listLineAttachmentsSuccess({ lineAttachments }));
    yield put(listClientsSuccess({ clients }));
    yield put(listPackDeclarationsSuccess({ packDeclarations }));

  } catch (e) {
    yield put(fetchPackFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create Pack
export function* watchCreatePack() {
  yield takeLeading(CREATE_PACK, sagaCreatePack);
}

export function* sagaCreatePack({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreatePack, payload);

    const { pack } = response.data;
    yield put(createPackSuccess({ pack }));
    yield put(toastSaved({ name: `Pack` }));


    history.push(generateRoute(INDEX_PACKS_PATH));
    yield put(initPack());


  } catch (e) {
    yield put(createPackFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update Pack
export function* watchUpdatePack() {
  yield takeLeading(UPDATE_PACK, sagaUpdatePack);
}

export function* sagaUpdatePack({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdatePack, payload);

    const { pack } = response.data;
    yield put(updatePackSuccess({ pack }));
    yield put(toastSaved({ name: 'Pack' }));
    yield put(initPack());

    if (payload.target) {
      switch (extractClassNameFrom(payload.target.id)) {
        case 'orderPack':
          yield put(startFirstLayerModalListening());
          yield put(closeFirstLayerModal());
          break;

        case 'orderSeaFreight':
          yield put(startFirstLayerModalListening());
          yield put(closeFirstLayerModal());
          break;

        default:
        // do nothing
      }
    } else {
      history.push(generateRoute(INDEX_PACKS_PATH));
    }

  } catch (e) {
    yield put(updatePackFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete Pack
export function* watchDeletePack() {
  yield takeLeading(DELETE_PACK, sagaDeletePack);
}

export function* sagaDeletePack({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeletePack, payload);

    const { pack } = response.data;
    yield put(deletePackSuccess({ pack }));
    yield put(toastDeleted({ name: 'Pack' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deletePackFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Split Pack
export function* watchSplitPack() {
  yield takeLeading(SPLIT_PACK, sagaSplitPack);
}

export function* sagaSplitPack({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiSplitPack, payload);

    const { lineItems, packs } = response.data;

    const { orderPack, orderSeaFreight } = response.data;
    if (orderPack) {
      yield put(updateOrderPackSuccess({ orderPack }));
    }

    if (orderSeaFreight) {
      yield put(updateOrderSeaFreightSuccess({ orderSeaFreight }));
    }

    yield put(listLineItemsSuccess({ lineItems }));
    yield put(listPacksSuccess({ packs }));

    yield put(startFirstLayerModalListening());
    yield put(closeFirstLayerModal());
    yield put(toastDefault({ message: 'Pack has been split' }))

  } catch (e) {
    yield put(updateOrderPackFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default packSaga;
