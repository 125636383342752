
export const CREATE_SEA_FREIGHT_CONTAINER_DECLARATION_API = {
  url: `/seaFreightContainerDeclarations`,
  method: 'post'
};

export const INDEX_SEA_FREIGHT_CONTAINER_DECLARATIONS_API = {
  url: `/seaFreightContainerDeclarations`,
  method: 'get',
};

export const FETCH_SEA_FREIGHT_CONTAINER_DECLARATION_API = {
  url: `/seaFreightContainerDeclarations/:id`,
  method: 'get',
};

export const UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION_API = {
  url: `/seaFreightContainerDeclarations/:id`,
  method: 'put'
};

export const DELETE_SEA_FREIGHT_CONTAINER_DECLARATION_API = {
  url: `/seaFreightContainerDeclarations/:id`,
  method: 'delete'
};
