export const INIT_ORDER_DECLARATION = 'INIT_ORDER_DECLARATION';

export const FETCH_ORDER_DECLARATION = 'FETCH_ORDER_DECLARATION';
export const FETCH_ORDER_DECLARATION_SUCCESS = 'FETCH_ORDER_DECLARATION_SUCCESS';
export const FETCH_ORDER_DECLARATION_FAILED = 'FETCH_ORDER_DECLARATION_FAILED';

export const CREATE_ORDER_DECLARATION = 'CREATE_ORDER_DECLARATION';
export const CREATE_ORDER_DECLARATION_SUCCESS = 'CREATE_ORDER_DECLARATION_SUCCESS';
export const CREATE_ORDER_DECLARATION_FAILED = 'CREATE_ORDER_DECLARATION_FAILED';

export const UPDATE_ORDER_DECLARATION = 'UPDATE_ORDER_DECLARATION';
export const UPDATE_ORDER_DECLARATION_SUCCESS = 'UPDATE_ORDER_DECLARATION_SUCCESS';
export const UPDATE_ORDER_DECLARATION_FAILED = 'UPDATE_ORDER_DECLARATION_FAILED';

export const DELETE_ORDER_DECLARATION = 'DELETE_ORDER_DECLARATION';
export const DELETE_ORDER_DECLARATION_SUCCESS = 'DELETE_ORDER_DECLARATION_SUCCESS';
export const DELETE_ORDER_DECLARATION_FAILED = 'DELETE_ORDER_DECLARATION_FAILED';

export const ASSIGN_ORDER_DECLARATION_EXPORTER_NAME = 'ASSIGN_ORDER_DECLARATION_EXPORTER_NAME';
export const ASSIGN_ORDER_DECLARATION_EXPORTER_ADDRESS = 'ASSIGN_ORDER_DECLARATION_EXPORTER_ADDRESS';
export const ASSIGN_ORDER_DECLARATION_EXPORTER_PHONE = 'ASSIGN_ORDER_DECLARATION_EXPORTER_PHONE';
export const ASSIGN_ORDER_DECLARATION_EXPORTER_EMAIL = 'ASSIGN_ORDER_DECLARATION_EXPORTER_EMAIL';
export const ASSIGN_ORDER_DECLARATION_CONSIGNEE_NAME = 'ASSIGN_ORDER_DECLARATION_CONSIGNEE_NAME';
export const ASSIGN_ORDER_DECLARATION_CONSIGNEE_ADDRESS = 'ASSIGN_ORDER_DECLARATION_CONSIGNEE_ADDRESS';
export const ASSIGN_ORDER_DECLARATION_CONSIGNEE_PHONE = 'ASSIGN_ORDER_DECLARATION_CONSIGNEE_PHONE';
export const ASSIGN_ORDER_DECLARATION_CONSIGNEE_EMAIL = 'ASSIGN_ORDER_DECLARATION_CONSIGNEE_EMAIL';
export const ASSIGN_ORDER_DECLARATION_FROM_ATR = 'ASSIGN_ORDER_DECLARATION_FROM_ATR';
export const ASSIGN_ORDER_DECLARATION_DATE_PACK_TIME = 'ASSIGN_ORDER_DECLARATION_DATE_PACK_TIME';
export const ASSIGN_ORDER_DECLARATION_REMARKS = 'ASSIGN_ORDER_DECLARATION_REMARKS';
export const ASSIGN_ORDER_DECLARATION_TO = 'ASSIGN_ORDER_DECLARATION_TO';
export const ASSIGN_ORDER_DECLARATION_BY = 'ASSIGN_ORDER_DECLARATION_BY';
export const ASSIGN_ORDER_DECLARATION_DELIVERY_AND_PAYMENT_TERM = 'ASSIGN_ORDER_DECLARATION_DELIVERY_AND_PAYMENT_TERM';
export const ASSIGN_ORDER_DECLARATION_NO_AND_DATE_OF_INVOICE = 'ASSIGN_ORDER_DECLARATION_NO_AND_DATE_OF_INVOICE';
export const ASSIGN_ORDER_DECLARATION_NO_AND_DATE_OF_PACKING_LIST = 'ASSIGN_ORDER_DECLARATION_NO_AND_DATE_OF_PACKING_LIST';

export const ASSIGN_ORDER_DECLARATION_EXPORTER_ID = 'ASSIGN_ORDER_DECLARATION_EXPORTER_ID';
export const ASSIGN_ORDER_DECLARATION_CONSIGNEE_ID = 'ASSIGN_ORDER_DECLARATION_CONSIGNEE_ID';