import {
  ERROR_FORM_LINE_ITEM,
  LOADING_FORM_LINE_ITEM,
  RESET_FORM_LINE_ITEM
} from "redux/ui/form/lineItem/constants";
import {
  CREATE_LINE_ITEM,
  CREATE_LINE_ITEM_FAILED,
  CREATE_LINE_ITEM_SUCCESS, TRANSFER_LINE_ITEM,
  UPDATE_LINE_ITEM, UPDATE_LINE_ITEM_FAILED, UPDATE_LINE_ITEM_SUCCESS
} from "redux/model/lineItems/lineItem/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formLineItemReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_LINE_ITEM:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_LINE_ITEM:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_LINE_ITEM:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_LINE_ITEM:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_LINE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_LINE_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_LINE_ITEM:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_LINE_ITEM_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_LINE_ITEM_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case TRANSFER_LINE_ITEM:
      return {
        ...state,
        loading: true,
        error: null
      };


    default:
      return { ...state };
  }
};

export default formLineItemReducers;
