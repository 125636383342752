import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_SEA_FREIGHT_EXPORTERS } from "redux/model/seaFreightExporters/seaFreightExporters/constants";
import { apiListSeaFreightExporters } from "api/seaFreightExporter/apiSeaFreightExporter";
import { listSeaFreightExportersFailed, listSeaFreightExportersSuccess } from "redux/model/seaFreightExporters/seaFreightExporters/seaFreightExportersActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* seaFreightExportersSaga() {
  yield all([
    fork(watchListSeaFreightExporters),
  ]);
}

// List SeaFreightExporters
export function* watchListSeaFreightExporters() {
  yield takeEvery(LIST_SEA_FREIGHT_EXPORTERS, sagaListSeaFreightExporters);
}

export function* sagaListSeaFreightExporters() {
  try {
    const response = yield call(apiListSeaFreightExporters);

    const { seaFreightExporters } = response.data;

    yield put(listSeaFreightExportersSuccess({ seaFreightExporters }));

  } catch (e) {
    yield put(listSeaFreightExportersFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default seaFreightExportersSaga;
