import {
  ASSIGN_PACK_ARRIVED_AT,
  ASSIGN_PACK_CLIENT,
  ASSIGN_PACK_DOMESTIC_WAYBILL_ITEM_NUMBER,
  ASSIGN_PACK_DOMESTIC_WAYBILL_NUMBER,
  ASSIGN_PACK_GROSS_WEIGHT,
  ASSIGN_PACK_HEIGHT,
  ASSIGN_PACK_IS_REMOVE_PACKAGE,
  ASSIGN_PACK_ITEM_DESCRIPTION,
  ASSIGN_PACK_LENGTH,
  ASSIGN_PACK_NOTE,
  ASSIGN_PACK_QUANTITY,
  ASSIGN_PACK_RECIPIENT,
  ASSIGN_PACK_RECIPIENT_ADDRESS,
  ASSIGN_PACK_RECIPIENT_MOBILE,
  ASSIGN_PACK_UNIT,
  ASSIGN_PACK_VARIETY,
  ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_NUMBER,
  ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_POSITION_NUMBER,
  ASSIGN_PACK_WIDTH,
  ASSIGN_PACK_MATERIAL,
  ASSIGN_PACK_UNIT_PRICE,
  ASSIGN_PACK_TOATL_AMOUNT,
  FETCH_PACK,
  FETCH_PACK_FAILED,
  FETCH_PACK_SUCCESS,
  INIT_PACK,
  ASSIGN_PACK_START_AT,
  ASSIGN_PACK_PORT_OF_DEPARTURE,
  ASSIGN_PACK_PORT_OF_DESTINATION,
  ASSIGN_PACK_LOGISTIC_METHOD,
  ASSIGN_PACK_CONTAINER_CATEGORY,
  ASSIGN_PACK_IS_PAID_FOR_CLIENT,
  ASSIGN_PACK_IS_PAID_FOR_AUSGO,
  ASSIGN_PACK_SALE_UNIT_PRICE,
  ASSIGN_PACK_SALE_TOTAL_AMOUNT,
  ASSIGN_PACK_PROFIT,
  ASSIGN_PACK_CURRENCY,
  ASSIGN_PACK_STATUS,
  ASSIGN_PACK_REAL_COST,
  ASSIGN_PACK_INVOICE_TOTAL
} from "redux/model/packs/pack/constants";

const INIT_STATE = {
  pack: {},
  loading: true,
  error: null,
};

const packReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PACK:
      return INIT_STATE;

    case FETCH_PACK:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PACK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        pack: action.payload.pack,
      };

    case FETCH_PACK_FAILED:
      return {
        ...state,
        pack: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_PACK_GROSS_WEIGHT:
      return {
        ...state,
        pack: {
          ...state.pack,
          grossWeight: action.payload.grossWeight,
        }
      }

    case ASSIGN_PACK_HEIGHT:
      return {
        ...state,
        pack: {
          ...state.pack,
          height: action.payload.height,
        }
      }

    case ASSIGN_PACK_LENGTH:
      return {
        ...state,
        pack: {
          ...state.pack,
          length: action.payload.length,
        }
      }

    case ASSIGN_PACK_WIDTH:
      return {
        ...state,
        pack: {
          ...state.pack,
          width: action.payload.width,
        }
      };

    case ASSIGN_PACK_DOMESTIC_WAYBILL_NUMBER:
      return {
        ...state,
        pack: {
          ...state.pack,
          domesticWaybillNumber: action.payload.domesticWaybillNumber,
        }
      };

    case ASSIGN_PACK_DOMESTIC_WAYBILL_ITEM_NUMBER:
      return {
        ...state,
        pack: {
          ...state.pack,
          domesticWaybillItemNumber: action.payload.domesticWaybillItemNumber,
        }
      };

    case ASSIGN_PACK_ITEM_DESCRIPTION:
      return {
        ...state,
        pack: {
          ...state.pack,
          itemDescription: action.payload.itemDescription,
        }
      };

    case ASSIGN_PACK_QUANTITY:
      return {
        ...state,
        pack: {
          ...state.pack,
          quantity: action.payload.quantity,
        }
      };

    case ASSIGN_PACK_UNIT:
      return {
        ...state,
        pack: {
          ...state.pack,
          unit: action.payload.unit,
        }
      };

    case ASSIGN_PACK_RECIPIENT:
      return {
        ...state,
        pack: {
          ...state.pack,
          recipient: action.payload.recipient,
        }
      };

    case ASSIGN_PACK_RECIPIENT_MOBILE:
      return {
        ...state,
        pack: {
          ...state.pack,
          recipientMobile: action.payload.recipientMobile,
        }
      };

    case ASSIGN_PACK_RECIPIENT_ADDRESS:
      return {
        ...state,
        pack: {
          ...state.pack,
          recipientAddress: action.payload.recipientAddress,
        }
      };

    case ASSIGN_PACK_NOTE:
      return {
        ...state,
        pack: {
          ...state.pack,
          note: action.payload.note,
        }
      };

    case ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_NUMBER:
      return {
        ...state,
        pack: {
          ...state.pack,
          warehouseLocationShelfNumber: action.payload.warehouseLocationShelfNumber,
        }
      };

    case ASSIGN_PACK_WAREHOUSE_LOCATION_SHELF_POSITION_NUMBER:
      return {
        ...state,
        pack: {
          ...state.pack,
          warehouseLocationShelfPositionNumber: action.payload.warehouseLocationShelfPositionNumber,
        }
      };

    case ASSIGN_PACK_CLIENT:
      return {
        ...state,
        pack: {
          ...state.pack,
          clientId: action.payload.client ? action.payload.client.id : null,
        }
      };

    case ASSIGN_PACK_IS_REMOVE_PACKAGE:
      return {
        ...state,
        pack: {
          ...state.pack,
          isRemovePackage: action.payload.isRemovePackage,
        }
      };

    case ASSIGN_PACK_ARRIVED_AT:
      return {
        ...state,
        pack: {
          ...state.pack,
          arrivedAt: action.payload.arrivedAt,
        }
      };

    case ASSIGN_PACK_VARIETY:
      return {
        ...state,
        pack: {
          ...state.pack,
          variety: action.payload.variety,
        }
      };

    case ASSIGN_PACK_UNIT_PRICE:
      return {
        ...state,
        pack: {
          ...state.pack,
          unitPrice: action.payload.unitPrice,
        }
      };

    case ASSIGN_PACK_TOATL_AMOUNT:
      return {
        ...state,
        pack: {
          ...state.pack,
          totalAmount: action.payload.totalAmount,
        }
      };

    case ASSIGN_PACK_MATERIAL:
      return {
        ...state,
        pack: {
          ...state.pack,
          material: action.payload.material,
        }
      };
      
    case ASSIGN_PACK_START_AT:
      return {
        ...state,
        pack: {
          ...state.pack,
          startAt: action.payload.startAt,
        }
      };

    case ASSIGN_PACK_PORT_OF_DEPARTURE:
      return {
        ...state,
        pack: {
          ...state.pack,
          portOfDeparture: action.payload.portOfDeparture,
        }
      };

    case ASSIGN_PACK_PORT_OF_DESTINATION:
      return {
        ...state,
        pack: {
          ...state.pack,
          portOfDestination: action.payload.portOfDestination,
        }
      };

    case ASSIGN_PACK_LOGISTIC_METHOD:
      return {
        ...state,
        pack: {
          ...state.pack,
          logisticMathed: action.payload.logisticMathed,
        }
      };

    case ASSIGN_PACK_CONTAINER_CATEGORY:
      return {
        ...state,
        pack: {
          ...state.pack,
          containerCategory: action.payload.containerCategory,
        }
      };

    case ASSIGN_PACK_IS_PAID_FOR_CLIENT:
      return {
        ...state,
        pack: {
          ...state.pack,
          isPaidForClient: action.payload.isPaidForClient,
        }
      };

    case ASSIGN_PACK_IS_PAID_FOR_AUSGO:
      return {
        ...state,
        pack: {
          ...state.pack,
          isPaidForAusgo: action.payload.isPaidForAusgo,
        }
      };

    case ASSIGN_PACK_SALE_UNIT_PRICE:
      return {
        ...state,
        pack: {
          ...state.pack,
          saleUnitPrice: action.payload.saleUnitPrice,
        }
      };

    case ASSIGN_PACK_SALE_TOTAL_AMOUNT:
      return {
        ...state,
        pack: {
          ...state.pack,
          saleTotalAmount: action.payload.saleTotalAmount,
        }
      };


    case ASSIGN_PACK_SALE_TOTAL_AMOUNT:
      return {
        ...state,
        pack: {
          ...state.pack,
          saleTotalAmount: action.payload.saleTotalAmount,
        }
      };


    case ASSIGN_PACK_PROFIT:
      return {
        ...state,
        pack: {
          ...state.pack,
          profit: action.payload.profit,
        }
      };  

    case ASSIGN_PACK_CURRENCY:
      return {
        ...state,
        pack: {
          ...state.pack,
          currency: action.payload.currency,
        }
      };

    case ASSIGN_PACK_STATUS:
      return {
        ...state,
        pack: {
          ...state.pack,
          status: action.payload.status,
        }
      }

    case ASSIGN_PACK_REAL_COST:
      return {
        ...state,
        pack: {
          ...state.pack,
          realCost: action.payload.realCost,
        }
      }

    case ASSIGN_PACK_INVOICE_TOTAL:
      return {
        ...state,
        pack: {
          ...state.pack,
          invoiceTotal: action.payload.invoiceTotal,
        }
      }
    default:
      return { ...state };
  }
};

export default packReducers;
