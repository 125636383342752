export const INIT_SEA_FREIGHT_EXPORTER = 'INIT_SEA_FREIGHT_EXPORTER';

export const FETCH_SEA_FREIGHT_EXPORTER = 'FETCH_SEA_FREIGHT_EXPORTER';
export const FETCH_SEA_FREIGHT_EXPORTER_SUCCESS = 'FETCH_SEA_FREIGHT_EXPORTER_SUCCESS';
export const FETCH_SEA_FREIGHT_EXPORTER_FAILED = 'FETCH_SEA_FREIGHT_EXPORTER_FAILED';

export const CREATE_SEA_FREIGHT_EXPORTER = 'CREATE_SEA_FREIGHT_EXPORTER';
export const CREATE_SEA_FREIGHT_EXPORTER_SUCCESS = 'CREATE_SEA_FREIGHT_EXPORTER_SUCCESS';
export const CREATE_SEA_FREIGHT_EXPORTER_FAILED = 'CREATE_SEA_FREIGHT_EXPORTER_FAILED';

export const UPDATE_SEA_FREIGHT_EXPORTER = 'UPDATE_SEA_FREIGHT_EXPORTER';
export const UPDATE_SEA_FREIGHT_EXPORTER_SUCCESS = 'UPDATE_SEA_FREIGHT_EXPORTER_SUCCESS';
export const UPDATE_SEA_FREIGHT_EXPORTER_FAILED = 'UPDATE_SEA_FREIGHT_EXPORTER_FAILED';

export const DELETE_SEA_FREIGHT_EXPORTER = 'DELETE_SEA_FREIGHT_EXPORTER';
export const DELETE_SEA_FREIGHT_EXPORTER_SUCCESS = 'DELETE_SEA_FREIGHT_EXPORTER_SUCCESS';
export const DELETE_SEA_FREIGHT_EXPORTER_FAILED = 'DELETE_SEA_FREIGHT_EXPORTER_FAILED';
