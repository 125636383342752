import {
  CREATE_SEA_FREIGHT_EXPORTER,
  CREATE_SEA_FREIGHT_EXPORTER_FAILED,
  CREATE_SEA_FREIGHT_EXPORTER_SUCCESS,
  DELETE_SEA_FREIGHT_EXPORTER, DELETE_SEA_FREIGHT_EXPORTER_FAILED, DELETE_SEA_FREIGHT_EXPORTER_SUCCESS,
  FETCH_SEA_FREIGHT_EXPORTER,
  FETCH_SEA_FREIGHT_EXPORTER_FAILED,
  FETCH_SEA_FREIGHT_EXPORTER_SUCCESS,
  INIT_SEA_FREIGHT_EXPORTER,
  UPDATE_SEA_FREIGHT_EXPORTER,
  UPDATE_SEA_FREIGHT_EXPORTER_FAILED,
  UPDATE_SEA_FREIGHT_EXPORTER_SUCCESS
} from "redux/model/seaFreightExporters/seaFreightExporter/constants";

export const initSeaFreightExporter = () => ({
  type: INIT_SEA_FREIGHT_EXPORTER,
  payload: {}
});

export const createSeaFreightExporter = ({ seaFreightExporter }) => ({
  type: CREATE_SEA_FREIGHT_EXPORTER,
  payload: { seaFreightExporter }
});

export const createSeaFreightExporterSuccess = ({ seaFreightExporter }) => ({
  type: CREATE_SEA_FREIGHT_EXPORTER_SUCCESS,
  payload: { seaFreightExporter }
});

export const createSeaFreightExporterFailed = ({ error }) => ({
  type: CREATE_SEA_FREIGHT_EXPORTER_FAILED,
  payload: { error }
});

export const fetchSeaFreightExporter = ({ seaFreightExporterId }) => ({
  type: FETCH_SEA_FREIGHT_EXPORTER,
  payload: { seaFreightExporterId }
});

export const fetchSeaFreightExporterSuccess = ({ seaFreightExporter }) => ({
  type: FETCH_SEA_FREIGHT_EXPORTER_SUCCESS,
  payload: { seaFreightExporter }
});

export const fetchSeaFreightExporterFailed = ({ error }) => ({
  type: FETCH_SEA_FREIGHT_EXPORTER_FAILED,
  payload: { error }
});

export const updateSeaFreightExporter = ({ seaFreightExporter }) => ({
  type: UPDATE_SEA_FREIGHT_EXPORTER,
  payload: { seaFreightExporter }
});

export const updateSeaFreightExporterSuccess = ({ seaFreightExporter }) => ({
  type: UPDATE_SEA_FREIGHT_EXPORTER_SUCCESS,
  payload: { seaFreightExporter }
});

export const updateSeaFreightExporterFailed = ({ error }) => ({
  type: UPDATE_SEA_FREIGHT_EXPORTER_FAILED,
  payload: { error }
});

export const deleteSeaFreightExporter = ({ seaFreightExporter }) => ({
  type: DELETE_SEA_FREIGHT_EXPORTER,
  payload: { seaFreightExporter }
});

export const deleteSeaFreightExporterSuccess = ({ seaFreightExporter }) => ({
  type: DELETE_SEA_FREIGHT_EXPORTER_SUCCESS,
  payload: { seaFreightExporter }
});

export const deleteSeaFreightExporterFailed = ({ error }) => ({
  type: DELETE_SEA_FREIGHT_EXPORTER_FAILED,
  payload: { error }
});
