import {
  DOWNLOAD_UNCLAIMED_PACKS_CSV,
  INIT_PACKS,
  LIST_PACKS,
  LIST_PACKS_FAILED,
  LIST_PACKS_SUCCESS
} from "redux/model/packs/packs/constants";

export const initPacks = () => ({
  type: INIT_PACKS,
  payload: {}
});

export const listPacks = ({ page, query }) => ({
  type: LIST_PACKS,
  payload: { page, query }
});

export const listPacksSuccess = ({ packs, totalSize }) => ({
  type: LIST_PACKS_SUCCESS,
  payload: { packs, totalSize }
});

export const listPacksFailed = ({ error }) => ({
  type: LIST_PACKS_FAILED,
  payload: { error }
});

export const downloadUnclaimedPacksCsv = () => ({
  type: DOWNLOAD_UNCLAIMED_PACKS_CSV,
  payload: {}
});