import {
  ASSIGN_ORDER_SEA_FREIGHT_ADDITIONAL_FEE,
  ASSIGN_ORDER_SEA_FREIGHT_BILLABLE_WEIGHT,
  ASSIGN_ORDER_SEA_FREIGHT_DEDUCTION_AMOUNT,
  ASSIGN_ORDER_SEA_FREIGHT_DELIVERY_OR_PICK_UP,
  ASSIGN_ORDER_SEA_FREIGHT_DISCOUNT,
  ASSIGN_ORDER_SEA_FREIGHT_DISCOUNT_RATE,
  ASSIGN_ORDER_SEA_FREIGHT_ESTIMATED_COST,
  ASSIGN_ORDER_SEA_FREIGHT_EXPORT_DATE,
  ASSIGN_ORDER_SEA_FREIGHT_GROSS_WEIGHT,
  ASSIGN_ORDER_SEA_FREIGHT_HEIGHT,
  ASSIGN_ORDER_SEA_FREIGHT_INTERNATIONAL_WAYBILL_NUMBER,
  ASSIGN_ORDER_SEA_FREIGHT_LENGTH,
  ASSIGN_ORDER_SEA_FREIGHT_NOTE,
  ASSIGN_ORDER_SEA_FREIGHT_ORDER_FEE,
  ASSIGN_ORDER_SEA_FREIGHT_QUOTATION,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_ADDRESS,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_CITY,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_COUNTRY,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_EMAIL,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_MOBILE,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_NAME,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_POSTCODE,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_STATE,
  ASSIGN_ORDER_SEA_FREIGHT_STATUS,
  ASSIGN_ORDER_SEA_FREIGHT_TITLE,
  ASSIGN_ORDER_SEA_FREIGHT_TRACKING_NUMBER,
  ASSIGN_ORDER_SEA_FREIGHT_VOLUME_WEIGHT,
  ASSIGN_ORDER_SEA_FREIGHT_WIDTH,
  CREATE_ORDER_SEA_FREIGHT,
  CREATE_ORDER_SEA_FREIGHT_FAILED,
  CREATE_ORDER_SEA_FREIGHT_SUCCESS,
  DELETE_ORDER_SEA_FREIGHT,
  DELETE_ORDER_SEA_FREIGHT_FAILED,
  DELETE_ORDER_SEA_FREIGHT_SUCCESS, DOWNLOAD_ORDER_SEA_FREIGHT,
  FETCH_ORDER_SEA_FREIGHT,
  FETCH_ORDER_SEA_FREIGHT_FAILED,
  FETCH_ORDER_SEA_FREIGHT_SUCCESS,
  INIT_ORDER_SEA_FREIGHT,
  MERGE_ORDER_SEA_FREIGHTS,
  SPLIT_ORDER_SEA_FREIGHT,
  TRANSFER_ORDER_SEA_FREIGHT,
  TRANSFER_ORDER_SEA_FREIGHT_FAILED,
  TRANSFER_ORDER_SEA_FREIGHT_SUCCESS,
  UPDATE_ORDER_SEA_FREIGHT,
  UPDATE_ORDER_SEA_FREIGHT_FAILED,
  UPDATE_ORDER_SEA_FREIGHT_SUCCESS
} from "redux/model/orderSeaFreights/orderSeaFreight/constants";

export const initOrderSeaFreight = () => ({
  type: INIT_ORDER_SEA_FREIGHT,
  payload: {}
});

export const createOrderSeaFreight = ({ orderSeaFreight }) => ({
  type: CREATE_ORDER_SEA_FREIGHT,
  payload: { orderSeaFreight }
});

export const createOrderSeaFreightSuccess = ({ orderSeaFreight }) => ({
  type: CREATE_ORDER_SEA_FREIGHT_SUCCESS,
  payload: { orderSeaFreight }
});

export const createOrderSeaFreightFailed = ({ error }) => ({
  type: CREATE_ORDER_SEA_FREIGHT_FAILED,
  payload: { error }
});

export const fetchOrderSeaFreight = ({ orderSeaFreightId }) => ({
  type: FETCH_ORDER_SEA_FREIGHT,
  payload: { orderSeaFreightId }
});

export const fetchOrderSeaFreightSuccess = ({ orderSeaFreight }) => ({
  type: FETCH_ORDER_SEA_FREIGHT_SUCCESS,
  payload: { orderSeaFreight }
});

export const fetchOrderSeaFreightFailed = ({ error }) => ({
  type: FETCH_ORDER_SEA_FREIGHT_FAILED,
  payload: { error }
});

export const updateOrderSeaFreight = ({ orderSeaFreight }) => ({
  type: UPDATE_ORDER_SEA_FREIGHT,
  payload: { orderSeaFreight }
});

export const updateOrderSeaFreightSuccess = ({ orderSeaFreight }) => ({
  type: UPDATE_ORDER_SEA_FREIGHT_SUCCESS,
  payload: { orderSeaFreight }
});

export const updateOrderSeaFreightFailed = ({ error }) => ({
  type: UPDATE_ORDER_SEA_FREIGHT_FAILED,
  payload: { error }
});

export const deleteOrderSeaFreight = ({ orderSeaFreight }) => ({
  type: DELETE_ORDER_SEA_FREIGHT,
  payload: { orderSeaFreight }
});

export const deleteOrderSeaFreightSuccess = ({ orderSeaFreight }) => ({
  type: DELETE_ORDER_SEA_FREIGHT_SUCCESS,
  payload: { orderSeaFreight }
});

export const deleteOrderSeaFreightFailed = ({ error }) => ({
  type: DELETE_ORDER_SEA_FREIGHT_FAILED,
  payload: { error }
});

export const splitOrderSeaFreight = ({ order, lineItems }) => ({
  type: SPLIT_ORDER_SEA_FREIGHT,
  payload: { order, lineItems }
});

export const mergeOrderSeaFreights = ({ order, orderToBeMerged }) => ({
  type: MERGE_ORDER_SEA_FREIGHTS,
  payload: { order, orderToBeMerged }
});

export const assignOrderSeaFreightReceiptName = ({ receiptName }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_NAME,
  payload: { receiptName }
});

export const assignOrderSeaFreightReceiptMobile = ({ receiptMobile }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_MOBILE,
  payload: { receiptMobile }
});

export const assignOrderSeaFreightReceiptEmail = ({ receiptEmail }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_EMAIL,
  payload: { receiptEmail }
});

export const assignOrderSeaFreightReceiptCountry = ({ receiptCountry }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_COUNTRY,
  payload: { receiptCountry }
});

export const assignOrderSeaFreightReceiptCity = ({ receiptCity }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_CITY,
  payload: { receiptCity }
});

export const assignOrderSeaFreightReceiptState = ({ receiptState }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_STATE,
  payload: { receiptState }
});

export const assignOrderSeaFreightReceiptAddress = ({ receiptAddress }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_ADDRESS,
  payload: { receiptAddress }
});

export const assignOrderSeaFreightReceiptPostcode = ({ receiptPostcode }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_POSTCODE,
  payload: { receiptPostcode }
});

export const assignOrderSeaFreightNote = ({ note }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_NOTE,
  payload: { note }
});

export const assignOrderSeaFreightTitle = ({ title }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_TITLE,
  payload: { title }
});

export const assignOrderSeaFreightLength = ({ length }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_LENGTH,
  payload: { length }
});

export const assignOrderSeaFreightWidth = ({ width }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_WIDTH,
  payload: { width }
});

export const assignOrderSeaFreightHeight = ({ height }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_HEIGHT,
  payload: { height }
});

export const assignOrderSeaFreightGrossWeight = ({ grossWeight }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_GROSS_WEIGHT,
  payload: { grossWeight }
});

export const assignOrderSeaFreightStatus = ({ status }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_STATUS,
  payload: { status }
});

export const assignOrderSeaFreightTrackingNumber = ({ trackingNumber }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_TRACKING_NUMBER,
  payload: { trackingNumber }
});

export const assignOrderSeaFreightInternationalWaybillNumber = ({ internationalWaybillNumber }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_INTERNATIONAL_WAYBILL_NUMBER,
  payload: { internationalWaybillNumber }
});

export const assignOrderSeaFreightVolumeWeight = ({ volumeWeight }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_VOLUME_WEIGHT,
  payload: { volumeWeight }
});

export const assignOrderSeaFreightBillableWeight = ({ billableWeight }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_BILLABLE_WEIGHT,
  payload: { billableWeight }
});

export const assignOrderSeaFreightDiscountRate = ({ discountRate }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_DISCOUNT_RATE,
  payload: { discountRate }
});

export const assignOrderSeaFreightDeductionAmount = ({ deductionAmount }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_DEDUCTION_AMOUNT,
  payload: { deductionAmount }
});

export const assignOrderSeaFreightQuotation = ({ quotation }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_QUOTATION,
  payload: { quotation }
});

export const assignOrderSeaFreightEstimatedCost = ({ estimatedCost }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_ESTIMATED_COST,
  payload: { estimatedCost }
});

export const assignOrderSeaFreightOrderFee = ({ orderFee }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_ORDER_FEE,
  payload: { orderFee }
});

export const assignOrderSeaFreightExportDate = ({ exportDate }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_EXPORT_DATE,
  payload: { exportDate }
});

export const assignOrderSeaFreightDiscount = ({ discount }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_DISCOUNT,
  payload: { discount }
});

export const assignOrderSeaFreightAdditionalFee = ({ additionalFee }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_ADDITIONAL_FEE,
  payload: { additionalFee }
});

export const assignOrderSeaFreightDeliveryOrPickUp = ({ deliveryOrPickUp }) => ({
  type: ASSIGN_ORDER_SEA_FREIGHT_DELIVERY_OR_PICK_UP,
  payload: { deliveryOrPickUp }
});

export const transferOrderSeaFreight = ({ orderSeaFreight, seaFreightContainer }) => ({
  type: TRANSFER_ORDER_SEA_FREIGHT,
  payload: { orderSeaFreight, seaFreightContainer }
});

export const transferOrderSeaFreightSuccess = ({ orderSeaFreight }) => ({
  type: TRANSFER_ORDER_SEA_FREIGHT_SUCCESS,
  payload: { orderSeaFreight }
});

export const transferOrderSeaFreightFailed = ({ error }) => ({
  type: TRANSFER_ORDER_SEA_FREIGHT_FAILED,
  payload: { error }
});

export const downloadOrderSeaFreight = ({ orderSeaFreight }) => ({
  type: DOWNLOAD_ORDER_SEA_FREIGHT,
  payload: { orderSeaFreight }
});