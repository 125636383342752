import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_LINE_ITEMS } from "redux/model/lineItems/lineItems/constants";
import { apiListLineItems } from "api/lineItem/apiLineItem";
import { listLineItemsFailed, listLineItemsSuccess } from "redux/model/lineItems/lineItems/lineItemsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* lineItemsSaga() {
  yield all([
    fork(watchListLineItems),
  ]);
}

// List LineItems
export function* watchListLineItems() {
  yield takeEvery(LIST_LINE_ITEMS, sagaListLineItems);
}

export function* sagaListLineItems() {
  try {
    const response = yield call(apiListLineItems);

    const { lineItems } = response.data;

    yield put(listLineItemsSuccess({ lineItems }));

  } catch (e) {
    yield put(listLineItemsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default lineItemsSaga;
