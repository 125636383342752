import {
  ASSIGN_ORDER_SEA_FREIGHT_ADDITIONAL_FEE,
  ASSIGN_ORDER_SEA_FREIGHT_BILLABLE_WEIGHT,
  ASSIGN_ORDER_SEA_FREIGHT_DEDUCTION_AMOUNT,
  ASSIGN_ORDER_SEA_FREIGHT_DELIVERY_OR_PICK_UP,
  ASSIGN_ORDER_SEA_FREIGHT_DISCOUNT,
  ASSIGN_ORDER_SEA_FREIGHT_DISCOUNT_RATE,
  ASSIGN_ORDER_SEA_FREIGHT_ESTIMATED_COST,
  ASSIGN_ORDER_SEA_FREIGHT_EXPORT_DATE,
  ASSIGN_ORDER_SEA_FREIGHT_GROSS_WEIGHT,
  ASSIGN_ORDER_SEA_FREIGHT_HEIGHT,
  ASSIGN_ORDER_SEA_FREIGHT_INTERNATIONAL_WAYBILL_NUMBER,
  ASSIGN_ORDER_SEA_FREIGHT_LENGTH,
  ASSIGN_ORDER_SEA_FREIGHT_NOTE,
  ASSIGN_ORDER_SEA_FREIGHT_ORDER_FEE,
  ASSIGN_ORDER_SEA_FREIGHT_QUOTATION,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_ADDRESS,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_CITY,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_COUNTRY,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_EMAIL,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_MOBILE,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_NAME,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_POSTCODE,
  ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_STATE,
  ASSIGN_ORDER_SEA_FREIGHT_STATUS,
  ASSIGN_ORDER_SEA_FREIGHT_TITLE,
  ASSIGN_ORDER_SEA_FREIGHT_TRACKING_NUMBER,
  ASSIGN_ORDER_SEA_FREIGHT_VOLUME_WEIGHT,
  ASSIGN_ORDER_SEA_FREIGHT_WIDTH,
  FETCH_ORDER_SEA_FREIGHT,
  FETCH_ORDER_SEA_FREIGHT_FAILED,
  FETCH_ORDER_SEA_FREIGHT_SUCCESS,
  INIT_ORDER_SEA_FREIGHT
} from "redux/model/orderSeaFreights/orderSeaFreight/constants";

const INIT_STATE = {
  orderSeaFreight: {},
  loading: true,
  error: null,
};

const orderSeaFreightReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_ORDER_SEA_FREIGHT:
      return INIT_STATE;

    case FETCH_ORDER_SEA_FREIGHT:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_ORDER_SEA_FREIGHT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        orderSeaFreight: action.payload.orderSeaFreight,
      };

    case FETCH_ORDER_SEA_FREIGHT_FAILED:
      return {
        ...state,
        orderSeaFreight: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_NAME:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          receiptName: action.payload.receiptName,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_MOBILE:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          receiptMobile: action.payload.receiptMobile,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_EMAIL:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          receiptEmail: action.payload.receiptEmail,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_COUNTRY:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          receiptCountry: action.payload.receiptCountry,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_CITY:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          receiptCity: action.payload.receiptCity,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_STATE:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          receiptState: action.payload.receiptState,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_ADDRESS:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          receiptAddress: action.payload.receiptAddress,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_POSTCODE:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          receiptPostcode: action.payload.receiptPostcode,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_NOTE:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          note: action.payload.note,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_TITLE:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          title: action.payload.title,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_LENGTH:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          length: action.payload.length,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_WIDTH:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          width: action.payload.width,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_HEIGHT:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          height: action.payload.height,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_GROSS_WEIGHT:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          grossWeight: action.payload.grossWeight,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_STATUS:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          status: action.payload.status,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_TRACKING_NUMBER:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          trackingNumber: action.payload.trackingNumber,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_INTERNATIONAL_WAYBILL_NUMBER:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          internationalWaybillNumber: action.payload.internationalWaybillNumber,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_VOLUME_WEIGHT:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          volumeWeight: action.payload.volumeWeight,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_BILLABLE_WEIGHT:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          billableWeight: action.payload.billableWeight,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_DISCOUNT_RATE:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          discountRate: action.payload.discountRate,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_DEDUCTION_AMOUNT:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          deductionAmount: action.payload.deductionAmount,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_QUOTATION:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          quotation: action.payload.quotation,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_ESTIMATED_COST:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          estimatedCost: action.payload.estimatedCost,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_ORDER_FEE:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          orderFee: action.payload.orderFee,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_EXPORT_DATE:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          exportDate: action.payload.exportDate,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_DISCOUNT:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          discount: action.payload.discount,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_ADDITIONAL_FEE:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          additionalFee: action.payload.additionalFee,
        }
      };

    case ASSIGN_ORDER_SEA_FREIGHT_DELIVERY_OR_PICK_UP:
      return {
        ...state,
        orderSeaFreight: {
          ...state.orderSeaFreight,
          deliveryOrPickUp: action.payload.deliveryOrPickUp,
        }
      };

    default:
      return { ...state };
  }
};

export default orderSeaFreightReducers;
