import {
  ERROR_FORM_PACK,
  LOADING_FORM_PACK,
  RESET_FORM_PACK
} from "redux/ui/form/pack/constants";
import {
  CREATE_PACK,
  CREATE_PACK_FAILED,
  CREATE_PACK_SUCCESS, SPLIT_PACK,
  UPDATE_PACK, UPDATE_PACK_FAILED, UPDATE_PACK_SUCCESS
} from "redux/model/packs/pack/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formPackReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_PACK:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_PACK:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_PACK:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_PACK:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_PACK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_PACK_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_PACK:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_PACK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_PACK_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case SPLIT_PACK:
      return {
        ...state,
        loading: true,
        error: null
      };


    default:
      return { ...state };
  }
};

export default formPackReducers;
