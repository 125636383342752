import {
  CREATE_LINE_ITEM,
  CREATE_LINE_ITEM_FAILED,
  CREATE_LINE_ITEM_SUCCESS,
  DELETE_LINE_ITEM, DELETE_LINE_ITEM_FAILED, DELETE_LINE_ITEM_SUCCESS,
  FETCH_LINE_ITEM,
  FETCH_LINE_ITEM_FAILED,
  FETCH_LINE_ITEM_SUCCESS,
  INIT_LINE_ITEM, TRANSFER_LINE_ITEM,
  UPDATE_LINE_ITEM,
  UPDATE_LINE_ITEM_FAILED,
  UPDATE_LINE_ITEM_SUCCESS
} from "redux/model/lineItems/lineItem/constants";

export const initLineItem = () => ({
  type: INIT_LINE_ITEM,
  payload: {}
});

export const createLineItem = ({ lineItem }) => ({
  type: CREATE_LINE_ITEM,
  payload: { lineItem }
});

export const createLineItemSuccess = ({ lineItem }) => ({
  type: CREATE_LINE_ITEM_SUCCESS,
  payload: { lineItem }
});

export const createLineItemFailed = ({ error }) => ({
  type: CREATE_LINE_ITEM_FAILED,
  payload: { error }
});

export const fetchLineItem = ({ lineItemId }) => ({
  type: FETCH_LINE_ITEM,
  payload: { lineItemId }
});

export const fetchLineItemSuccess = ({ lineItem }) => ({
  type: FETCH_LINE_ITEM_SUCCESS,
  payload: { lineItem }
});

export const fetchLineItemFailed = ({ error }) => ({
  type: FETCH_LINE_ITEM_FAILED,
  payload: { error }
});

export const updateLineItem = ({ lineItem }) => ({
  type: UPDATE_LINE_ITEM,
  payload: { lineItem }
});

export const updateLineItemSuccess = ({ lineItem }) => ({
  type: UPDATE_LINE_ITEM_SUCCESS,
  payload: { lineItem }
});

export const updateLineItemFailed = ({ error }) => ({
  type: UPDATE_LINE_ITEM_FAILED,
  payload: { error }
});

export const deleteLineItem = ({ lineItem }) => ({
  type: DELETE_LINE_ITEM,
  payload: { lineItem }
});

export const deleteLineItemSuccess = ({ lineItem }) => ({
  type: DELETE_LINE_ITEM_SUCCESS,
  payload: { lineItem }
});

export const deleteLineItemFailed = ({ error }) => ({
  type: DELETE_LINE_ITEM_FAILED,
  payload: { error }
});

export const transferLineItem = ({ lineItem, order }) => ({
  type: TRANSFER_LINE_ITEM,
  payload: { lineItem, order }
});
