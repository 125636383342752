import {
  INIT_SEA_FREIGHT_CONTAINER_DECLARATIONS,
  LIST_SEA_FREIGHT_CONTAINER_DECLARATIONS,
  LIST_SEA_FREIGHT_CONTAINER_DECLARATIONS_FAILED,
  LIST_SEA_FREIGHT_CONTAINER_DECLARATIONS_SUCCESS
} from "redux/model/seaFreightContainerDeclarations/seaFreightContainerDeclarations/constants";

export const initSeaFreightContainerDeclarations = () => ({
  type: INIT_SEA_FREIGHT_CONTAINER_DECLARATIONS,
  payload: {}
});

export const listSeaFreightContainerDeclarations = () => ({
  type: LIST_SEA_FREIGHT_CONTAINER_DECLARATIONS,
  payload: {}
});

export const listSeaFreightContainerDeclarationsSuccess = ({ seaFreightContainerDeclarations }) => ({
  type: LIST_SEA_FREIGHT_CONTAINER_DECLARATIONS_SUCCESS,
  payload: { seaFreightContainerDeclarations }
});

export const listSeaFreightContainerDeclarationsFailed = ({ error }) => ({
  type: LIST_SEA_FREIGHT_CONTAINER_DECLARATIONS_FAILED,
  payload: { error }
});
