export const INIT_CLIENT = 'INIT_CLIENT';

export const FETCH_CLIENT = 'FETCH_CLIENT';
export const FETCH_CLIENT_SUCCESS = 'FETCH_CLIENT_SUCCESS';
export const FETCH_CLIENT_FAILED = 'FETCH_CLIENT_FAILED';

export const CREATE_CLIENT = 'CREATE_CLIENT';
export const CREATE_CLIENT_SUCCESS = 'CREATE_CLIENT_SUCCESS';
export const CREATE_CLIENT_FAILED = 'CREATE_CLIENT_FAILED';

export const UPDATE_CLIENT = 'UPDATE_CLIENT';
export const UPDATE_CLIENT_SUCCESS = 'UPDATE_CLIENT_SUCCESS';
export const UPDATE_CLIENT_FAILED = 'UPDATE_CLIENT_FAILED';

export const DELETE_CLIENT = 'DELETE_CLIENT';
export const DELETE_CLIENT_SUCCESS = 'DELETE_CLIENT_SUCCESS';
export const DELETE_CLIENT_FAILED = 'DELETE_CLIENT_FAILED';
