import {
  ASSIGN_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT,
  ASSIGN_SEA_FREIGHT_CONTAINER_STATUS,
  FETCH_SEA_FREIGHT_CONTAINER,
  FETCH_SEA_FREIGHT_CONTAINER_FAILED,
  FETCH_SEA_FREIGHT_CONTAINER_SUCCESS,
  INIT_SEA_FREIGHT_CONTAINER,
  NEW_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT,
  PULL_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT
} from "redux/model/seaFreightContainers/seaFreightContainer/constants";
import _ from 'lodash';

const INIT_STATE = {
  seaFreightContainer: {
    orderSeaFreightIds: [],
  },
  loading: true,
  error: null,
};

const seaFreightContainerReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_SEA_FREIGHT_CONTAINER:
      return INIT_STATE;

    case FETCH_SEA_FREIGHT_CONTAINER:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_SEA_FREIGHT_CONTAINER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        seaFreightContainer: action.payload.seaFreightContainer,
      };

    case FETCH_SEA_FREIGHT_CONTAINER_FAILED:
      return {
        ...state,
        seaFreightContainer: {},
        loading: false,
        error: action.payload.error,
      };

    case NEW_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT:
      return {
        ...state,
        seaFreightContainer: {
          ...state.seaFreightContainer,
          orderSeaFreightIds: _.uniq([
            ...state.seaFreightContainer.orderSeaFreightIds,
            action.payload.dummyId,
          ]),
        }
      };

    case ASSIGN_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT:
      return {
        ...state,
        seaFreightContainer: {
          ...state.seaFreightContainer,
          orderSeaFreightIds: _.chain(state.seaFreightContainer.orderSeaFreightIds)
            .map((orderSeaFreightId) => {
              return orderSeaFreightId === action.payload.previousOrderSeaFreight.id
                ? action.payload.newOrderSeaFreight.id
                : orderSeaFreightId;
            })
            .uniq()
            .value(),
        }
      };

    case PULL_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT:
      return {
        ...state,
        seaFreightContainer: {
          ...state.seaFreightContainer,
          orderSeaFreightIds: _.without(state.seaFreightContainer.orderSeaFreightIds, action.payload.orderSeaFreight.id),
        }
      };

    case ASSIGN_SEA_FREIGHT_CONTAINER_STATUS:
      return {
        ...state,
        seaFreightContainer: {
          ...state.seaFreightContainer,
          status: action.payload.status,
        },
      };

    default:
      return { ...state };
  }
};

export default seaFreightContainerReducers;
