import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_ORDER_DECLARATIONS } from "redux/model/orderDeclarations/orderDeclarations/constants";
import { apiListOrderDeclarations } from "api/orderDeclaration/apiOrderDeclaration";
import { listOrderDeclarationsFailed, listOrderDeclarationsSuccess } from "redux/model/orderDeclarations/orderDeclarations/orderDeclarationsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* orderDeclarationsSaga() {
  yield all([
    fork(watchListOrderDeclarations),
  ]);
}

// List OrderDeclarations
export function* watchListOrderDeclarations() {
  yield takeEvery(LIST_ORDER_DECLARATIONS, sagaListOrderDeclarations);
}

export function* sagaListOrderDeclarations() {
  try {
    const response = yield call(apiListOrderDeclarations);

    const { orderDeclarations } = response.data;

    yield put(listOrderDeclarationsSuccess({ orderDeclarations }));

  } catch (e) {
    yield put(listOrderDeclarationsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default orderDeclarationsSaga;
