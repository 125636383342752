import {
  INIT_LINE_ATTACHMENTS,
  LIST_LINE_ATTACHMENTS,
  LIST_LINE_ATTACHMENTS_FAILED,
  LIST_LINE_ATTACHMENTS_SUCCESS
} from "redux/model/lineAttachments/lineAttachments/constants";

export const initLineAttachments = () => ({
  type: INIT_LINE_ATTACHMENTS,
  payload: {}
});

export const listLineAttachments = () => ({
  type: LIST_LINE_ATTACHMENTS,
  payload: {}
});

export const listLineAttachmentsSuccess = ({ lineAttachments }) => ({
  type: LIST_LINE_ATTACHMENTS_SUCCESS,
  payload: { lineAttachments }
});

export const listLineAttachmentsFailed = ({ error }) => ({
  type: LIST_LINE_ATTACHMENTS_FAILED,
  payload: { error }
});
