import {
  CREATE_CLIENT,
  CREATE_CLIENT_FAILED,
  CREATE_CLIENT_SUCCESS,
  DELETE_CLIENT, DELETE_CLIENT_FAILED, DELETE_CLIENT_SUCCESS,
  FETCH_CLIENT,
  FETCH_CLIENT_FAILED,
  FETCH_CLIENT_SUCCESS,
  INIT_CLIENT,
  UPDATE_CLIENT,
  UPDATE_CLIENT_FAILED,
  UPDATE_CLIENT_SUCCESS
} from "redux/model/clients/client/constants";

export const initClient = () => ({
  type: INIT_CLIENT,
  payload: {}
});

export const createClient = ({ client }) => ({
  type: CREATE_CLIENT,
  payload: { client }
});

export const createClientSuccess = ({ client }) => ({
  type: CREATE_CLIENT_SUCCESS,
  payload: { client }
});

export const createClientFailed = ({ error }) => ({
  type: CREATE_CLIENT_FAILED,
  payload: { error }
});

export const fetchClient = ({ clientId }) => ({
  type: FETCH_CLIENT,
  payload: { clientId }
});

export const fetchClientSuccess = ({ client }) => ({
  type: FETCH_CLIENT_SUCCESS,
  payload: { client }
});

export const fetchClientFailed = ({ error }) => ({
  type: FETCH_CLIENT_FAILED,
  payload: { error }
});

export const updateClient = ({ client }) => ({
  type: UPDATE_CLIENT,
  payload: { client }
});

export const updateClientSuccess = ({ client }) => ({
  type: UPDATE_CLIENT_SUCCESS,
  payload: { client }
});

export const updateClientFailed = ({ error }) => ({
  type: UPDATE_CLIENT_FAILED,
  payload: { error }
});

export const deleteClient = ({ client }) => ({
  type: DELETE_CLIENT,
  payload: { client }
});

export const deleteClientSuccess = ({ client }) => ({
  type: DELETE_CLIENT_SUCCESS,
  payload: { client }
});

export const deleteClientFailed = ({ error }) => ({
  type: DELETE_CLIENT_FAILED,
  payload: { error }
});
