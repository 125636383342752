
export const CREATE_RECEIPT_ADDRESS_API = {
  url: `/receiptAddresses`,
  method: 'post'
};

export const INDEX_RECEIPT_ADDRESSES_API = {
  url: `/receiptAddresses`,
  method: 'get',
};

export const FETCH_RECEIPT_ADDRESS_API = {
  url: `/receiptAddresses/:id`,
  method: 'get',
};

export const UPDATE_RECEIPT_ADDRESS_API = {
  url: `/receiptAddresses/:id`,
  method: 'put'
};

export const DELETE_RECEIPT_ADDRESS_API = {
  url: `/receiptAddresses/:id`,
  method: 'delete'
};
