import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import { BASE_ORDER_DECLARATION_PATH, INDEX_ORDER_DECLARATIONS_PATH, NEW_ORDER_DECLARATION_PATH, SHOW_ORDER_DECLARATION_PATH } from "routes/orderDeclaration/constants";

const OrderDeclarations = React.lazy(() => import('pages/orderDeclarations/index/OrderDeclarations'));
const NewOrderDeclaration = React.lazy(() => import('pages/orderDeclarations/new/NewOrderDeclaration'));
const ShowOrderDeclaration = React.lazy(() => import('pages/orderDeclarations/show/ShowOrderDeclaration'));

export const orderDeclarationRoutes = {
  path: BASE_ORDER_DECLARATION_PATH,
  name: 'OrderDeclarations',
  icon: 'far fa-star',
  children: [
    {
      path: INDEX_ORDER_DECLARATIONS_PATH,
      exact: true,
      name: 'OrderDeclarations',
      component: OrderDeclarations,
      route: PrivateRoute,
    },
    {
      path: NEW_ORDER_DECLARATION_PATH,
      exact: true,
      name: 'Add New',
      component: NewOrderDeclaration,
      route: PrivateRoute,
    },
  ],
};

export const hiddenOrderDeclarationRoutes = {
  children: [
    {
      path: SHOW_ORDER_DECLARATION_PATH,
      exact: true,
      name: 'Show OrderDeclaration',
      component: ShowOrderDeclaration,
      route: PrivateRoute,
    }
  ]
}
