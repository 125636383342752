import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_RECEIPT_ADDRESS_API,
  INDEX_RECEIPT_ADDRESSES_API,
  FETCH_RECEIPT_ADDRESS_API,
  DELETE_RECEIPT_ADDRESS_API,
  UPDATE_RECEIPT_ADDRESS_API
} from "api/receiptAddress/constants";

export const apiListReceiptAddresses = () => {
  const { url, method } = INDEX_RECEIPT_ADDRESSES_API;

  return api({
    method,
    url,
  });
};

export const apiCreateReceiptAddress = ({ receiptAddress }) => {
  const { url, method } = CREATE_RECEIPT_ADDRESS_API;

  return api({
    method,
    url,
    data: receiptAddressParams({ receiptAddress }),
  });
};

export const apiFetchReceiptAddress = ({ receiptAddressId }) => {
  const { url, method } = FETCH_RECEIPT_ADDRESS_API;

  return api({
    method,
    url: generateRoute(url, receiptAddressId),
  });
};

export const apiUpdateReceiptAddress = ({ receiptAddress }) => {
  const { url, method } = UPDATE_RECEIPT_ADDRESS_API;

  return api({
    method,
    url: generateRoute(url, receiptAddress.id),
    data: receiptAddressParams({ receiptAddress }),
  });
};

export const apiDeleteReceiptAddress = ({ receiptAddress }) => {
  const { url, method } = DELETE_RECEIPT_ADDRESS_API;

  return api({
    method,
    url: generateRoute(url, receiptAddress.id),
  });
};

const receiptAddressParams = ({ receiptAddress }) => {
  const {} = receiptAddress;

  return {
  }
};
