import _ from 'lodash';
import {
  CREATE_RECEIPT_ADDRESS_SUCCESS,
  DELETE_RECEIPT_ADDRESS_SUCCESS,
  UPDATE_RECEIPT_ADDRESS_SUCCESS
} from "redux/model/receiptAddresses/receiptAddress/constants";
import {
  INIT_RECEIPT_ADDRESSES,
  LIST_RECEIPT_ADDRESSES,
  LIST_RECEIPT_ADDRESSES_FAILED,
  LIST_RECEIPT_ADDRESSES_SUCCESS
} from "redux/model/receiptAddresses/receiptAddresses/constants";

const INIT_STATE = {
  receiptAddresses: {},
  loading: true,
  error: null,
};

const receiptAddressesReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_RECEIPT_ADDRESSES:
      return INIT_STATE;

    case LIST_RECEIPT_ADDRESSES:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_RECEIPT_ADDRESSES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        receiptAddresses: _.mapKeys(action.payload.receiptAddresses, 'id'),
      };

    case LIST_RECEIPT_ADDRESSES_FAILED:
      return {
        ...state,
        receiptAddresses: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_RECEIPT_ADDRESS_SUCCESS:
      return {
        ...state,
        receiptAddresses: {
          [action.payload.receiptAddress.id]: action.payload.receiptAddress,
          ...state.receiptAddresses,
        },
      };

    case UPDATE_RECEIPT_ADDRESS_SUCCESS:
      return {
        ...state,
        receiptAddresses: {
          ...state.receiptAddresses,
          [action.payload.receiptAddress.id]: action.payload.receiptAddress,
        },
      };

    case DELETE_RECEIPT_ADDRESS_SUCCESS:
      return {
        ...state,
        receiptAddresses: _.omit(state.receiptAddresses, action.payload.receiptAddress.id)
      };

    default:
      return { ...state };
  }
};

export default receiptAddressesReducers;
