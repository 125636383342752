import React from 'react';
import { connect } from 'react-redux';
import { Helmet } from "react-helmet";

class ConfigTheme extends React.Component {
  render = () => {
    if (this.props.darkMode) {
      return (
        <Helmet>
          <link rel={ "stylesheet" } type={ "text/css" } href={ "/css/theme/DarkTheme.css" }/>
          <link rel={ "stylesheet" } type={ "text/css" } href={ "/css/GlobalCustomized.css" }/>
          <link rel={ "stylesheet" } type={ "text/css" } href={ "/css/darkTheme/DarkThemeCustomized.css" }/>
        </Helmet>
      );
    } else {
      return (
        <Helmet>
          <link rel={ "stylesheet" } type={ "text/css" } href={ "/css/GlobalCustomized.css" }/>
          <link rel={ "stylesheet" } type={ "text/css" } href={ "/css/lightTheme/LightThemeCustomized.css" }/>
        </Helmet>
      );
    }
  }
}

function mapStateToProps(state) {
  return {
    darkMode: state.layout.darkMode,
  };
}

export default connect(mapStateToProps, {})(ConfigTheme);
