export const INIT_WAREHOUSE = 'INIT_WAREHOUSE';

export const FETCH_WAREHOUSE = 'FETCH_WAREHOUSE';
export const FETCH_WAREHOUSE_SUCCESS = 'FETCH_WAREHOUSE_SUCCESS';
export const FETCH_WAREHOUSE_FAILED = 'FETCH_WAREHOUSE_FAILED';

export const CREATE_WAREHOUSE = 'CREATE_WAREHOUSE';
export const CREATE_WAREHOUSE_SUCCESS = 'CREATE_WAREHOUSE_SUCCESS';
export const CREATE_WAREHOUSE_FAILED = 'CREATE_WAREHOUSE_FAILED';

export const UPDATE_WAREHOUSE = 'UPDATE_WAREHOUSE';
export const UPDATE_WAREHOUSE_SUCCESS = 'UPDATE_WAREHOUSE_SUCCESS';
export const UPDATE_WAREHOUSE_FAILED = 'UPDATE_WAREHOUSE_FAILED';

export const DELETE_WAREHOUSE = 'DELETE_WAREHOUSE';
export const DELETE_WAREHOUSE_SUCCESS = 'DELETE_WAREHOUSE_SUCCESS';
export const DELETE_WAREHOUSE_FAILED = 'DELETE_WAREHOUSE_FAILED';
