import { all, call, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';

import React from "react";
import {
  CREATE_PACK_BATCH_UPLOAD,
  DOWNLOAD_PACK_BATCH_UPLOAD_CSV
} from "redux/model/packBatchUploads/packBatchUpload/constants";
import {
  createPackBatchUploadFailed,
  fetchPackBatchUploadSuccess
} from "redux/model/packBatchUploads/packBatchUpload/packBatchUploadActions";
import { apiCreatePackBatchUpload, apiDownloadPackBatchUploadCsv } from "api/packBatchUpload/apiPackBatchUpload";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";

const fileDownload = require('js-file-download');


function* packBatchUploadSaga() {
  yield all([
    fork(watchCreatePackBatchUpload),
    fork(watchDownloadPackBatchUploadCsv),
  ]);
}


// Create PackBatchUpload
export function* watchCreatePackBatchUpload() {
  yield takeLeading(CREATE_PACK_BATCH_UPLOAD, sagaCreatePackBatchUpload);
}

export function* sagaCreatePackBatchUpload({ payload }) {
  try {
    const response = yield call(apiCreatePackBatchUpload, payload);

    const { packBatchUpload } = response.data;
    yield put(fetchPackBatchUploadSuccess({ packBatchUpload }));

  } catch (e) {
    yield put(createPackBatchUploadFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


// Download Attachment
export function* watchDownloadPackBatchUploadCsv() {
  yield takeEvery(DOWNLOAD_PACK_BATCH_UPLOAD_CSV, sagaDownloadPackBatchUploadCsv);
}

export function* sagaDownloadPackBatchUploadCsv({ payload }) {
  try {
    const response = yield call(apiDownloadPackBatchUploadCsv, payload);
    yield call(
      fileDownload,
      response.data,
      `包裹上传.csv`
    );

  } catch (e) {
    console.log(generateErrorMessage(e));
    throwFrontError(e);
  }
}


export default packBatchUploadSaga;

