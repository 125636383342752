import {
  CREATE_PAYMENT,
  CREATE_PAYMENT_FAILED,
  CREATE_PAYMENT_SUCCESS,
  DELETE_PAYMENT, DELETE_PAYMENT_FAILED, DELETE_PAYMENT_SUCCESS,
  FETCH_PAYMENT,
  FETCH_PAYMENT_FAILED,
  FETCH_PAYMENT_SUCCESS,
  INIT_PAYMENT,
  UPDATE_PAYMENT,
  UPDATE_PAYMENT_FAILED,
  UPDATE_PAYMENT_SUCCESS
} from "redux/model/payments/payment/constants";

export const initPayment = () => ({
  type: INIT_PAYMENT,
  payload: {}
});

export const createPayment = ({ payment }) => ({
  type: CREATE_PAYMENT,
  payload: { payment }
});

export const createPaymentSuccess = ({ payment }) => ({
  type: CREATE_PAYMENT_SUCCESS,
  payload: { payment }
});

export const createPaymentFailed = ({ error }) => ({
  type: CREATE_PAYMENT_FAILED,
  payload: { error }
});

export const fetchPayment = ({ paymentId }) => ({
  type: FETCH_PAYMENT,
  payload: { paymentId }
});

export const fetchPaymentSuccess = ({ payment }) => ({
  type: FETCH_PAYMENT_SUCCESS,
  payload: { payment }
});

export const fetchPaymentFailed = ({ error }) => ({
  type: FETCH_PAYMENT_FAILED,
  payload: { error }
});

export const updatePayment = ({ payment }) => ({
  type: UPDATE_PAYMENT,
  payload: { payment }
});

export const updatePaymentSuccess = ({ payment }) => ({
  type: UPDATE_PAYMENT_SUCCESS,
  payload: { payment }
});

export const updatePaymentFailed = ({ error }) => ({
  type: UPDATE_PAYMENT_FAILED,
  payload: { error }
});

export const deletePayment = ({ payment }) => ({
  type: DELETE_PAYMENT,
  payload: { payment }
});

export const deletePaymentSuccess = ({ payment }) => ({
  type: DELETE_PAYMENT_SUCCESS,
  payload: { payment }
});

export const deletePaymentFailed = ({ error }) => ({
  type: DELETE_PAYMENT_FAILED,
  payload: { error }
});
