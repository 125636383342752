import {
  FETCH_PACK_DECLARATION,
  FETCH_PACK_DECLARATION_FAILED,
  FETCH_PACK_DECLARATION_SUCCESS,
  INIT_PACK_DECLARATION
} from "redux/model/packDeclarations/packDeclaration/constants";

const INIT_STATE = {
  packDeclaration: {},
  loading: true,
  error: null,
};

const packDeclarationReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PACK_DECLARATION:
      return INIT_STATE;

    case FETCH_PACK_DECLARATION:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PACK_DECLARATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        packDeclaration: action.payload.packDeclaration,
      };

    case FETCH_PACK_DECLARATION_FAILED:
      return {
        ...state,
        packDeclaration: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default packDeclarationReducers;
