import _ from 'lodash';
import {
  CREATE_PACK_SUCCESS,
  DELETE_PACK_SUCCESS,
  UPDATE_PACK_SUCCESS
} from "redux/model/packs/pack/constants";
import {
  INIT_PACKS,
  LIST_PACKS,
  LIST_PACKS_FAILED,
  LIST_PACKS_SUCCESS
} from "redux/model/packs/packs/constants";

const INIT_STATE = {
  packs: {},
  totalSize: 0,
  loading: true,
  error: null,
};

const packsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PACKS:
      return INIT_STATE;

    case LIST_PACKS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_PACKS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        packs: _.mapKeys(action.payload.packs, 'id'),
        totalSize: action.payload.totalSize,
      };

    case LIST_PACKS_FAILED:
      return {
        ...state,
        packs: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_PACK_SUCCESS:
      return {
        ...state,
        packs: {
          [action.payload.pack.id]: action.payload.pack,
          ...state.packs,
        },
      };

    case UPDATE_PACK_SUCCESS:
      return {
        ...state,
        packs: {
          ...state.packs,
          [action.payload.pack.id]: action.payload.pack,
        },
      };

    case DELETE_PACK_SUCCESS:
      return {
        ...state,
        packs: _.omit(state.packs, action.payload.pack.id)
      };

    default:
      return { ...state };
  }
};

export default packsReducers;
