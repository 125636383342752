import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_RECEIPT_ADDRESS,
  DELETE_RECEIPT_ADDRESS,
  FETCH_RECEIPT_ADDRESS,
  UPDATE_RECEIPT_ADDRESS
} from "redux/model/receiptAddresses/receiptAddress/constants";
import {
  apiCreateReceiptAddress,
  apiDeleteReceiptAddress,
  apiFetchReceiptAddress,
  apiUpdateReceiptAddress
} from "api/receiptAddress/apiReceiptAddress";
import {
  createReceiptAddressFailed,
  createReceiptAddressSuccess,
  deleteReceiptAddressFailed,
  deleteReceiptAddressSuccess,
  fetchReceiptAddressFailed,
  fetchReceiptAddressSuccess,
  initReceiptAddress,
  updateReceiptAddressFailed,
  updateReceiptAddressSuccess
} from "redux/model/receiptAddresses/receiptAddress/receiptAddressActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_RECEIPT_ADDRESSES_PATH } from "routes/receiptAddress/constants";


function* receiptAddressSaga() {
  yield all([
    fork(watchFetchReceiptAddress),
    fork(watchUpdateReceiptAddress),
    fork(watchCreateReceiptAddress),
    fork(watchDeleteReceiptAddress),
  ]);
}

// Fetch ReceiptAddress
export function* watchFetchReceiptAddress() {
  yield takeEvery(FETCH_RECEIPT_ADDRESS, sagaFetchReceiptAddress);
}

export function* sagaFetchReceiptAddress({ payload }) {
  try {
    const response = yield call(apiFetchReceiptAddress, payload);

    const { receiptAddress } = response.data;
    yield put(fetchReceiptAddressSuccess({ receiptAddress }));

  } catch (e) {
    yield put(fetchReceiptAddressFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create ReceiptAddress
export function* watchCreateReceiptAddress() {
  yield takeLeading(CREATE_RECEIPT_ADDRESS, sagaCreateReceiptAddress);
}

export function* sagaCreateReceiptAddress({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateReceiptAddress, payload);

    const { receiptAddress } = response.data;
    yield put(createReceiptAddressSuccess({ receiptAddress }));
    yield put(toastSaved({ name: `ReceiptAddress` }));


    history.push(generateRoute(INDEX_RECEIPT_ADDRESSES_PATH));
    yield put(initReceiptAddress());


  } catch (e) {
    yield put(createReceiptAddressFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update ReceiptAddress
export function* watchUpdateReceiptAddress() {
  yield takeLeading(UPDATE_RECEIPT_ADDRESS, sagaUpdateReceiptAddress);
}

export function* sagaUpdateReceiptAddress({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateReceiptAddress, payload);

    const { receiptAddress } = response.data;
    yield put(updateReceiptAddressSuccess({ receiptAddress }));
    yield put(toastSaved({ name: 'ReceiptAddress' }));
    history.push(generateRoute(INDEX_RECEIPT_ADDRESSES_PATH));
    yield put(initReceiptAddress());

  } catch (e) {
    yield put(updateReceiptAddressFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete ReceiptAddress
export function* watchDeleteReceiptAddress() {
  yield takeLeading(DELETE_RECEIPT_ADDRESS, sagaDeleteReceiptAddress);
}

export function* sagaDeleteReceiptAddress({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteReceiptAddress, payload);

    const { receiptAddress } = response.data;
    yield put(deleteReceiptAddressSuccess({ receiptAddress }));
    yield put(toastDeleted({ name: 'ReceiptAddress' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteReceiptAddressFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default receiptAddressSaga;
