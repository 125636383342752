import {
  INIT_SEA_FREIGHT_EXPORTERS,
  LIST_SEA_FREIGHT_EXPORTERS,
  LIST_SEA_FREIGHT_EXPORTERS_FAILED,
  LIST_SEA_FREIGHT_EXPORTERS_SUCCESS
} from "redux/model/seaFreightExporters/seaFreightExporters/constants";

export const initSeaFreightExporters = () => ({
  type: INIT_SEA_FREIGHT_EXPORTERS,
  payload: {}
});

export const listSeaFreightExporters = () => ({
  type: LIST_SEA_FREIGHT_EXPORTERS,
  payload: {}
});

export const listSeaFreightExportersSuccess = ({ seaFreightExporters }) => ({
  type: LIST_SEA_FREIGHT_EXPORTERS_SUCCESS,
  payload: { seaFreightExporters }
});

export const listSeaFreightExportersFailed = ({ error }) => ({
  type: LIST_SEA_FREIGHT_EXPORTERS_FAILED,
  payload: { error }
});
