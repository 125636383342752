
export const CREATE_PAYMENT_API = {
  url: `/payments`,
  method: 'post'
};

export const INDEX_PAYMENTS_API = {
  url: `/payments`,
  method: 'get',
};

export const FETCH_PAYMENT_API = {
  url: `/payments/:id`,
  method: 'get',
};

export const UPDATE_PAYMENT_API = {
  url: `/payments/:id`,
  method: 'put'
};

export const DELETE_PAYMENT_API = {
  url: `/payments/:id`,
  method: 'delete'
};
