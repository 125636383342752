import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_LINE_ITEM_DECLARATION,
  DELETE_LINE_ITEM_DECLARATION,
  FETCH_LINE_ITEM_DECLARATION,
  UPDATE_LINE_ITEM_DECLARATION
} from "redux/model/lineItemDeclarations/lineItemDeclaration/constants";
import {
  apiCreateLineItemDeclaration,
  apiDeleteLineItemDeclaration,
  apiFetchLineItemDeclaration,
  apiUpdateLineItemDeclaration
} from "api/lineItemDeclaration/apiLineItemDeclaration";
import {
  createLineItemDeclarationFailed,
  createLineItemDeclarationSuccess,
  deleteLineItemDeclarationFailed,
  deleteLineItemDeclarationSuccess,
  fetchLineItemDeclarationFailed,
  fetchLineItemDeclarationSuccess,
  initLineItemDeclaration,
  updateLineItemDeclarationFailed,
  updateLineItemDeclarationSuccess
} from "redux/model/lineItemDeclarations/lineItemDeclaration/lineItemDeclarationActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
// import { INDEX_LINE_ITEM_DECLARATIONS_PATH } from "routes/lineItemDeclaration/constants";


function* lineItemDeclarationSaga() {
  yield all([
    fork(watchFetchLineItemDeclaration),
    fork(watchUpdateLineItemDeclaration),
    fork(watchCreateLineItemDeclaration),
    fork(watchDeleteLineItemDeclaration),
  ]);
}

// Fetch LineItemDeclaration
export function* watchFetchLineItemDeclaration() {
  yield takeEvery(FETCH_LINE_ITEM_DECLARATION, sagaFetchLineItemDeclaration);
}

export function* sagaFetchLineItemDeclaration({ payload }) {
  try {
    const response = yield call(apiFetchLineItemDeclaration, payload);

    const { lineItemDeclaration } = response.data;
    yield put(fetchLineItemDeclarationSuccess({ lineItemDeclaration }));

  } catch (e) {
    yield put(fetchLineItemDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create LineItemDeclaration
export function* watchCreateLineItemDeclaration() {
  yield takeLeading(CREATE_LINE_ITEM_DECLARATION, sagaCreateLineItemDeclaration);
}

export function* sagaCreateLineItemDeclaration({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateLineItemDeclaration, payload);

    const { lineItemDeclaration } = response.data;
    yield put(createLineItemDeclarationSuccess({ lineItemDeclaration }));
    yield put(toastSaved({ name: `LineItemDeclaration` }));


    // history.push(generateRoute(INDEX_LINE_ITEM_DECLARATIONS_PATH));
    yield put(initLineItemDeclaration());


  } catch (e) {
    yield put(createLineItemDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update LineItemDeclaration
export function* watchUpdateLineItemDeclaration() {
  yield takeLeading(UPDATE_LINE_ITEM_DECLARATION, sagaUpdateLineItemDeclaration);
}

export function* sagaUpdateLineItemDeclaration({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateLineItemDeclaration, payload);

    const { lineItemDeclaration } = response.data;
    yield put(updateLineItemDeclarationSuccess({ lineItemDeclaration }));
    yield put(toastSaved({ name: 'LineItemDeclaration' }));
    // history.push(generateRoute(INDEX_LINE_ITEM_DECLARATIONS_PATH));
    yield put(initLineItemDeclaration());

  } catch (e) {
    yield put(updateLineItemDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete LineItemDeclaration
export function* watchDeleteLineItemDeclaration() {
  yield takeLeading(DELETE_LINE_ITEM_DECLARATION, sagaDeleteLineItemDeclaration);
}

export function* sagaDeleteLineItemDeclaration({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteLineItemDeclaration, payload);

    const { lineItemDeclaration } = response.data;
    yield put(deleteLineItemDeclarationSuccess({ lineItemDeclaration }));
    yield put(toastDeleted({ name: 'LineItemDeclaration' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteLineItemDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default lineItemDeclarationSaga;
