export const INIT_RECEIPT_ADDRESS = 'INIT_RECEIPT_ADDRESS';

export const FETCH_RECEIPT_ADDRESS = 'FETCH_RECEIPT_ADDRESS';
export const FETCH_RECEIPT_ADDRESS_SUCCESS = 'FETCH_RECEIPT_ADDRESS_SUCCESS';
export const FETCH_RECEIPT_ADDRESS_FAILED = 'FETCH_RECEIPT_ADDRESS_FAILED';

export const CREATE_RECEIPT_ADDRESS = 'CREATE_RECEIPT_ADDRESS';
export const CREATE_RECEIPT_ADDRESS_SUCCESS = 'CREATE_RECEIPT_ADDRESS_SUCCESS';
export const CREATE_RECEIPT_ADDRESS_FAILED = 'CREATE_RECEIPT_ADDRESS_FAILED';

export const UPDATE_RECEIPT_ADDRESS = 'UPDATE_RECEIPT_ADDRESS';
export const UPDATE_RECEIPT_ADDRESS_SUCCESS = 'UPDATE_RECEIPT_ADDRESS_SUCCESS';
export const UPDATE_RECEIPT_ADDRESS_FAILED = 'UPDATE_RECEIPT_ADDRESS_FAILED';

export const DELETE_RECEIPT_ADDRESS = 'DELETE_RECEIPT_ADDRESS';
export const DELETE_RECEIPT_ADDRESS_SUCCESS = 'DELETE_RECEIPT_ADDRESS_SUCCESS';
export const DELETE_RECEIPT_ADDRESS_FAILED = 'DELETE_RECEIPT_ADDRESS_FAILED';
