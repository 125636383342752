import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import {
  LIST_SEA_FREIGHT_CONTAINERS,
  LIST_SEA_FREIGHT_CONTAINERS_CAN_BE_LOADED
} from "redux/model/seaFreightContainers/seaFreightContainers/constants";
import {
  apiListSeaFreightContainers,
  apiListSeaFreightContainersCanBeLoaded
} from "api/seaFreightContainer/apiSeaFreightContainer";
import { listSeaFreightContainersFailed, listSeaFreightContainersSuccess } from "redux/model/seaFreightContainers/seaFreightContainers/seaFreightContainersActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";
import { faGameConsoleHandheld } from '@fortawesome/pro-regular-svg-icons';


function* seaFreightContainersSaga() {
  yield all([
    fork(watchListSeaFreightContainers),
    fork(watchListSeaFreightContainersCanBeLoaded),
  ]);
}

// List SeaFreightContainers
export function* watchListSeaFreightContainers() {
  yield takeEvery(LIST_SEA_FREIGHT_CONTAINERS, sagaListSeaFreightContainers);
}

export function* sagaListSeaFreightContainers() {
  try {
    const response = yield call(apiListSeaFreightContainers);

    const { seaFreightContainers } = response.data;

    yield put(listSeaFreightContainersSuccess({ seaFreightContainers }));

  } catch (e) {
    yield put(listSeaFreightContainersFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// List SeaFreightContainers Can be loaded
export function* watchListSeaFreightContainersCanBeLoaded() {
  yield takeEvery(LIST_SEA_FREIGHT_CONTAINERS_CAN_BE_LOADED, sagaListSeaFreightContainersCanBeLoaded);
}

export function* sagaListSeaFreightContainersCanBeLoaded() {
  try {
    const response = yield call(apiListSeaFreightContainersCanBeLoaded);

    const { seaFreightContainers } = response.data;

    yield put(listSeaFreightContainersSuccess({ seaFreightContainers }));

  } catch (e) {
    yield put(listSeaFreightContainersFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default seaFreightContainersSaga;
