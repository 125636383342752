import _ from 'lodash';
import {
  CREATE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_SUCCESS,
  UPDATE_TRANSACTION_SUCCESS
} from "redux/model/transactions/transaction/constants";
import {
  INIT_TRANSACTIONS, LIST_PENDING_TRANSFER_TRANSACTIONS,
  LIST_TRANSACTIONS,
  LIST_TRANSACTIONS_FAILED,
  LIST_TRANSACTIONS_SUCCESS
} from "redux/model/transactions/transactions/constants";

const INIT_STATE = {
  transactions: {},
  loading: true,
  error: null,
};

const transactionsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_TRANSACTIONS:
      return INIT_STATE;

    case LIST_TRANSACTIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        transactions: _.mapKeys(action.payload.transactions, 'id'),
      };

    case LIST_TRANSACTIONS_FAILED:
      return {
        ...state,
        transactions: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: {
          [action.payload.transaction.id]: action.payload.transaction,
          ...state.transactions,
        },
      };

    case UPDATE_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: {
          ...state.transactions,
          [action.payload.transaction.id]: action.payload.transaction,
        },
      };

    case DELETE_TRANSACTION_SUCCESS:
      return {
        ...state,
        transactions: _.omit(state.transactions, action.payload.transaction.id)
      };

    default:
      return { ...state };
  }
};

export default transactionsReducers;
