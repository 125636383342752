import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_CLIENTS } from "redux/model/clients/clients/constants";
import { apiListClients } from "api/client/apiClient";
import { listClientsFailed, listClientsSuccess } from "redux/model/clients/clients/clientsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* clientsSaga() {
  yield all([
    fork(watchListClients),
  ]);
}

// List Clients
export function* watchListClients() {
  yield takeEvery(LIST_CLIENTS, sagaListClients);
}

export function* sagaListClients() {
  try {
    const response = yield call(apiListClients);

    const { clients } = response.data;

    yield put(listClientsSuccess({ clients }));

  } catch (e) {
    yield put(listClientsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default clientsSaga;
