
export const CREATE_RECEIVED_NOTICE_API = {
  url: `/notification/log/received_notices`,
  method: 'post'
};

export const INDEX_RECEIVED_NOTICES_API = {
  url: `/notification/log/received_notices`,
  method: 'get',
};

export const FETCH_RECEIVED_NOTICE_API = {
  url: `/notification/log/received_notices/:id`,
  method: 'get',
};

export const UPDATE_RECEIVED_NOTICE_API = {
  url: `/notification/log/received_notices/:id`,
  method: 'put'
};

export const DELETE_RECEIVED_NOTICE_API = {
  url: `/notification/log/received_notices/:id`,
  method: 'delete'
};

export const CLEAR_ALL_RECEIVED_NOTICES_API = {
  url: `/notification/log/received_notices/clear_all`,
  method: 'post',
};