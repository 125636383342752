import {
  FETCH_PAYMENT,
  FETCH_PAYMENT_FAILED,
  FETCH_PAYMENT_SUCCESS,
  INIT_PAYMENT
} from "redux/model/payments/payment/constants";

const INIT_STATE = {
  payment: {},
  loading: true,
  error: null,
};

const paymentReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PAYMENT:
      return INIT_STATE;

    case FETCH_PAYMENT:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        payment: action.payload.payment,
      };

    case FETCH_PAYMENT_FAILED:
      return {
        ...state,
        payment: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default paymentReducers;
