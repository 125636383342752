import {
  ASSIGN_PACK_BATCH_UPLOAD_DATA_FILE,
  FETCH_PACK_BATCH_UPLOAD_SUCCESS,
  INIT_PACK_BATCH_UPLOAD
} from "redux/model/packBatchUploads/packBatchUpload/constants";

const INIT_STATE = {
  packBatchUpload: {},
  loading: false,
  error: null,
};

const packBatchUploadReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PACK_BATCH_UPLOAD:
      return INIT_STATE;

    case FETCH_PACK_BATCH_UPLOAD_SUCCESS:
      return {
        ...state,
        packBatchUpload: action.payload.packBatchUpload,
      }

    case ASSIGN_PACK_BATCH_UPLOAD_DATA_FILE:
      return {
        ...state,
        packBatchUpload: {
          ...state.packBatchUpload,
          dataFile: action.payload.dataFile,
        },
      };

    default:
      return { ...state };
  }
};

export default packBatchUploadReducers;
