import {
  FETCH_LINE_ITEM_DECLARATION,
  FETCH_LINE_ITEM_DECLARATION_FAILED,
  FETCH_LINE_ITEM_DECLARATION_SUCCESS,
  INIT_LINE_ITEM_DECLARATION
} from "redux/model/lineItemDeclarations/lineItemDeclaration/constants";

const INIT_STATE = {
  lineItemDeclaration: {},
  loading: true,
  error: null,
};

const lineItemDeclarationReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_LINE_ITEM_DECLARATION:
      return INIT_STATE;

    case FETCH_LINE_ITEM_DECLARATION:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_LINE_ITEM_DECLARATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        lineItemDeclaration: action.payload.lineItemDeclaration,
      };

    case FETCH_LINE_ITEM_DECLARATION_FAILED:
      return {
        ...state,
        lineItemDeclaration: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default lineItemDeclarationReducers;
