export const INIT_PAYMENT = 'INIT_PAYMENT';

export const FETCH_PAYMENT = 'FETCH_PAYMENT';
export const FETCH_PAYMENT_SUCCESS = 'FETCH_PAYMENT_SUCCESS';
export const FETCH_PAYMENT_FAILED = 'FETCH_PAYMENT_FAILED';

export const CREATE_PAYMENT = 'CREATE_PAYMENT';
export const CREATE_PAYMENT_SUCCESS = 'CREATE_PAYMENT_SUCCESS';
export const CREATE_PAYMENT_FAILED = 'CREATE_PAYMENT_FAILED';

export const UPDATE_PAYMENT = 'UPDATE_PAYMENT';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_FAILED = 'UPDATE_PAYMENT_FAILED';

export const DELETE_PAYMENT = 'DELETE_PAYMENT';
export const DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS';
export const DELETE_PAYMENT_FAILED = 'DELETE_PAYMENT_FAILED';
