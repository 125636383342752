import {
  INIT_WECHAT_NOTICES,
  LIST_WECHAT_NOTICES,
  LIST_WECHAT_NOTICES_FAILED,
  LIST_WECHAT_NOTICES_SUCCESS
} from "redux/model/wechatNotices/wechatNotices/constants";

export const initWechatNotices = () => ({
  type: INIT_WECHAT_NOTICES,
  payload: {}
});

export const listWechatNotices = () => ({
  type: LIST_WECHAT_NOTICES,
  payload: {}
});

export const listWechatNoticesSuccess = ({ wechatNotices }) => ({
  type: LIST_WECHAT_NOTICES_SUCCESS,
  payload: { wechatNotices }
});

export const listWechatNoticesFailed = ({ error }) => ({
  type: LIST_WECHAT_NOTICES_FAILED,
  payload: { error }
});
