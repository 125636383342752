import {
  ERROR_FORM_RECEIVED_NOTICE,
  LOADING_FORM_RECEIVED_NOTICE,
  RESET_FORM_RECEIVED_NOTICE
} from "redux/ui/form/receivedNotice/constants";
import {
  CREATE_RECEIVED_NOTICE,
  CREATE_RECEIVED_NOTICE_FAILED,
  CREATE_RECEIVED_NOTICE_SUCCESS,
  UPDATE_RECEIVED_NOTICE, UPDATE_RECEIVED_NOTICE_FAILED, UPDATE_RECEIVED_NOTICE_SUCCESS
} from "redux/model/receivedNotices/receivedNotice/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formReceivedNoticeReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_RECEIVED_NOTICE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_RECEIVED_NOTICE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_RECEIVED_NOTICE:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_RECEIVED_NOTICE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_RECEIVED_NOTICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_RECEIVED_NOTICE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_RECEIVED_NOTICE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_RECEIVED_NOTICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_RECEIVED_NOTICE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formReceivedNoticeReducers;
