import {
  ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_CONSIGNMENT_IDENTIFIER,
  ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_NUMERICAL_LINK,
  ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_VESSEL_NAME,
  ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_VOYAGE_NUMBER,
  CREATE_SEA_FREIGHT_CONTAINER_DECLARATION,
  CREATE_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED,
  CREATE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS,
  DELETE_SEA_FREIGHT_CONTAINER_DECLARATION,
  DELETE_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED,
  DELETE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS,
  FETCH_SEA_FREIGHT_CONTAINER_DECLARATION,
  FETCH_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED,
  FETCH_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS,
  INIT_SEA_FREIGHT_CONTAINER_DECLARATION,
  UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION,
  UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED,
  UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS
} from "redux/model/seaFreightContainerDeclarations/seaFreightContainerDeclaration/constants";

export const initSeaFreightContainerDeclaration = () => ({
  type: INIT_SEA_FREIGHT_CONTAINER_DECLARATION,
  payload: {}
});

export const createSeaFreightContainerDeclaration = ({ seaFreightContainerDeclaration }) => ({
  type: CREATE_SEA_FREIGHT_CONTAINER_DECLARATION,
  payload: { seaFreightContainerDeclaration }
});

export const createSeaFreightContainerDeclarationSuccess = ({ seaFreightContainerDeclaration }) => ({
  type: CREATE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS,
  payload: { seaFreightContainerDeclaration }
});

export const createSeaFreightContainerDeclarationFailed = ({ error }) => ({
  type: CREATE_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED,
  payload: { error }
});

export const fetchSeaFreightContainerDeclaration = ({ seaFreightContainerDeclarationId }) => ({
  type: FETCH_SEA_FREIGHT_CONTAINER_DECLARATION,
  payload: { seaFreightContainerDeclarationId }
});

export const fetchSeaFreightContainerDeclarationSuccess = ({ seaFreightContainerDeclaration }) => ({
  type: FETCH_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS,
  payload: { seaFreightContainerDeclaration }
});

export const fetchSeaFreightContainerDeclarationFailed = ({ error }) => ({
  type: FETCH_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED,
  payload: { error }
});

export const updateSeaFreightContainerDeclaration = ({ seaFreightContainerDeclaration }) => ({
  type: UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION,
  payload: { seaFreightContainerDeclaration }
});

export const updateSeaFreightContainerDeclarationSuccess = ({ seaFreightContainerDeclaration }) => ({
  type: UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS,
  payload: { seaFreightContainerDeclaration }
});

export const updateSeaFreightContainerDeclarationFailed = ({ error }) => ({
  type: UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED,
  payload: { error }
});

export const deleteSeaFreightContainerDeclaration = ({ seaFreightContainerDeclaration }) => ({
  type: DELETE_SEA_FREIGHT_CONTAINER_DECLARATION,
  payload: { seaFreightContainerDeclaration }
});

export const deleteSeaFreightContainerDeclarationSuccess = ({ seaFreightContainerDeclaration }) => ({
  type: DELETE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS,
  payload: { seaFreightContainerDeclaration }
});

export const deleteSeaFreightContainerDeclarationFailed = ({ error }) => ({
  type: DELETE_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED,
  payload: { error }
});

export const assignSeaFreightContainerDeclarationVesselName = ({ vesselName }) => ({
  type: ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_VESSEL_NAME,
  payload: { vesselName }
});

export const assignSeaFreightContainerDeclarationVoyageNumber = ({ voyageNumber }) => ({
  type: ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_VOYAGE_NUMBER,
  payload: { voyageNumber }
});

export const assignSeaFreightContainerDeclarationConsignmentIdentifier = ({ consignmentIdentifier }) => ({
  type: ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_CONSIGNMENT_IDENTIFIER,
  payload: { consignmentIdentifier }
});

export const assignSeaFreightContainerDeclarationNumericalLink = ({ numericalLink }) => ({
  type: ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_NUMERICAL_LINK,
  payload: { numericalLink }
});