import _ from 'lodash';
import {
  CREATE_LINE_ITEM_SUCCESS,
  DELETE_LINE_ITEM_SUCCESS,
  UPDATE_LINE_ITEM_SUCCESS
} from "redux/model/lineItems/lineItem/constants";
import {
  INIT_LINE_ITEMS,
  LIST_LINE_ITEMS,
  LIST_LINE_ITEMS_FAILED,
  LIST_LINE_ITEMS_SUCCESS
} from "redux/model/lineItems/lineItems/constants";

const INIT_STATE = {
  lineItems: {},
  loading: true,
  error: null,
};

const lineItemsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_LINE_ITEMS:
      return INIT_STATE;

    case LIST_LINE_ITEMS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_LINE_ITEMS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        lineItems: _.mapKeys(action.payload.lineItems, 'id'),
      };

    case LIST_LINE_ITEMS_FAILED:
      return {
        ...state,
        lineItems: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_LINE_ITEM_SUCCESS:
      return {
        ...state,
        lineItems: {
          [action.payload.lineItem.id]: action.payload.lineItem,
          ...state.lineItems,
        },
      };

    case UPDATE_LINE_ITEM_SUCCESS:
      return {
        ...state,
        lineItems: {
          ...state.lineItems,
          [action.payload.lineItem.id]: action.payload.lineItem,
        },
      };

    case DELETE_LINE_ITEM_SUCCESS:
      return {
        ...state,
        lineItems: _.omit(state.lineItems, action.payload.lineItem.id)
      };

    default:
      return { ...state };
  }
};

export default lineItemsReducers;
