export const INIT_COUPON_RULE = 'INIT_COUPON_RULE';

export const FETCH_COUPON_RULE = 'FETCH_COUPON_RULE';
export const FETCH_COUPON_RULE_SUCCESS = 'FETCH_COUPON_RULE_SUCCESS';
export const FETCH_COUPON_RULE_FAILED = 'FETCH_COUPON_RULE_FAILED';

export const CREATE_COUPON_RULE = 'CREATE_COUPON_RULE';
export const CREATE_COUPON_RULE_SUCCESS = 'CREATE_COUPON_RULE_SUCCESS';
export const CREATE_COUPON_RULE_FAILED = 'CREATE_COUPON_RULE_FAILED';

export const UPDATE_COUPON_RULE = 'UPDATE_COUPON_RULE';
export const UPDATE_COUPON_RULE_SUCCESS = 'UPDATE_COUPON_RULE_SUCCESS';
export const UPDATE_COUPON_RULE_FAILED = 'UPDATE_COUPON_RULE_FAILED';

export const DELETE_COUPON_RULE = 'DELETE_COUPON_RULE';
export const DELETE_COUPON_RULE_SUCCESS = 'DELETE_COUPON_RULE_SUCCESS';
export const DELETE_COUPON_RULE_FAILED = 'DELETE_COUPON_RULE_FAILED';

export const ASSIGN_COUPON_RULE_NAME = 'ASSIGN_COUPON_RULE_NAME';
export const ASSIGN_COUPON_RULE_CODE = 'ASSIGN_COUPON_RULE_CODE';
export const ASSIGN_COUPON_RULE_DATE_TYPE = 'ASSIGN_COUPON_RULE_DATE_TYPE';
export const ASSIGN_COUPON_RULE_DATE_FROM = 'ASSIGN_COUPON_RULE_DATE_FROM';
export const ASSIGN_COUPON_RULE_DATE_TO = 'ASSIGN_COUPON_RULE_DATE_TO';
export const ASSIGN_COUPON_RULE_AMOUNT_TYPE = 'ASSIGN_COUPON_RULE_AMOUNT_TYPE';
export const ASSIGN_COUPON_RULE_AMOUNT = 'ASSIGN_COUPON_RULE_AMOUNT';
export const ASSIGN_COUPON_RULE_LIMIT_PAYMENT = 'ASSIGN_COUPON_RULE_LIMIT_PAYMENT';
export const ASSIGN_COUPON_RULE_IS_MULTIPLE = 'ASSIGN_COUPON_RULE_IS_MULTIPLE';
export const ASSIGN_COUPON_RULE_IS_ACTIVE = 'ASSIGN_COUPON_RULE_IS_ACTIVE';
export const ASSIGN_COUPON_RULE_IS_PUBLIC = 'ASSIGN_COUPON_RULE_IS_PUBLIC';

export const NEW_COUPON_RULE = 'NEW_COUPON_RULE';

export const BATCH_REDEEM_COUPON_RULE = 'BATCH_REDEEM_COUPON_RULE';
export const BATCH_REDEEM_COUPON_RULE_SUCCESS = 'BATCH_REDEEM_COUPON_RULE_SUCCESS';
export const BATCH_REDEEM_COUPON_RULE_FAILED = 'BATCH_REDEEM_COUPON_RULE_FAILED';
