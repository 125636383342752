import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_RECEIVED_NOTICE_API,
  INDEX_RECEIVED_NOTICES_API,
  FETCH_RECEIVED_NOTICE_API,
  DELETE_RECEIVED_NOTICE_API,
  UPDATE_RECEIVED_NOTICE_API, CLEAR_ALL_RECEIVED_NOTICES_API
} from "api/receivedNotice/constants";

export const apiListReceivedNotices = () => {
  const { url, method } = INDEX_RECEIVED_NOTICES_API;

  return api({
    method,
    url,
  });
};

export const apiCreateReceivedNotice = ({ receivedNotice }) => {
  const { url, method } = CREATE_RECEIVED_NOTICE_API;

  return api({
    method,
    url,
    data: receivedNoticeParams({ receivedNotice }),
  });
};

export const apiFetchReceivedNotice = ({ receivedNoticeId }) => {
  const { url, method } = FETCH_RECEIVED_NOTICE_API;

  return api({
    method,
    url: generateRoute(url, receivedNoticeId),
  });
};

export const apiUpdateReceivedNotice = ({ receivedNotice }) => {
  const { url, method } = UPDATE_RECEIVED_NOTICE_API;

  return api({
    method,
    url: generateRoute(url, receivedNotice.id),
    data: receivedNoticeParams({ receivedNotice }),
  });
};

export const apiDeleteReceivedNotice = ({ receivedNotice }) => {
  const { url, method } = DELETE_RECEIVED_NOTICE_API;

  return api({
    method,
    url: generateRoute(url, receivedNotice.id),
  });
};

export const apiClearAllReceivedNotices = () => {
  const { url, method } = CLEAR_ALL_RECEIVED_NOTICES_API;

  return api({
    method,
    url: url,
  });
};

const receivedNoticeParams = ({ receivedNotice }) => {
  const {isRead} = receivedNotice;

  return {
    notice: {
      isRead
    }
  }
};
