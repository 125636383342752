import {
  ERROR_FORM_PACK_BATCH_UPLOAD,
  LOADING_FORM_PACK_BATCH_UPLOAD,
  RESET_FORM_PACK_BATCH_UPLOAD
} from "redux/ui/form/packBatchUpload/constants";
import {
  CREATE_PACK_BATCH_UPLOAD,
  CREATE_PACK_BATCH_UPLOAD_FAILED,
  FETCH_PACK_BATCH_UPLOAD_SUCCESS
} from "redux/model/packBatchUploads/packBatchUpload/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formPackBatchUploadReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOADING_FORM_PACK_BATCH_UPLOAD:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_PACK_BATCH_UPLOAD:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_PACK_BATCH_UPLOAD:
      return {
        ...state,
        loading: false,
        error: null,
      };

    case FETCH_PACK_BATCH_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case CREATE_PACK_BATCH_UPLOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_PACK_BATCH_UPLOAD_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};
export default formPackBatchUploadReducer;
