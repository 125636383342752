import {
  ERROR_FORM_ORDER_PACK,
  LOADING_FORM_ORDER_PACK,
  RESET_FORM_ORDER_PACK
} from "redux/ui/form/orderPack/constants";
import {
  CREATE_ORDER_PACK,
  CREATE_ORDER_PACK_FAILED,
  CREATE_ORDER_PACK_SUCCESS, MERGE_ORDER_PACKS, SPLIT_ORDER_PACK,
  UPDATE_ORDER_PACK, UPDATE_ORDER_PACK_FAILED, UPDATE_ORDER_PACK_SUCCESS
} from "redux/model/orderPacks/orderPack/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formOrderPackReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_ORDER_PACK:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_ORDER_PACK:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_ORDER_PACK:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_ORDER_PACK:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_ORDER_PACK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_ORDER_PACK_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_ORDER_PACK:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_ORDER_PACK_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_ORDER_PACK_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    case SPLIT_ORDER_PACK:
      return {
        ...state,
        loading: true,
        error: null
      };
    case MERGE_ORDER_PACKS:
      return {
        ...state,
        loading: true,
        error: null
      };


    default:
      return { ...state };
  }
};

export default formOrderPackReducers;
