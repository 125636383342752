import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_LINE_ATTACHMENTS } from "redux/model/lineAttachments/lineAttachments/constants";
import { apiListLineAttachments } from "api/lineAttachment/apiLineAttachment";
import { listLineAttachmentsFailed, listLineAttachmentsSuccess } from "redux/model/lineAttachments/lineAttachments/lineAttachmentsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* lineAttachmentsSaga() {
  yield all([
    fork(watchListLineAttachments),
  ]);
}

// List LineAttachments
export function* watchListLineAttachments() {
  yield takeEvery(LIST_LINE_ATTACHMENTS, sagaListLineAttachments);
}

export function* sagaListLineAttachments() {
  try {
    const response = yield call(apiListLineAttachments);

    const { lineAttachments } = response.data;

    yield put(listLineAttachmentsSuccess({ lineAttachments }));

  } catch (e) {
    yield put(listLineAttachmentsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default lineAttachmentsSaga;
