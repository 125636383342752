import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_LINE_ITEM,
  DELETE_LINE_ITEM,
  FETCH_LINE_ITEM,
  TRANSFER_LINE_ITEM,
  UPDATE_LINE_ITEM
} from "redux/model/lineItems/lineItem/constants";
import {
  apiCreateLineItem,
  apiDeleteLineItem,
  apiFetchLineItem,
  apiTransferLineItem,
  apiUpdateLineItem
} from "api/lineItem/apiLineItem";
import {
  createLineItemFailed,
  createLineItemSuccess,
  deleteLineItemFailed,
  deleteLineItemSuccess,
  fetchLineItemFailed,
  fetchLineItemSuccess,
  initLineItem,
  updateLineItemFailed,
  updateLineItemSuccess
} from "redux/model/lineItems/lineItem/lineItemActions";
import {
  toastDefault,
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeFirstLayerModal,
  closeSecondLayerModal,
  startFirstLayerModalListening,
  startSecondLayerModalListening, stopFirstLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_LINE_ITEMS_PATH } from "routes/lineItem/constants";
import { updateOrderPackFailed, updateOrderPackSuccess } from "redux/model/orderPacks/orderPack/orderPackActions";
import { listLineItemsSuccess } from "redux/model/lineItems/lineItems/lineItemsActions";
import { listPacksSuccess } from "redux/model/packs/packs/packsActions";
import { updateOrderSeaFreightSuccess } from "redux/model/orderSeaFreights/orderSeaFreight/orderSeaFreightActions";


function* lineItemSaga() {
  yield all([
    fork(watchFetchLineItem),
    fork(watchUpdateLineItem),
    fork(watchCreateLineItem),
    fork(watchDeleteLineItem),
    fork(watchTransferLineItem),
  ]);
}

// Fetch LineItem
export function* watchFetchLineItem() {
  yield takeEvery(FETCH_LINE_ITEM, sagaFetchLineItem);
}

export function* sagaFetchLineItem({ payload }) {
  try {
    const response = yield call(apiFetchLineItem, payload);

    const { lineItem } = response.data;
    yield put(fetchLineItemSuccess({ lineItem }));

  } catch (e) {
    yield put(fetchLineItemFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create LineItem
export function* watchCreateLineItem() {
  yield takeLeading(CREATE_LINE_ITEM, sagaCreateLineItem);
}

export function* sagaCreateLineItem({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateLineItem, payload);

    const { lineItem } = response.data;
    yield put(createLineItemSuccess({ lineItem }));
    yield put(toastSaved({ name: `LineItem` }));


    history.push(generateRoute(INDEX_LINE_ITEMS_PATH));
    yield put(initLineItem());


  } catch (e) {
    yield put(createLineItemFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update LineItem
export function* watchUpdateLineItem() {
  yield takeLeading(UPDATE_LINE_ITEM, sagaUpdateLineItem);
}

export function* sagaUpdateLineItem({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateLineItem, payload);

    const { lineItem } = response.data;
    yield put(updateLineItemSuccess({ lineItem }));
    yield put(toastSaved({ name: 'LineItem' }));
    history.push(generateRoute(INDEX_LINE_ITEMS_PATH));
    yield put(initLineItem());

  } catch (e) {
    yield put(updateLineItemFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete LineItem
export function* watchDeleteLineItem() {
  yield takeLeading(DELETE_LINE_ITEM, sagaDeleteLineItem);
}

export function* sagaDeleteLineItem({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteLineItem, payload);

    const { lineItem } = response.data;
    yield put(deleteLineItemSuccess({ lineItem }));
    yield put(toastDeleted({ name: 'LineItem' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteLineItemFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Transfer LineItem
export function* watchTransferLineItem() {
  yield takeLeading(TRANSFER_LINE_ITEM, sagaTransferLineItem);
}

export function* sagaTransferLineItem({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiTransferLineItem, payload);

    const { lineItems, packs } = response.data;
    const { orderPack, orderSeaFreight } = response.data;

    if (orderPack) {
      yield put(updateOrderPackSuccess({ orderPack }));
    }

    if (orderSeaFreight) {
      yield put(updateOrderSeaFreightSuccess({ orderSeaFreight }));
    }

    yield put(listLineItemsSuccess({ lineItems }));
    yield put(listPacksSuccess({ packs }));

    yield put(startFirstLayerModalListening());
    yield put(closeFirstLayerModal());
    yield put(toastDefault({ message: 'Line Item has been Transferred' }));
    yield put(stopFirstLayerModalListening());

  } catch (e) {
    yield put(updateOrderPackFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default lineItemSaga;
