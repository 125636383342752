import _ from 'lodash';
import {
  CREATE_SEA_FREIGHT_EXPORTER_SUCCESS,
  DELETE_SEA_FREIGHT_EXPORTER_SUCCESS,
  UPDATE_SEA_FREIGHT_EXPORTER_SUCCESS
} from "redux/model/seaFreightExporters/seaFreightExporter/constants";
import {
  INIT_SEA_FREIGHT_EXPORTERS,
  LIST_SEA_FREIGHT_EXPORTERS,
  LIST_SEA_FREIGHT_EXPORTERS_FAILED,
  LIST_SEA_FREIGHT_EXPORTERS_SUCCESS
} from "redux/model/seaFreightExporters/seaFreightExporters/constants";

const INIT_STATE = {
  seaFreightExporters: {},
  loading: true,
  error: null,
};

const seaFreightExportersReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_SEA_FREIGHT_EXPORTERS:
      return INIT_STATE;

    case LIST_SEA_FREIGHT_EXPORTERS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_SEA_FREIGHT_EXPORTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        seaFreightExporters: _.mapKeys(action.payload.seaFreightExporters, 'id'),
      };

    case LIST_SEA_FREIGHT_EXPORTERS_FAILED:
      return {
        ...state,
        seaFreightExporters: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_SEA_FREIGHT_EXPORTER_SUCCESS:
      return {
        ...state,
        seaFreightExporters: {
          [action.payload.seaFreightExporter.id]: action.payload.seaFreightExporter,
          ...state.seaFreightExporters,
        },
      };

    case UPDATE_SEA_FREIGHT_EXPORTER_SUCCESS:
      return {
        ...state,
        seaFreightExporters: {
          ...state.seaFreightExporters,
          [action.payload.seaFreightExporter.id]: action.payload.seaFreightExporter,
        },
      };

    case DELETE_SEA_FREIGHT_EXPORTER_SUCCESS:
      return {
        ...state,
        seaFreightExporters: _.omit(state.seaFreightExporters, action.payload.seaFreightExporter.id)
      };

    default:
      return { ...state };
  }
};

export default seaFreightExportersReducers;
