import _ from 'lodash';
import {
  CREATE_PACK_DECLARATION_SUCCESS,
  DELETE_PACK_DECLARATION_SUCCESS,
  UPDATE_PACK_DECLARATION_SUCCESS
} from "redux/model/packDeclarations/packDeclaration/constants";
import {
  INIT_PACK_DECLARATIONS,
  LIST_PACK_DECLARATIONS,
  LIST_PACK_DECLARATIONS_FAILED,
  LIST_PACK_DECLARATIONS_SUCCESS
} from "redux/model/packDeclarations/packDeclarations/constants";

const INIT_STATE = {
  packDeclarations: {},
  loading: true,
  error: null,
};

const packDeclarationsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_PACK_DECLARATIONS:
      return INIT_STATE;

    case LIST_PACK_DECLARATIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_PACK_DECLARATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        packDeclarations: _.mapKeys(action.payload.packDeclarations, 'id'),
      };

    case LIST_PACK_DECLARATIONS_FAILED:
      return {
        ...state,
        packDeclarations: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_PACK_DECLARATION_SUCCESS:
      return {
        ...state,
        packDeclarations: {
          [action.payload.packDeclaration.id]: action.payload.packDeclaration,
          ...state.packDeclarations,
        },
      };

    case UPDATE_PACK_DECLARATION_SUCCESS:
      return {
        ...state,
        packDeclarations: {
          ...state.packDeclarations,
          [action.payload.packDeclaration.id]: action.payload.packDeclaration,
        },
      };

    case DELETE_PACK_DECLARATION_SUCCESS:
      return {
        ...state,
        packDeclarations: _.omit(state.packDeclarations, action.payload.packDeclaration.id)
      };

    default:
      return { ...state };
  }
};

export default packDeclarationsReducers;
