import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import { INDEX_CLIENTS_PATH, SHOW_CLIENT_PATH } from "routes/client/constants";

const Clients = React.lazy(() => import('pages/clients/index/Clients'));
// const NewClient = React.lazy(() => import('pages/clients/new/NewClient'));
const ShowClient = React.lazy(() => import('pages/clients/show/ShowClient'));

export const clientRoutes = {
  path: INDEX_CLIENTS_PATH,
  exact: true,
  name: '用户列表',
  icon: 'far fa-users',
  component: Clients,
  route: PrivateRoute,
};

export const hiddenClientRoutes = {
  children: [
    {
      path: SHOW_CLIENT_PATH,
      exact: true,
      name: 'Show Client',
      component: ShowClient,
      route: PrivateRoute,
    }
  ]
}
