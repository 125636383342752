
export const CREATE_SEA_FREIGHT_CONSIGNEE_API = {
  url: `/sea_freight_consignees`,
  method: 'post'
};

export const INDEX_SEA_FREIGHT_CONSIGNEES_API = {
  url: `/sea_freight_consignees`,
  method: 'get',
};

export const FETCH_SEA_FREIGHT_CONSIGNEE_API = {
  url: `/sea_freight_consignees/:id`,
  method: 'get',
};

export const UPDATE_SEA_FREIGHT_CONSIGNEE_API = {
  url: `/sea_freight_consignees/:id`,
  method: 'put'
};

export const DELETE_SEA_FREIGHT_CONSIGNEE_API = {
  url: `/sea_freight_consignees/:id`,
  method: 'delete'
};
