import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import { BASE_RECEIVED_NOTICE_PATH, INDEX_RECEIVED_NOTICES_PATH, NEW_RECEIVED_NOTICE_PATH, SHOW_RECEIVED_NOTICE_PATH } from "routes/receivedNotice/constants";

const ReceivedNotices = React.lazy(() => import('pages/receivedNotices/index/ReceivedNotices'));
const NewReceivedNotice = React.lazy(() => import('pages/receivedNotices/new/NewReceivedNotice'));
const ShowReceivedNotice = React.lazy(() => import('pages/receivedNotices/show/ShowReceivedNotice'));

// export const receivedNoticeRoutes = {
//   path: BASE_RECEIVED_NOTICE_PATH,
//   name: 'ReceivedNotices',
//   icon: 'far fa-star',
//   children: [
//     {
//       path: INDEX_RECEIVED_NOTICES_PATH,
//       exact: true,
//       name: 'ReceivedNotices',
//       component: ReceivedNotices,
//       route: PrivateRoute,
//     },
//     {
//       path: NEW_RECEIVED_NOTICE_PATH,
//       exact: true,
//       name: 'Add New',
//       component: NewReceivedNotice,
//       route: PrivateRoute,
//     },
//   ],
// };

export const hiddenReceivedNoticeRoutes = {
  children: [
    {
      path: INDEX_RECEIVED_NOTICES_PATH,
      exact: true,
      name: 'Inbox',
      component: ReceivedNotices,
      route: PrivateRoute,
    }
  ]
}
