import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_CLIENT_API,
  INDEX_CLIENTS_API,
  FETCH_CLIENT_API,
  DELETE_CLIENT_API,
  UPDATE_CLIENT_API
} from "api/client/constants";

export const apiListClients = () => {
  const { url, method } = INDEX_CLIENTS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateClient = ({ client }) => {
  const { url, method } = CREATE_CLIENT_API;

  return api({
    method,
    url,
    data: clientParams(client),
  });
};

export const apiFetchClient = ({ clientId }) => {
  const { url, method } = FETCH_CLIENT_API;

  return api({
    method,
    url: generateRoute(url, clientId),
  });
};

export const apiUpdateClient = ({ client }) => {
  const { url, method } = UPDATE_CLIENT_API;

  return api({
    method,
    url: generateRoute(url, client.id),
    data: clientParams(client),
  });
};

export const apiDeleteClient = ({ client }) => {
  const { url, method } = DELETE_CLIENT_API;

  return api({
    method,
    url: generateRoute(url, client.id),
  });
};

const clientParams = (client) => {
  return {
  }
};
