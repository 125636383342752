export const INIT_RECEIVED_NOTICE = 'INIT_RECEIVED_NOTICE';

export const FETCH_RECEIVED_NOTICE = 'FETCH_RECEIVED_NOTICE';
export const FETCH_RECEIVED_NOTICE_SUCCESS = 'FETCH_RECEIVED_NOTICE_SUCCESS';
export const FETCH_RECEIVED_NOTICE_FAILED = 'FETCH_RECEIVED_NOTICE_FAILED';

export const CREATE_RECEIVED_NOTICE = 'CREATE_RECEIVED_NOTICE';
export const CREATE_RECEIVED_NOTICE_SUCCESS = 'CREATE_RECEIVED_NOTICE_SUCCESS';
export const CREATE_RECEIVED_NOTICE_FAILED = 'CREATE_RECEIVED_NOTICE_FAILED';

export const UPDATE_RECEIVED_NOTICE = 'UPDATE_RECEIVED_NOTICE';
export const UPDATE_RECEIVED_NOTICE_SUCCESS = 'UPDATE_RECEIVED_NOTICE_SUCCESS';
export const UPDATE_RECEIVED_NOTICE_FAILED = 'UPDATE_RECEIVED_NOTICE_FAILED';

export const DELETE_RECEIVED_NOTICE = 'DELETE_RECEIVED_NOTICE';
export const DELETE_RECEIVED_NOTICE_SUCCESS = 'DELETE_RECEIVED_NOTICE_SUCCESS';
export const DELETE_RECEIVED_NOTICE_FAILED = 'DELETE_RECEIVED_NOTICE_FAILED';

export const RECEIVE_NOTICE_SUCCESS = 'RECEIVE_NOTICE_SUCCESS';

export const UPDATE_RECEIVED_NOTICE_READ = 'UPDATE_RECEIVED_NOTICE_READ';