import {
  BATCH_REDEEM_COUPON_RULE,
  BATCH_REDEEM_COUPON_RULE_FAILED,
  BATCH_REDEEM_COUPON_RULE_SUCCESS
} from "redux/model/couponRules/couponRule/constants";
import {
  ERROR_FORM_BATCH_REDEEM_COUPON_RULE,
  LOADING_FORM_BATCH_REDEEM_COUPON_RULE,
  RESET_FORM_BATCH_REDEEM_COUPON_RULE
} from "redux/ui/form/batchRedeemCouponRule/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formBatchRedeemCouponRuleReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_BATCH_REDEEM_COUPON_RULE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_BATCH_REDEEM_COUPON_RULE:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_BATCH_REDEEM_COUPON_RULE:
      return {
        ...state,
        loading: false,
        error: null
      };

    case BATCH_REDEEM_COUPON_RULE:
      return {
        ...state,
        loading: true,
        error: null
      };
    case BATCH_REDEEM_COUPON_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case BATCH_REDEEM_COUPON_RULE_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formBatchRedeemCouponRuleReducers;
