import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_SEA_FREIGHT_CONTAINER,
  DELETE_SEA_FREIGHT_CONTAINER,
  DOWNLOAD_SEA_FREIGHT_CONTAINER,
  FETCH_SEA_FREIGHT_CONTAINER,
  UPDATE_SEA_FREIGHT_CONTAINER
} from "redux/model/seaFreightContainers/seaFreightContainer/constants";
import {
  apiCreateSeaFreightContainer,
  apiDeleteSeaFreightContainer,
  apiDownloadSeaFreightContainer,
  apiFetchSeaFreightContainer,
  apiUpdateSeaFreightContainer
} from "api/seaFreightContainer/apiSeaFreightContainer";
import {
  createSeaFreightContainerFailed,
  createSeaFreightContainerSuccess,
  deleteSeaFreightContainerFailed,
  deleteSeaFreightContainerSuccess,
  fetchSeaFreightContainerFailed,
  fetchSeaFreightContainerSuccess,
  initSeaFreightContainer,
  updateSeaFreightContainerFailed,
  updateSeaFreightContainerSuccess
} from "redux/model/seaFreightContainers/seaFreightContainer/seaFreightContainerActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_SEA_FREIGHT_CONTAINERS_PATH } from "routes/seaFreightContainer/constants";
import { listOrderSeaFreightsSuccess } from "redux/model/orderSeaFreights/orderSeaFreights/orderSeaFreightsActions";
import {
  fetchSeaFreightContainerDeclarationSuccess
} from "redux/model/seaFreightContainerDeclarations/seaFreightContainerDeclaration/seaFreightContainerDeclarationActions";
import fileDownload from "js-file-download";


function* seaFreightContainerSaga() {
  yield all([
    fork(watchFetchSeaFreightContainer),
    fork(watchUpdateSeaFreightContainer),
    fork(watchCreateSeaFreightContainer),
    fork(watchDeleteSeaFreightContainer),
    fork(watchDownloadSeaFreightContainer),
  ]);
}

// Fetch SeaFreightContainer
export function* watchFetchSeaFreightContainer() {
  yield takeEvery(FETCH_SEA_FREIGHT_CONTAINER, sagaFetchSeaFreightContainer);
}

export function* sagaFetchSeaFreightContainer({ payload }) {
  try {
    const response = yield call(apiFetchSeaFreightContainer, payload);

    const { seaFreightContainer, orderSeaFreights, seaFreightContainerDeclaration } = response.data;
    yield put(fetchSeaFreightContainerSuccess({ seaFreightContainer }));
    yield put(fetchSeaFreightContainerDeclarationSuccess({ seaFreightContainerDeclaration }));
    yield put(listOrderSeaFreightsSuccess({ orderSeaFreights }));

  } catch (e) {
    yield put(fetchSeaFreightContainerFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create SeaFreightContainer
export function* watchCreateSeaFreightContainer() {
  yield takeLeading(CREATE_SEA_FREIGHT_CONTAINER, sagaCreateSeaFreightContainer);
}

export function* sagaCreateSeaFreightContainer({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateSeaFreightContainer, payload);

    const { seaFreightContainer } = response.data;
    yield put(createSeaFreightContainerSuccess({ seaFreightContainer }));
    yield put(toastSaved({ name: `SeaFreightContainer` }));


    history.push(generateRoute(INDEX_SEA_FREIGHT_CONTAINERS_PATH));
    yield put(initSeaFreightContainer());


  } catch (e) {
    yield put(createSeaFreightContainerFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update SeaFreightContainer
export function* watchUpdateSeaFreightContainer() {
  yield takeLeading(UPDATE_SEA_FREIGHT_CONTAINER, sagaUpdateSeaFreightContainer);
}

export function* sagaUpdateSeaFreightContainer({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateSeaFreightContainer, payload);

    const { seaFreightContainer } = response.data;
    yield put(updateSeaFreightContainerSuccess({ seaFreightContainer }));
    yield put(toastSaved({ name: 'SeaFreightContainer' }));
    history.push(generateRoute(INDEX_SEA_FREIGHT_CONTAINERS_PATH));
    yield put(initSeaFreightContainer());

  } catch (e) {
    yield put(updateSeaFreightContainerFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete SeaFreightContainer
export function* watchDeleteSeaFreightContainer() {
  yield takeLeading(DELETE_SEA_FREIGHT_CONTAINER, sagaDeleteSeaFreightContainer);
}

export function* sagaDeleteSeaFreightContainer({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteSeaFreightContainer, payload);

    const { seaFreightContainer } = response.data;
    yield put(deleteSeaFreightContainerSuccess({ seaFreightContainer }));
    yield put(toastDeleted({ name: 'SeaFreightContainer' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteSeaFreightContainerFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


// Download Sea Freight Container
export function* watchDownloadSeaFreightContainer() {
  yield takeLeading(DOWNLOAD_SEA_FREIGHT_CONTAINER, sagaDownloadSeaFreightContainer);
}

export function* sagaDownloadSeaFreightContainer({ payload }) {
  try {
    const { seaFreightContainer } = payload;
    const consignmentIdentifier = seaFreightContainer ? seaFreightContainer.consignmentIdentifier : 'unknown';

    const response = yield call(apiDownloadSeaFreightContainer, payload);

    yield call(
      fileDownload,
      response.data,
      `集装箱-${ consignmentIdentifier }.xlsx`
    );

  } catch (e) {
    console.log(generateErrorMessage(e));
    throwFrontError(e);
  }
}


export default seaFreightContainerSaga;
