export const INIT_ORDER_SEA_FREIGHTS = 'INIT_ORDER_SEA_FREIGHTS';

export const LIST_ORDER_SEA_FREIGHTS = 'LIST_ORDER_SEA_FREIGHTS';
export const LIST_ORDER_SEA_FREIGHTS_SUCCESS = 'LIST_ORDER_SEA_FREIGHTS_SUCCESS';
export const LIST_ORDER_SEA_FREIGHTS_FAILED = 'LIST_ORDER_SEA_FREIGHTS_FAILED';

export const LIST_UNCONTAINED_ORDER_SEA_FREIGHTS = 'LIST_UNCONTAINED_ORDER_SEA_FREIGHTS';
export const LIST_ORDER_SEA_FREIGHTS_CAN_BE_MERGED = 'LIST_ORDER_SEA_FREIGHTS_CAN_BE_MERGED';

export const DOWNLOAD_PROCESSING_ORDER_SEA_FREIGHTS = 'DOWNLOAD_PROCESSING_ORDER_SEA_FREIGHTS';
export const DOWNLOAD_DISPATCHING_ORDER_SEA_FREIGHTS = 'DOWNLOAD_DISPATCHING_ORDER_SEA_FREIGHTS';