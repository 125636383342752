import {
  ASSIGN_COUPON_RULE_AMOUNT,
  ASSIGN_COUPON_RULE_AMOUNT_TYPE,
  ASSIGN_COUPON_RULE_CODE,
  ASSIGN_COUPON_RULE_DATE_FROM,
  ASSIGN_COUPON_RULE_DATE_TO,
  ASSIGN_COUPON_RULE_DATE_TYPE,
  ASSIGN_COUPON_RULE_IS_ACTIVE,
  ASSIGN_COUPON_RULE_IS_MULTIPLE,
  ASSIGN_COUPON_RULE_IS_PUBLIC,
  ASSIGN_COUPON_RULE_LIMIT_PAYMENT,
  ASSIGN_COUPON_RULE_NAME, BATCH_REDEEM_COUPON_RULE, BATCH_REDEEM_COUPON_RULE_FAILED, BATCH_REDEEM_COUPON_RULE_SUCCESS,
  CREATE_COUPON_RULE,
  CREATE_COUPON_RULE_FAILED,
  CREATE_COUPON_RULE_SUCCESS,
  DELETE_COUPON_RULE,
  DELETE_COUPON_RULE_FAILED,
  DELETE_COUPON_RULE_SUCCESS,
  FETCH_COUPON_RULE,
  FETCH_COUPON_RULE_FAILED,
  FETCH_COUPON_RULE_SUCCESS,
  INIT_COUPON_RULE, NEW_COUPON_RULE,
  UPDATE_COUPON_RULE,
  UPDATE_COUPON_RULE_FAILED,
  UPDATE_COUPON_RULE_SUCCESS
} from "redux/model/couponRules/couponRule/constants";

export const initCouponRule = () => ({
  type: INIT_COUPON_RULE,
  payload: {}
});

export const createCouponRule = ({ couponRule }) => ({
  type: CREATE_COUPON_RULE,
  payload: { couponRule }
});

export const createCouponRuleSuccess = ({ couponRule }) => ({
  type: CREATE_COUPON_RULE_SUCCESS,
  payload: { couponRule }
});

export const createCouponRuleFailed = ({ error }) => ({
  type: CREATE_COUPON_RULE_FAILED,
  payload: { error }
});

export const fetchCouponRule = ({ couponRuleId }) => ({
  type: FETCH_COUPON_RULE,
  payload: { couponRuleId }
});

export const fetchCouponRuleSuccess = ({ couponRule }) => ({
  type: FETCH_COUPON_RULE_SUCCESS,
  payload: { couponRule }
});

export const fetchCouponRuleFailed = ({ error }) => ({
  type: FETCH_COUPON_RULE_FAILED,
  payload: { error }
});

export const updateCouponRule = ({ couponRule }) => ({
  type: UPDATE_COUPON_RULE,
  payload: { couponRule }
});

export const updateCouponRuleSuccess = ({ couponRule }) => ({
  type: UPDATE_COUPON_RULE_SUCCESS,
  payload: { couponRule }
});

export const updateCouponRuleFailed = ({ error }) => ({
  type: UPDATE_COUPON_RULE_FAILED,
  payload: { error }
});

export const deleteCouponRule = ({ couponRule }) => ({
  type: DELETE_COUPON_RULE,
  payload: { couponRule }
});

export const deleteCouponRuleSuccess = ({ couponRule }) => ({
  type: DELETE_COUPON_RULE_SUCCESS,
  payload: { couponRule }
});

export const deleteCouponRuleFailed = ({ error }) => ({
  type: DELETE_COUPON_RULE_FAILED,
  payload: { error }
});

export const assignCouponRuleName = ({ name }) => ({
  type: ASSIGN_COUPON_RULE_NAME,
  payload: { name }
});

export const assignCouponRuleCode = ({ code }) => ({
  type: ASSIGN_COUPON_RULE_CODE,
  payload: { code }
});

export const assignCouponRuleDateType = ({ dateType }) => ({
  type: ASSIGN_COUPON_RULE_DATE_TYPE,
  payload: { dateType }
});

export const assignCouponRuleDateFrom = ({ dateFrom }) => ({
  type: ASSIGN_COUPON_RULE_DATE_FROM,
  payload: { dateFrom }
});

export const assignCouponRuleDateTo = ({ dateTo }) => ({
  type: ASSIGN_COUPON_RULE_DATE_TO,
  payload: { dateTo }
});

export const assignCouponRuleAmountType = ({ amountType }) => ({
  type: ASSIGN_COUPON_RULE_AMOUNT_TYPE,
  payload: { amountType }
});

export const assignCouponRuleAmount = ({ amount }) => ({
  type: ASSIGN_COUPON_RULE_AMOUNT,
  payload: { amount }
});

export const assignCouponRuleLimitPayment = ({ limitPayment }) => ({
  type: ASSIGN_COUPON_RULE_LIMIT_PAYMENT,
  payload: { limitPayment }
});

export const assignCouponRuleIsMultiple = ({ isMultiple }) => ({
  type: ASSIGN_COUPON_RULE_IS_MULTIPLE,
  payload: { isMultiple }
});

export const assignCouponRuleIsActive = ({ isActive }) => ({
  type: ASSIGN_COUPON_RULE_IS_ACTIVE,
  payload: { isActive }
});

export const assignCouponRuleIsPublic = ({ isPublic }) => ({
  type: ASSIGN_COUPON_RULE_IS_PUBLIC,
  payload: { isPublic }
});

export const newCouponRule = () => ({
  type: NEW_COUPON_RULE,
  payload: {}
});

export const batchRedeemCouponRule = ({ couponRule, clients }) => ({
  type: BATCH_REDEEM_COUPON_RULE,
  payload: { couponRule, clients }
});

export const batchRedeemCouponRuleSuccess = () => ({
  type: BATCH_REDEEM_COUPON_RULE_SUCCESS,
  payload: {}
});

export const batchRedeemCouponRuleFailed = ({ error }) => ({
  type: BATCH_REDEEM_COUPON_RULE_FAILED,
  payload: { error }
});