
export const CREATE_LINE_ITEM_API = {
  url: `/line_items`,
  method: 'post'
};

export const INDEX_LINE_ITEMS_API = {
  url: `/line_items`,
  method: 'get',
};

export const FETCH_LINE_ITEM_API = {
  url: `/line_items/:id`,
  method: 'get',
};

export const UPDATE_LINE_ITEM_API = {
  url: `/line_items/:id`,
  method: 'put'
};

export const DELETE_LINE_ITEM_API = {
  url: `/line_items/:id`,
  method: 'delete'
};

export const TRANSFER_LINE_ITEM_API = {
  url: `/line_items/:id/transfer`,
  method: 'post'
}
