import {
  CREATE_PACK_DECLARATION,
  CREATE_PACK_DECLARATION_FAILED,
  CREATE_PACK_DECLARATION_SUCCESS,
  DELETE_PACK_DECLARATION, DELETE_PACK_DECLARATION_FAILED, DELETE_PACK_DECLARATION_SUCCESS,
  FETCH_PACK_DECLARATION,
  FETCH_PACK_DECLARATION_FAILED,
  FETCH_PACK_DECLARATION_SUCCESS,
  INIT_PACK_DECLARATION,
  UPDATE_PACK_DECLARATION,
  UPDATE_PACK_DECLARATION_FAILED,
  UPDATE_PACK_DECLARATION_SUCCESS
} from "redux/model/packDeclarations/packDeclaration/constants";

export const initPackDeclaration = () => ({
  type: INIT_PACK_DECLARATION,
  payload: {}
});

export const createPackDeclaration = ({ packDeclaration }) => ({
  type: CREATE_PACK_DECLARATION,
  payload: { packDeclaration }
});

export const createPackDeclarationSuccess = ({ packDeclaration }) => ({
  type: CREATE_PACK_DECLARATION_SUCCESS,
  payload: { packDeclaration }
});

export const createPackDeclarationFailed = ({ error }) => ({
  type: CREATE_PACK_DECLARATION_FAILED,
  payload: { error }
});

export const fetchPackDeclaration = ({ packDeclarationId }) => ({
  type: FETCH_PACK_DECLARATION,
  payload: { packDeclarationId }
});

export const fetchPackDeclarationSuccess = ({ packDeclaration }) => ({
  type: FETCH_PACK_DECLARATION_SUCCESS,
  payload: { packDeclaration }
});

export const fetchPackDeclarationFailed = ({ error }) => ({
  type: FETCH_PACK_DECLARATION_FAILED,
  payload: { error }
});

export const updatePackDeclaration = ({ packDeclaration }) => ({
  type: UPDATE_PACK_DECLARATION,
  payload: { packDeclaration }
});

export const updatePackDeclarationSuccess = ({ packDeclaration }) => ({
  type: UPDATE_PACK_DECLARATION_SUCCESS,
  payload: { packDeclaration }
});

export const updatePackDeclarationFailed = ({ error }) => ({
  type: UPDATE_PACK_DECLARATION_FAILED,
  payload: { error }
});

export const deletePackDeclaration = ({ packDeclaration }) => ({
  type: DELETE_PACK_DECLARATION,
  payload: { packDeclaration }
});

export const deletePackDeclarationSuccess = ({ packDeclaration }) => ({
  type: DELETE_PACK_DECLARATION_SUCCESS,
  payload: { packDeclaration }
});

export const deletePackDeclarationFailed = ({ error }) => ({
  type: DELETE_PACK_DECLARATION_FAILED,
  payload: { error }
});
