import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import {
  OVERVIEW_DASHBOARD_PATH,
} from "routes/dashboard/constants";

// dashboard
const Overview = React.lazy(() => import('pages/dashboards/overview'));

// dashboards
export const dashboardRoutes = {
  // Over view
  name: '总览',
  icon: 'far fa-tachometer-slow',
  header: "全局",
  path: OVERVIEW_DASHBOARD_PATH,
  component: Overview,
  route: PrivateRoute,
};

export const hiddenDashboardRoutes = {
  children: []
}
