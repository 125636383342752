import {
  CREATE_RECEIPT_ADDRESS,
  CREATE_RECEIPT_ADDRESS_FAILED,
  CREATE_RECEIPT_ADDRESS_SUCCESS,
  DELETE_RECEIPT_ADDRESS, DELETE_RECEIPT_ADDRESS_FAILED, DELETE_RECEIPT_ADDRESS_SUCCESS,
  FETCH_RECEIPT_ADDRESS,
  FETCH_RECEIPT_ADDRESS_FAILED,
  FETCH_RECEIPT_ADDRESS_SUCCESS,
  INIT_RECEIPT_ADDRESS,
  UPDATE_RECEIPT_ADDRESS,
  UPDATE_RECEIPT_ADDRESS_FAILED,
  UPDATE_RECEIPT_ADDRESS_SUCCESS
} from "redux/model/receiptAddresses/receiptAddress/constants";

export const initReceiptAddress = () => ({
  type: INIT_RECEIPT_ADDRESS,
  payload: {}
});

export const createReceiptAddress = ({ receiptAddress }) => ({
  type: CREATE_RECEIPT_ADDRESS,
  payload: { receiptAddress }
});

export const createReceiptAddressSuccess = ({ receiptAddress }) => ({
  type: CREATE_RECEIPT_ADDRESS_SUCCESS,
  payload: { receiptAddress }
});

export const createReceiptAddressFailed = ({ error }) => ({
  type: CREATE_RECEIPT_ADDRESS_FAILED,
  payload: { error }
});

export const fetchReceiptAddress = ({ receiptAddressId }) => ({
  type: FETCH_RECEIPT_ADDRESS,
  payload: { receiptAddressId }
});

export const fetchReceiptAddressSuccess = ({ receiptAddress }) => ({
  type: FETCH_RECEIPT_ADDRESS_SUCCESS,
  payload: { receiptAddress }
});

export const fetchReceiptAddressFailed = ({ error }) => ({
  type: FETCH_RECEIPT_ADDRESS_FAILED,
  payload: { error }
});

export const updateReceiptAddress = ({ receiptAddress }) => ({
  type: UPDATE_RECEIPT_ADDRESS,
  payload: { receiptAddress }
});

export const updateReceiptAddressSuccess = ({ receiptAddress }) => ({
  type: UPDATE_RECEIPT_ADDRESS_SUCCESS,
  payload: { receiptAddress }
});

export const updateReceiptAddressFailed = ({ error }) => ({
  type: UPDATE_RECEIPT_ADDRESS_FAILED,
  payload: { error }
});

export const deleteReceiptAddress = ({ receiptAddress }) => ({
  type: DELETE_RECEIPT_ADDRESS,
  payload: { receiptAddress }
});

export const deleteReceiptAddressSuccess = ({ receiptAddress }) => ({
  type: DELETE_RECEIPT_ADDRESS_SUCCESS,
  payload: { receiptAddress }
});

export const deleteReceiptAddressFailed = ({ error }) => ({
  type: DELETE_RECEIPT_ADDRESS_FAILED,
  payload: { error }
});
