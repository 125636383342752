import {
  INIT_EXPORT_DIMENSION_BATCH_UPLOADS,
  LIST_EXPORT_DIMENSION_BATCH_UPLOADS,
  LIST_EXPORT_DIMENSION_BATCH_UPLOADS_FAILED,
  LIST_EXPORT_DIMENSION_BATCH_UPLOADS_SUCCESS
} from "redux/model/exportDimensionBatchUploads/exportDimensionBatchUploads/constants";

export const initExportDimensionBatchUploads = () => ({
  type: INIT_EXPORT_DIMENSION_BATCH_UPLOADS,
  payload: {}
});

export const listExportDimensionBatchUploads = () => ({
  type: LIST_EXPORT_DIMENSION_BATCH_UPLOADS,
  payload: {}
});

export const listExportDimensionBatchUploadsSuccess = ({ exportDimensionBatchUploads }) => ({
  type: LIST_EXPORT_DIMENSION_BATCH_UPLOADS_SUCCESS,
  payload: { exportDimensionBatchUploads }
});

export const listExportDimensionBatchUploadsFailed = ({ error }) => ({
  type: LIST_EXPORT_DIMENSION_BATCH_UPLOADS_FAILED,
  payload: { error }
});
