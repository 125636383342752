
export const CREATE_EXPORT_DIMENSION_BATCH_UPLOAD_API = {
  url: `/export_dimension_batch_uploads`,
  method: 'post'
};

export const INDEX_EXPORT_DIMENSION_BATCH_UPLOADS_API = {
  url: `/export_dimension_batch_uploads`,
  method: 'get',
};

export const FETCH_EXPORT_DIMENSION_BATCH_UPLOAD_API = {
  url: `/export_dimension_batch_uploads/:id`,
  method: 'get',
};

export const UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD_API = {
  url: `/export_dimension_batch_uploads/:id`,
  method: 'put'
};

export const DELETE_EXPORT_DIMENSION_BATCH_UPLOAD_API = {
  url: `/export_dimension_batch_uploads/:id`,
  method: 'delete'
};

export const DOWNLOAD_EXPORT_DIMENSION_BATCH_UPLOAD_CSV_API = {
  url: `/export_dimension_batch_uploads/download_csv`,
  method: 'get',
  responseType: 'arraybuffer',
};
