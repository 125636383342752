import {
  ASSIGN_ORDER_DECLARATION_BY,
  ASSIGN_ORDER_DECLARATION_CONSIGNEE_ADDRESS,
  ASSIGN_ORDER_DECLARATION_CONSIGNEE_EMAIL,
  ASSIGN_ORDER_DECLARATION_CONSIGNEE_ID,
  ASSIGN_ORDER_DECLARATION_CONSIGNEE_NAME,
  ASSIGN_ORDER_DECLARATION_CONSIGNEE_PHONE,
  ASSIGN_ORDER_DECLARATION_DATE_PACK_TIME,
  ASSIGN_ORDER_DECLARATION_DELIVERY_AND_PAYMENT_TERM,
  ASSIGN_ORDER_DECLARATION_EXPORTER_ADDRESS,
  ASSIGN_ORDER_DECLARATION_EXPORTER_EMAIL,
  ASSIGN_ORDER_DECLARATION_EXPORTER_ID,
  ASSIGN_ORDER_DECLARATION_EXPORTER_NAME,
  ASSIGN_ORDER_DECLARATION_EXPORTER_PHONE,
  ASSIGN_ORDER_DECLARATION_FROM_ATR,
  ASSIGN_ORDER_DECLARATION_NO_AND_DATE_OF_INVOICE,
  ASSIGN_ORDER_DECLARATION_NO_AND_DATE_OF_PACKING_LIST,
  ASSIGN_ORDER_DECLARATION_REMARKS,
  ASSIGN_ORDER_DECLARATION_TO,
  CREATE_ORDER_DECLARATION,
  CREATE_ORDER_DECLARATION_FAILED,
  CREATE_ORDER_DECLARATION_SUCCESS,
  DELETE_ORDER_DECLARATION,
  DELETE_ORDER_DECLARATION_FAILED,
  DELETE_ORDER_DECLARATION_SUCCESS,
  FETCH_ORDER_DECLARATION,
  FETCH_ORDER_DECLARATION_FAILED,
  FETCH_ORDER_DECLARATION_SUCCESS,
  INIT_ORDER_DECLARATION,
  UPDATE_ORDER_DECLARATION,
  UPDATE_ORDER_DECLARATION_FAILED,
  UPDATE_ORDER_DECLARATION_SUCCESS
} from "redux/model/orderDeclarations/orderDeclaration/constants";

export const initOrderDeclaration = () => ({
  type: INIT_ORDER_DECLARATION,
  payload: {}
});

export const createOrderDeclaration = ({ orderDeclaration }) => ({
  type: CREATE_ORDER_DECLARATION,
  payload: { orderDeclaration }
});

export const createOrderDeclarationSuccess = ({ orderDeclaration }) => ({
  type: CREATE_ORDER_DECLARATION_SUCCESS,
  payload: { orderDeclaration }
});

export const createOrderDeclarationFailed = ({ error }) => ({
  type: CREATE_ORDER_DECLARATION_FAILED,
  payload: { error }
});

export const fetchOrderDeclaration = ({ orderDeclarationId }) => ({
  type: FETCH_ORDER_DECLARATION,
  payload: { orderDeclarationId }
});

export const fetchOrderDeclarationSuccess = ({ orderDeclaration }) => ({
  type: FETCH_ORDER_DECLARATION_SUCCESS,
  payload: { orderDeclaration }
});

export const fetchOrderDeclarationFailed = ({ error }) => ({
  type: FETCH_ORDER_DECLARATION_FAILED,
  payload: { error }
});

export const updateOrderDeclaration = ({ orderDeclaration }) => ({
  type: UPDATE_ORDER_DECLARATION,
  payload: { orderDeclaration }
});

export const updateOrderDeclarationSuccess = ({ orderDeclaration }) => ({
  type: UPDATE_ORDER_DECLARATION_SUCCESS,
  payload: { orderDeclaration }
});

export const updateOrderDeclarationFailed = ({ error }) => ({
  type: UPDATE_ORDER_DECLARATION_FAILED,
  payload: { error }
});

export const deleteOrderDeclaration = ({ orderDeclaration }) => ({
  type: DELETE_ORDER_DECLARATION,
  payload: { orderDeclaration }
});

export const deleteOrderDeclarationSuccess = ({ orderDeclaration }) => ({
  type: DELETE_ORDER_DECLARATION_SUCCESS,
  payload: { orderDeclaration }
});

export const deleteOrderDeclarationFailed = ({ error }) => ({
  type: DELETE_ORDER_DECLARATION_FAILED,
  payload: { error }
});

export const assignOrderDeclarationExporterName = ({ exporterName }) => ({
  type: ASSIGN_ORDER_DECLARATION_EXPORTER_NAME,
  payload: { exporterName }
});

export const assignOrderDeclarationExporterAddress = ({ exporterAddress }) => ({
  type: ASSIGN_ORDER_DECLARATION_EXPORTER_ADDRESS,
  payload: { exporterAddress }
});

export const assignOrderDeclarationExporterPhone = ({ exporterPhone }) => ({
  type: ASSIGN_ORDER_DECLARATION_EXPORTER_PHONE,
  payload: { exporterPhone }
});

export const assignOrderDeclarationExporterEmail = ({ exporterEmail }) => ({
  type: ASSIGN_ORDER_DECLARATION_EXPORTER_EMAIL,
  payload: { exporterEmail }
});

export const assignOrderDeclarationConsigneeName = ({ consigneeName }) => ({
  type: ASSIGN_ORDER_DECLARATION_CONSIGNEE_NAME,
  payload: { consigneeName }
});

export const assignOrderDeclarationConsigneeAddress = ({ consigneeAddress }) => ({
  type: ASSIGN_ORDER_DECLARATION_CONSIGNEE_ADDRESS,
  payload: { consigneeAddress }
});

export const assignOrderDeclarationConsigneePhone = ({ consigneePhone }) => ({
  type: ASSIGN_ORDER_DECLARATION_CONSIGNEE_PHONE,
  payload: { consigneePhone }
});

export const assignOrderDeclarationConsigneeEmail = ({ consigneeEmail }) => ({
  type: ASSIGN_ORDER_DECLARATION_CONSIGNEE_EMAIL,
  payload: { consigneeEmail }
});

export const assignOrderDeclarationExporterId = ({ exporterId }) => ({
  type: ASSIGN_ORDER_DECLARATION_EXPORTER_ID,
  payload: { exporterId }
});

export const assignOrderDeclarationConsigneeId = ({ consigneeId }) => ({
  type: ASSIGN_ORDER_DECLARATION_CONSIGNEE_ID,
  payload: { consigneeId }
});

export const assignOrderDeclarationFromAtr = ({ fromAtr }) => ({
  type: ASSIGN_ORDER_DECLARATION_FROM_ATR,
  payload: { fromAtr }
});

export const assignOrderDeclarationDatePackTime = ({ datePackTime }) => ({
  type: ASSIGN_ORDER_DECLARATION_DATE_PACK_TIME,
  payload: { datePackTime }
});

export const assignOrderDeclarationRemarks = ({ remarks }) => ({
  type: ASSIGN_ORDER_DECLARATION_REMARKS,
  payload: { remarks }
});

export const assignOrderDeclarationTo = ({ to }) => ({
  type: ASSIGN_ORDER_DECLARATION_TO,
  payload: { to }
});

export const assignOrderDeclarationBy = ({ by }) => ({
  type: ASSIGN_ORDER_DECLARATION_BY,
  payload: { by }
});

export const assignOrderDeclarationDeliveryAndPaymentTerm = ({ deliveryAndPaymentTerm }) => ({
  type: ASSIGN_ORDER_DECLARATION_DELIVERY_AND_PAYMENT_TERM,
  payload: { deliveryAndPaymentTerm }
});

export const assignOrderDeclarationNoAndDateOfInvoice = ({ noAndDateOfInvoice }) => ({
  type: ASSIGN_ORDER_DECLARATION_NO_AND_DATE_OF_INVOICE,
  payload: { noAndDateOfInvoice }
});

export const assignOrderDeclarationNoAndDateOfPackingList = ({ noAndDateOfPackingList }) => ({
  type: ASSIGN_ORDER_DECLARATION_NO_AND_DATE_OF_PACKING_LIST,
  payload: { noAndDateOfPackingList }
});