import {
  INIT_PAYMENTS,
  LIST_PAYMENTS,
  LIST_PAYMENTS_FAILED,
  LIST_PAYMENTS_SUCCESS
} from "redux/model/payments/payments/constants";

export const initPayments = () => ({
  type: INIT_PAYMENTS,
  payload: {}
});

export const listPayments = () => ({
  type: LIST_PAYMENTS,
  payload: {}
});

export const listPaymentsSuccess = ({ payments }) => ({
  type: LIST_PAYMENTS_SUCCESS,
  payload: { payments }
});

export const listPaymentsFailed = ({ error }) => ({
  type: LIST_PAYMENTS_FAILED,
  payload: { error }
});
