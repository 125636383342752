import _ from 'lodash';
import {
  CREATE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS,
  DELETE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS,
  UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS
} from "redux/model/seaFreightContainerDeclarations/seaFreightContainerDeclaration/constants";
import {
  INIT_SEA_FREIGHT_CONTAINER_DECLARATIONS,
  LIST_SEA_FREIGHT_CONTAINER_DECLARATIONS,
  LIST_SEA_FREIGHT_CONTAINER_DECLARATIONS_FAILED,
  LIST_SEA_FREIGHT_CONTAINER_DECLARATIONS_SUCCESS
} from "redux/model/seaFreightContainerDeclarations/seaFreightContainerDeclarations/constants";

const INIT_STATE = {
  seaFreightContainerDeclarations: {},
  loading: true,
  error: null,
};

const seaFreightContainerDeclarationsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_SEA_FREIGHT_CONTAINER_DECLARATIONS:
      return INIT_STATE;

    case LIST_SEA_FREIGHT_CONTAINER_DECLARATIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_SEA_FREIGHT_CONTAINER_DECLARATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        seaFreightContainerDeclarations: _.mapKeys(action.payload.seaFreightContainerDeclarations, 'id'),
      };

    case LIST_SEA_FREIGHT_CONTAINER_DECLARATIONS_FAILED:
      return {
        ...state,
        seaFreightContainerDeclarations: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS:
      return {
        ...state,
        seaFreightContainerDeclarations: {
          [action.payload.seaFreightContainerDeclaration.id]: action.payload.seaFreightContainerDeclaration,
          ...state.seaFreightContainerDeclarations,
        },
      };

    case UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS:
      return {
        ...state,
        seaFreightContainerDeclarations: {
          ...state.seaFreightContainerDeclarations,
          [action.payload.seaFreightContainerDeclaration.id]: action.payload.seaFreightContainerDeclaration,
        },
      };

    case DELETE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS:
      return {
        ...state,
        seaFreightContainerDeclarations: _.omit(state.seaFreightContainerDeclarations, action.payload.seaFreightContainerDeclaration.id)
      };

    default:
      return { ...state };
  }
};

export default seaFreightContainerDeclarationsReducers;
