export const DOWNLOAD_PACK_BATCH_UPLOAD_CSV_API = {
  url: `/pack_batch_uploads/download_csv`,
  method: 'get',
  responseType: 'arraybuffer',
};

export const CREATE_PACK_BATCH_UPLOAD_API = {
  url: `/pack_batch_uploads`,
  method: 'post',
}
