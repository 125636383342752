import { api, extractClassNameFrom, extractIdFrom, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_LINE_ATTACHMENT_API,
  INDEX_LINE_ATTACHMENTS_API,
  FETCH_LINE_ATTACHMENT_API,
  DELETE_LINE_ATTACHMENT_API,
  UPDATE_LINE_ATTACHMENT_API, CREATE_PACK_ATTACHMENT_API, DOWNLOAD_LINE_ATTACHMENT_API, CREATE_ORDER_ATTACHMENT_API
} from "api/lineAttachment/constants";
import { extractRailsClassNameFrom } from "helpers/railsUtils";

export const apiListLineAttachments = () => {
  const { url, method } = INDEX_LINE_ATTACHMENTS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateLineAttachment = ({ lineAttachment }) => {
  const { url, method } = CREATE_LINE_ATTACHMENT_API;

  return api({
    method,
    url,
    data: lineAttachmentParams({ lineAttachment }),
  });
};

export const apiCreatePackAttachment = ({ lineAttachment }) => {
  const { url, method } = CREATE_PACK_ATTACHMENT_API;

  return api({
    method,
    url,
    data: lineAttachmentParams({ lineAttachment }),
  });
};

export const apiCreateOrderAttachment = ({ lineAttachment }) => {
  const { url, method } = CREATE_ORDER_ATTACHMENT_API;

  return api({
    method,
    url,
    data: lineAttachmentParams({ lineAttachment }),
  });
};

export const apiFetchLineAttachment = ({ lineAttachmentId }) => {
  const { url, method } = FETCH_LINE_ATTACHMENT_API;

  return api({
    method,
    url: generateRoute(url, lineAttachmentId),
  });
};

export const apiUpdateLineAttachment = ({ lineAttachment }) => {
  const { url, method } = UPDATE_LINE_ATTACHMENT_API;

  return api({
    method,
    url: generateRoute(url, lineAttachment.id),
    data: lineAttachmentParams({ lineAttachment }),
  });
};

export const apiDeleteLineAttachment = ({ lineAttachment }) => {
  const { url, method } = DELETE_LINE_ATTACHMENT_API;

  return api({
    method,
    url: generateRoute(url, lineAttachment.id),
  });
};

export const apiDownloadLineAttachment = ({ lineAttachment }) => {
  const { url, method, responseType } = DOWNLOAD_LINE_ATTACHMENT_API;

  return api({
    method,
    url: generateRoute(url, lineAttachment.id),
    responseType,
    transformResponse: [(data) => {
      return data;
    }]
  });
};

const lineAttachmentParams = ({ lineAttachment }) => {
  const { attachable, dummyId, file, title } = lineAttachment;

  if (file) {
    return {
      lineAttachment: {
        attachableId: attachable ? extractIdFrom(attachable.id) : null,
        attachableType: attachable ? attachableType(attachable) : null,
        title: file.title,
        dummyId,
      },
      file: file.content,
    }
  }

  return {
    lineAttachment: {
      title,
    }
  }
};

const attachableType = (attachable) => {
  switch (extractClassNameFrom(attachable.id)) {

    // case 'bulkWinePurchase':
    //   return railsClassNameMapper['basePurchase'];

    default:
      return extractRailsClassNameFrom(attachable.id);
  }

};