
export const CREATE_SEA_FREIGHT_EXPORTER_API = {
  url: `/sea_freight_exporters`,
  method: 'post'
};

export const INDEX_SEA_FREIGHT_EXPORTERS_API = {
  url: `/sea_freight_exporters`,
  method: 'get',
};

export const FETCH_SEA_FREIGHT_EXPORTER_API = {
  url: `/sea_freight_exporters/:id`,
  method: 'get',
};

export const UPDATE_SEA_FREIGHT_EXPORTER_API = {
  url: `/sea_freight_exporters/:id`,
  method: 'put'
};

export const DELETE_SEA_FREIGHT_EXPORTER_API = {
  url: `/sea_freight_exporters/:id`,
  method: 'delete'
};
