import {
  ASSIGN_PACK_BATCH_UPLOAD_DATA_FILE,
  CREATE_PACK_BATCH_UPLOAD,
  CREATE_PACK_BATCH_UPLOAD_FAILED,
  DOWNLOAD_PACK_BATCH_UPLOAD_CSV,
  FETCH_PACK_BATCH_UPLOAD_SUCCESS,
  INIT_PACK_BATCH_UPLOAD
} from "redux/model/packBatchUploads/packBatchUpload/constants";


export const initPackBatchUpload = () => ({
  type: INIT_PACK_BATCH_UPLOAD,
  payload: {}
});

export const fetchPackBatchUploadSuccess = ({ packBatchUpload }) => ({
  type: FETCH_PACK_BATCH_UPLOAD_SUCCESS,
  payload: { packBatchUpload }
});

export const assignPackBatchUploadDataFile = ({ dataFile }) => ({
  type: ASSIGN_PACK_BATCH_UPLOAD_DATA_FILE,
  payload: { dataFile }
});

export const createPackBatchUpload = ({ packBatchUpload }) => ({
  type: CREATE_PACK_BATCH_UPLOAD,
  payload: { packBatchUpload }
});

export const createPackBatchUploadFailed = ({ error }) => ({
  type: CREATE_PACK_BATCH_UPLOAD_FAILED,
  payload: { error }
});

export const downloadPackBatchUploadCsv = () => ({
  type: DOWNLOAD_PACK_BATCH_UPLOAD_CSV,
  payload: {}
});
