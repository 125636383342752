export const INIT_PACK_DECLARATION = 'INIT_PACK_DECLARATION';

export const FETCH_PACK_DECLARATION = 'FETCH_PACK_DECLARATION';
export const FETCH_PACK_DECLARATION_SUCCESS = 'FETCH_PACK_DECLARATION_SUCCESS';
export const FETCH_PACK_DECLARATION_FAILED = 'FETCH_PACK_DECLARATION_FAILED';

export const CREATE_PACK_DECLARATION = 'CREATE_PACK_DECLARATION';
export const CREATE_PACK_DECLARATION_SUCCESS = 'CREATE_PACK_DECLARATION_SUCCESS';
export const CREATE_PACK_DECLARATION_FAILED = 'CREATE_PACK_DECLARATION_FAILED';

export const UPDATE_PACK_DECLARATION = 'UPDATE_PACK_DECLARATION';
export const UPDATE_PACK_DECLARATION_SUCCESS = 'UPDATE_PACK_DECLARATION_SUCCESS';
export const UPDATE_PACK_DECLARATION_FAILED = 'UPDATE_PACK_DECLARATION_FAILED';

export const DELETE_PACK_DECLARATION = 'DELETE_PACK_DECLARATION';
export const DELETE_PACK_DECLARATION_SUCCESS = 'DELETE_PACK_DECLARATION_SUCCESS';
export const DELETE_PACK_DECLARATION_FAILED = 'DELETE_PACK_DECLARATION_FAILED';
