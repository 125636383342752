import _ from 'lodash';
import {
  CREATE_LINE_ATTACHMENT,
  CREATE_LINE_ATTACHMENT_FAILED,
  CREATE_LINE_ATTACHMENT_SUCCESS, CREATE_ORDER_ATTACHMENT,
  CREATE_PACK_ATTACHMENT,
  DELETE_LINE_ATTACHMENT_SUCCESS,
  UPDATE_LINE_ATTACHMENT_SUCCESS
} from "redux/model/lineAttachments/lineAttachment/constants";
import {
  INIT_LINE_ATTACHMENTS,
  LIST_LINE_ATTACHMENTS,
  LIST_LINE_ATTACHMENTS_FAILED,
  LIST_LINE_ATTACHMENTS_SUCCESS
} from "redux/model/lineAttachments/lineAttachments/constants";

const INIT_STATE = {
  lineAttachments: {},
  loading: true,
  error: null,
};

const lineAttachmentsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_LINE_ATTACHMENTS:
      return INIT_STATE;

    case LIST_LINE_ATTACHMENTS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_LINE_ATTACHMENTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        lineAttachments: _.mapKeys(action.payload.lineAttachments, 'id'),
      };

    case LIST_LINE_ATTACHMENTS_FAILED:
      return {
        ...state,
        lineAttachments: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_LINE_ATTACHMENT:
      return {
        ...state,
        loading: true,
        error: null,
        lineAttachments: {
          ...state.lineAttachments,
          [action.payload.lineAttachment.dummyId]: {
            title: action.payload.lineAttachment.file.title,
            id: action.payload.lineAttachment.dummyId,
            isTemp: true
          }
        }
      };

    case CREATE_PACK_ATTACHMENT:
      return {
        ...state,
        loading: true,
        error: null,
        lineAttachments: {
          ...state.lineAttachments,
          [action.payload.lineAttachment.dummyId]: {
            title: action.payload.lineAttachment.file.title,
            id: action.payload.lineAttachment.dummyId,
            isTemp: true
          }
        }
      };

    case CREATE_ORDER_ATTACHMENT:
      return {
        ...state,
        loading: true,
        error: null,
        lineAttachments: {
          ...state.lineAttachments,
          [action.payload.lineAttachment.dummyId]: {
            title: action.payload.lineAttachment.file.title,
            id: action.payload.lineAttachment.dummyId,
            isTemp: true
          }
        }
      };

    case CREATE_LINE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        lineAttachments: _.omit({
          ...state.lineAttachments,
          [action.payload.lineAttachment.id]: action.payload.lineAttachment,
        }, action.payload.lineAttachment.dummyId),
      };

    case CREATE_LINE_ATTACHMENT_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        lineAttachments: {
          ...state.lineAttachments,
          [action.payload.lineAttachment.dummyId]: {
            ...state.lineAttachments[action.payload.lineAttachment.dummyId],
            title: action.payload.lineAttachment.file.title,
            isTemp: false,
            isCreated: false,
          },
        },
      };

    case UPDATE_LINE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        lineAttachments: {
          ...state.lineAttachments,
          [action.payload.lineAttachment.id]: action.payload.lineAttachment,
        },
      };

    case DELETE_LINE_ATTACHMENT_SUCCESS:
      return {
        ...state,
        lineAttachments: _.omit(state.lineAttachments, action.payload.lineAttachment.id)
      };

    default:
      return { ...state };
  }
};

export default lineAttachmentsReducers;
