import {
  ASSIGN_EXPORT_DIMENSION_BATCH_UPLOAD_DATA_FILE,
  CREATE_EXPORT_DIMENSION_BATCH_UPLOAD,
  CREATE_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED,
  CREATE_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS,
  DELETE_EXPORT_DIMENSION_BATCH_UPLOAD,
  DELETE_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED,
  DELETE_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS,
  DOWNLOAD_EXPORT_DIMENSION_BATCH_UPLOAD_CSV,
  FETCH_EXPORT_DIMENSION_BATCH_UPLOAD,
  FETCH_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED,
  FETCH_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS,
  INIT_EXPORT_DIMENSION_BATCH_UPLOAD,
  UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD,
  UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED,
  UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS
} from "redux/model/exportDimensionBatchUploads/exportDimensionBatchUpload/constants";

export const initExportDimensionBatchUpload = () => ({
  type: INIT_EXPORT_DIMENSION_BATCH_UPLOAD,
  payload: {}
});

export const createExportDimensionBatchUpload = ({ exportDimensionBatchUpload }) => ({
  type: CREATE_EXPORT_DIMENSION_BATCH_UPLOAD,
  payload: { exportDimensionBatchUpload }
});

export const createExportDimensionBatchUploadSuccess = ({ exportDimensionBatchUpload }) => ({
  type: CREATE_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS,
  payload: { exportDimensionBatchUpload }
});

export const createExportDimensionBatchUploadFailed = ({ error }) => ({
  type: CREATE_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED,
  payload: { error }
});

export const fetchExportDimensionBatchUpload = ({ exportDimensionBatchUploadId }) => ({
  type: FETCH_EXPORT_DIMENSION_BATCH_UPLOAD,
  payload: { exportDimensionBatchUploadId }
});

export const fetchExportDimensionBatchUploadSuccess = ({ exportDimensionBatchUpload }) => ({
  type: FETCH_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS,
  payload: { exportDimensionBatchUpload }
});

export const fetchExportDimensionBatchUploadFailed = ({ error }) => ({
  type: FETCH_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED,
  payload: { error }
});

export const updateExportDimensionBatchUpload = ({ exportDimensionBatchUpload }) => ({
  type: UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD,
  payload: { exportDimensionBatchUpload }
});

export const updateExportDimensionBatchUploadSuccess = ({ exportDimensionBatchUpload }) => ({
  type: UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS,
  payload: { exportDimensionBatchUpload }
});

export const updateExportDimensionBatchUploadFailed = ({ error }) => ({
  type: UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED,
  payload: { error }
});

export const deleteExportDimensionBatchUpload = ({ exportDimensionBatchUpload }) => ({
  type: DELETE_EXPORT_DIMENSION_BATCH_UPLOAD,
  payload: { exportDimensionBatchUpload }
});

export const deleteExportDimensionBatchUploadSuccess = ({ exportDimensionBatchUpload }) => ({
  type: DELETE_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS,
  payload: { exportDimensionBatchUpload }
});

export const deleteExportDimensionBatchUploadFailed = ({ error }) => ({
  type: DELETE_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED,
  payload: { error }
});

export const assignExportDimensionBatchUploadDataFile = ({ dataFile }) => ({
  type: ASSIGN_EXPORT_DIMENSION_BATCH_UPLOAD_DATA_FILE,
  payload: { dataFile }
});

export const downloadExportDimensionBatchUploadCsv = () => ({
  type: DOWNLOAD_EXPORT_DIMENSION_BATCH_UPLOAD_CSV,
  payload: {}
});
