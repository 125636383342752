import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD,
  DELETE_EXPORT_INTERNATIONAL_BATCH_UPLOAD,
  DOWNLOAD_EXPORT_INTERNATIONAL_BATCH_UPLOAD_CSV,
  FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD,
  UPDATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD
} from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUpload/constants";
import {
  apiCreateExportInternationalBatchUpload,
  apiDeleteExportInternationalBatchUpload,
  apiDownloadExportInternationalBatchUploadCsv,
  apiFetchExportInternationalBatchUpload,
  apiUpdateExportInternationalBatchUpload
} from "api/exportInternationalBatchUpload/apiExportInternationalBatchUpload";
import {
  createExportInternationalBatchUploadFailed,
  createExportInternationalBatchUploadSuccess,
  deleteExportInternationalBatchUploadFailed,
  deleteExportInternationalBatchUploadSuccess,
  fetchExportInternationalBatchUploadFailed,
  fetchExportInternationalBatchUploadSuccess,
  initExportInternationalBatchUpload,
  updateExportInternationalBatchUploadFailed,
  updateExportInternationalBatchUploadSuccess
} from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUpload/exportInternationalBatchUploadActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_EXPORT_INTERNATIONAL_BATCH_UPLOADS_PATH } from "routes/exportInternationalBatchUpload/constants";
import fileDownload from "js-file-download";


function* exportInternationalBatchUploadSaga() {
  yield all([
    fork(watchFetchExportInternationalBatchUpload),
    fork(watchUpdateExportInternationalBatchUpload),
    fork(watchCreateExportInternationalBatchUpload),
    fork(watchDeleteExportInternationalBatchUpload),
    fork(watchDownloadExportInternationalBatchUploadCsv),
  ]);
}

// Fetch ExportInternationalBatchUpload
export function* watchFetchExportInternationalBatchUpload() {
  yield takeEvery(FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD, sagaFetchExportInternationalBatchUpload);
}

export function* sagaFetchExportInternationalBatchUpload({ payload }) {
  try {
    const response = yield call(apiFetchExportInternationalBatchUpload, payload);

    const { exportInternationalBatchUpload } = response.data;
    yield put(fetchExportInternationalBatchUploadSuccess({ exportInternationalBatchUpload }));

  } catch (e) {
    yield put(fetchExportInternationalBatchUploadFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create ExportInternationalBatchUpload
export function* watchCreateExportInternationalBatchUpload() {
  yield takeLeading(CREATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD, sagaCreateExportInternationalBatchUpload);
}

export function* sagaCreateExportInternationalBatchUpload({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateExportInternationalBatchUpload, payload);

    const { exportInternationalBatchUpload } = response.data;
    yield put(createExportInternationalBatchUploadSuccess({ exportInternationalBatchUpload }));
    yield put(toastSaved({ name: `ExportInternationalBatchUpload` }));


    history.push(generateRoute(INDEX_EXPORT_INTERNATIONAL_BATCH_UPLOADS_PATH));
    yield put(initExportInternationalBatchUpload());


  } catch (e) {
    yield put(createExportInternationalBatchUploadFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update ExportInternationalBatchUpload
export function* watchUpdateExportInternationalBatchUpload() {
  yield takeLeading(UPDATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD, sagaUpdateExportInternationalBatchUpload);
}

export function* sagaUpdateExportInternationalBatchUpload({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateExportInternationalBatchUpload, payload);

    const { exportInternationalBatchUpload } = response.data;
    yield put(updateExportInternationalBatchUploadSuccess({ exportInternationalBatchUpload }));
    yield put(toastSaved({ name: 'ExportInternationalBatchUpload' }));
    history.push(generateRoute(INDEX_EXPORT_INTERNATIONAL_BATCH_UPLOADS_PATH));
    yield put(initExportInternationalBatchUpload());

  } catch (e) {
    yield put(updateExportInternationalBatchUploadFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete ExportInternationalBatchUpload
export function* watchDeleteExportInternationalBatchUpload() {
  yield takeLeading(DELETE_EXPORT_INTERNATIONAL_BATCH_UPLOAD, sagaDeleteExportInternationalBatchUpload);
}

export function* sagaDeleteExportInternationalBatchUpload({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteExportInternationalBatchUpload, payload);

    const { exportInternationalBatchUpload } = response.data;
    yield put(deleteExportInternationalBatchUploadSuccess({ exportInternationalBatchUpload }));
    yield put(toastDeleted({ name: 'ExportInternationalBatchUpload' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteExportInternationalBatchUploadFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Download Attachment
export function* watchDownloadExportInternationalBatchUploadCsv() {
  yield takeEvery(DOWNLOAD_EXPORT_INTERNATIONAL_BATCH_UPLOAD_CSV, sagaDownloadExportInternationalBatchUploadCsv);
}

export function* sagaDownloadExportInternationalBatchUploadCsv({ payload }) {
  try {
    const response = yield call(apiDownloadExportInternationalBatchUploadCsv, payload);
    yield call(
      fileDownload,
      response.data,
      `出库国际运单表上传.csv`
    );

  } catch (e) {
    console.log(generateErrorMessage(e));
    throwFrontError(e);
  }
}


export default exportInternationalBatchUploadSaga;
