import {
  ERROR_FORM_SEA_FREIGHT_CONTAINER,
  LOADING_FORM_SEA_FREIGHT_CONTAINER,
  RESET_FORM_SEA_FREIGHT_CONTAINER
} from "redux/ui/form/seaFreightContainer/constants";
import {
  CREATE_SEA_FREIGHT_CONTAINER,
  CREATE_SEA_FREIGHT_CONTAINER_FAILED,
  CREATE_SEA_FREIGHT_CONTAINER_SUCCESS,
  UPDATE_SEA_FREIGHT_CONTAINER, UPDATE_SEA_FREIGHT_CONTAINER_FAILED, UPDATE_SEA_FREIGHT_CONTAINER_SUCCESS
} from "redux/model/seaFreightContainers/seaFreightContainer/constants";


const INIT_STATE = {
  loading: false,
  error: null,
};

const formSeaFreightContainerReducers = (state = INIT_STATE, action) => {
  switch (action.type) {

    case LOADING_FORM_SEA_FREIGHT_CONTAINER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case ERROR_FORM_SEA_FREIGHT_CONTAINER:
      return {
        ...state,
        loading: false,
        error: action.payload.error
      };
    case RESET_FORM_SEA_FREIGHT_CONTAINER:
      return {
        ...state,
        loading: false,
        error: null
      };

    case CREATE_SEA_FREIGHT_CONTAINER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case CREATE_SEA_FREIGHT_CONTAINER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case CREATE_SEA_FREIGHT_CONTAINER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };

    case UPDATE_SEA_FREIGHT_CONTAINER:
      return {
        ...state,
        loading: true,
        error: null
      };
    case UPDATE_SEA_FREIGHT_CONTAINER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null
      };
    case UPDATE_SEA_FREIGHT_CONTAINER_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };


    default:
      return { ...state };
  }
};

export default formSeaFreightContainerReducers;
