import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_EXPORT_DIMENSION_BATCH_UPLOAD_API,
  DELETE_EXPORT_DIMENSION_BATCH_UPLOAD_API,
  DOWNLOAD_EXPORT_DIMENSION_BATCH_UPLOAD_CSV_API,
  FETCH_EXPORT_DIMENSION_BATCH_UPLOAD_API,
  INDEX_EXPORT_DIMENSION_BATCH_UPLOADS_API,
  UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD_API
} from "api/exportDimensionBatchUpload/constants";

export const apiListExportDimensionBatchUploads = () => {
  const { url, method } = INDEX_EXPORT_DIMENSION_BATCH_UPLOADS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateExportDimensionBatchUpload = ({ exportDimensionBatchUpload }) => {
  const { url, method } = CREATE_EXPORT_DIMENSION_BATCH_UPLOAD_API;

  return api({
    method,
    url,
    data: exportDimensionBatchUploadParams({ exportDimensionBatchUpload }),
  });
};

export const apiFetchExportDimensionBatchUpload = ({ exportDimensionBatchUploadId }) => {
  const { url, method } = FETCH_EXPORT_DIMENSION_BATCH_UPLOAD_API;

  return api({
    method,
    url: generateRoute(url, exportDimensionBatchUploadId),
  });
};

export const apiUpdateExportDimensionBatchUpload = ({ exportDimensionBatchUpload }) => {
  const { url, method } = UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD_API;

  return api({
    method,
    url: generateRoute(url, exportDimensionBatchUpload.id),
    data: exportDimensionBatchUploadParams({ exportDimensionBatchUpload }),
  });
};

export const apiDeleteExportDimensionBatchUpload = ({ exportDimensionBatchUpload }) => {
  const { url, method } = DELETE_EXPORT_DIMENSION_BATCH_UPLOAD_API;

  return api({
    method,
    url: generateRoute(url, exportDimensionBatchUpload.id),
  });
};

export const apiDownloadExportDimensionBatchUploadCsv = () => {
  const { url, method, responseType } = DOWNLOAD_EXPORT_DIMENSION_BATCH_UPLOAD_CSV_API;

  return api({
    method,
    url,
    responseType,
    transformResponse: [(data) => {
      return data;
    }]
  });
};

const exportDimensionBatchUploadParams = ({ exportDimensionBatchUpload }) => {
  const { dataFile } = exportDimensionBatchUpload;

  return {
    dataFile,
  }
};
