import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_SEA_FREIGHT_CONTAINER_DECLARATION,
  DELETE_SEA_FREIGHT_CONTAINER_DECLARATION,
  FETCH_SEA_FREIGHT_CONTAINER_DECLARATION,
  UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION
} from "redux/model/seaFreightContainerDeclarations/seaFreightContainerDeclaration/constants";
import {
  apiCreateSeaFreightContainerDeclaration,
  apiDeleteSeaFreightContainerDeclaration,
  apiFetchSeaFreightContainerDeclaration,
  apiUpdateSeaFreightContainerDeclaration
} from "api/seaFreightContainerDeclaration/apiSeaFreightContainerDeclaration";
import {
  createSeaFreightContainerDeclarationFailed,
  createSeaFreightContainerDeclarationSuccess,
  deleteSeaFreightContainerDeclarationFailed,
  deleteSeaFreightContainerDeclarationSuccess,
  fetchSeaFreightContainerDeclarationFailed,
  fetchSeaFreightContainerDeclarationSuccess,
  initSeaFreightContainerDeclaration,
  updateSeaFreightContainerDeclarationFailed,
  updateSeaFreightContainerDeclarationSuccess
} from "redux/model/seaFreightContainerDeclarations/seaFreightContainerDeclaration/seaFreightContainerDeclarationActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_SEA_FREIGHT_CONTAINER_DECLARATIONS_PATH } from "routes/seaFreightContainerDeclaration/constants";


function* seaFreightContainerDeclarationSaga() {
  yield all([
    fork(watchFetchSeaFreightContainerDeclaration),
    fork(watchUpdateSeaFreightContainerDeclaration),
    fork(watchCreateSeaFreightContainerDeclaration),
    fork(watchDeleteSeaFreightContainerDeclaration),
  ]);
}

// Fetch SeaFreightContainerDeclaration
export function* watchFetchSeaFreightContainerDeclaration() {
  yield takeEvery(FETCH_SEA_FREIGHT_CONTAINER_DECLARATION, sagaFetchSeaFreightContainerDeclaration);
}

export function* sagaFetchSeaFreightContainerDeclaration({ payload }) {
  try {
    const response = yield call(apiFetchSeaFreightContainerDeclaration, payload);

    const { seaFreightContainerDeclaration } = response.data;
    yield put(fetchSeaFreightContainerDeclarationSuccess({ seaFreightContainerDeclaration }));

  } catch (e) {
    yield put(fetchSeaFreightContainerDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create SeaFreightContainerDeclaration
export function* watchCreateSeaFreightContainerDeclaration() {
  yield takeLeading(CREATE_SEA_FREIGHT_CONTAINER_DECLARATION, sagaCreateSeaFreightContainerDeclaration);
}

export function* sagaCreateSeaFreightContainerDeclaration({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateSeaFreightContainerDeclaration, payload);

    const { seaFreightContainerDeclaration } = response.data;
    yield put(createSeaFreightContainerDeclarationSuccess({ seaFreightContainerDeclaration }));
    yield put(toastSaved({ name: `SeaFreightContainerDeclaration` }));


    history.push(generateRoute(INDEX_SEA_FREIGHT_CONTAINER_DECLARATIONS_PATH));
    yield put(initSeaFreightContainerDeclaration());


  } catch (e) {
    yield put(createSeaFreightContainerDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update SeaFreightContainerDeclaration
export function* watchUpdateSeaFreightContainerDeclaration() {
  yield takeLeading(UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION, sagaUpdateSeaFreightContainerDeclaration);
}

export function* sagaUpdateSeaFreightContainerDeclaration({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateSeaFreightContainerDeclaration, payload);

    const { seaFreightContainerDeclaration } = response.data;
    yield put(updateSeaFreightContainerDeclarationSuccess({ seaFreightContainerDeclaration }));
    yield put(toastSaved({ name: 'SeaFreightContainerDeclaration' }));
    history.push(generateRoute(INDEX_SEA_FREIGHT_CONTAINER_DECLARATIONS_PATH));
    yield put(initSeaFreightContainerDeclaration());

  } catch (e) {
    yield put(updateSeaFreightContainerDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete SeaFreightContainerDeclaration
export function* watchDeleteSeaFreightContainerDeclaration() {
  yield takeLeading(DELETE_SEA_FREIGHT_CONTAINER_DECLARATION, sagaDeleteSeaFreightContainerDeclaration);
}

export function* sagaDeleteSeaFreightContainerDeclaration({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteSeaFreightContainerDeclaration, payload);

    const { seaFreightContainerDeclaration } = response.data;
    yield put(deleteSeaFreightContainerDeclarationSuccess({ seaFreightContainerDeclaration }));
    yield put(toastDeleted({ name: 'SeaFreightContainerDeclaration' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteSeaFreightContainerDeclarationFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default seaFreightContainerDeclarationSaga;
