
export const CREATE_PACK_DECLARATION_API = {
  url: `/packDeclarations`,
  method: 'post'
};

export const INDEX_PACK_DECLARATIONS_API = {
  url: `/packDeclarations`,
  method: 'get',
};

export const FETCH_PACK_DECLARATION_API = {
  url: `/packDeclarations/:id`,
  method: 'get',
};

export const UPDATE_PACK_DECLARATION_API = {
  url: `/packDeclarations/:id`,
  method: 'put'
};

export const DELETE_PACK_DECLARATION_API = {
  url: `/packDeclarations/:id`,
  method: 'delete'
};
