
export const CREATE_LINE_ATTACHMENT_API = {
  url: `/line_attachments`,
  method: 'post'
};

export const CREATE_PACK_ATTACHMENT_API = {
  url: `/line_attachments/create_pack_attachment`,
  method: 'post'
};

export const CREATE_ORDER_ATTACHMENT_API = {
  url: `/line_attachments/create_order_attachment`,
  method: 'post'
};

export const INDEX_LINE_ATTACHMENTS_API = {
  url: `/line_attachments`,
  method: 'get',
};

export const FETCH_LINE_ATTACHMENT_API = {
  url: `/line_attachments/:id`,
  method: 'get',
};

export const UPDATE_LINE_ATTACHMENT_API = {
  url: `/line_attachments/:id`,
  method: 'put'
};

export const DELETE_LINE_ATTACHMENT_API = {
  url: `/line_attachments/:id`,
  method: 'delete'
};

export const DOWNLOAD_LINE_ATTACHMENT_API = {
  url: `/line_attachments/:id/download`,
  method: 'get',
  responseType: 'arraybuffer',
};