import { api, extractIdFrom, generateRoute, isNew } from "@tmatt-tech/react_common";
import {
  CREATE_ORDER_DECLARATION_API,
  INDEX_ORDER_DECLARATIONS_API,
  FETCH_ORDER_DECLARATION_API,
  DELETE_ORDER_DECLARATION_API,
  UPDATE_ORDER_DECLARATION_API
} from "api/orderDeclaration/constants";
import _ from "lodash";

export const apiListOrderDeclarations = () => {
  const { url, method } = INDEX_ORDER_DECLARATIONS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateOrderDeclaration = ({ orderDeclaration }) => {
  const { url, method } = CREATE_ORDER_DECLARATION_API;

  return api({
    method,
    url,
    data: orderDeclarationParams({ orderDeclaration }),
  });
};

export const apiFetchOrderDeclaration = ({ orderDeclarationId }) => {
  const { url, method } = FETCH_ORDER_DECLARATION_API;

  return api({
    method,
    url: generateRoute(url, orderDeclarationId),
  });
};

export const apiUpdateOrderDeclaration = ({ orderDeclaration }) => {
  const { url, method } = UPDATE_ORDER_DECLARATION_API;

  return api({
    method,
    url: generateRoute(url, orderDeclaration.id),
    data: orderDeclarationParams({ orderDeclaration }),
  });
};

export const apiDeleteOrderDeclaration = ({ orderDeclaration }) => {
  const { url, method } = DELETE_ORDER_DECLARATION_API;

  return api({
    method,
    url: generateRoute(url, orderDeclaration.id),
  });
};

const orderDeclarationParams = ({ orderDeclaration }) => {
  const {
    exporterName,
    exporterAddress,
    exporterPhone,
    exporterEmail,
    consigneeName,
    consigneeAddress,
    consigneePhone,
    consigneeEmail,
    invoiceHouseBillNumber,
    invoiceCurrency,
    invoiceNumber,
    invoiceCountryOfOrigin,
    fromAtr,
    datePackTime,
    remarks,
    to,
    by,
    deliveryAndPaymentTerm,
    noAndDateOfInvoice,
    noAndDateOfPackingList,
    lineItemDeclarations,
  } = orderDeclaration;

  const lineItemDeclarationsAttributes = _.map(lineItemDeclarations, (lineItemDeclaration) => {
    const {
      id,
      caseCount,
      descriptionZh,
      descriptionEn,
      materialZh,
      materialEn,
      quantity,
      netWeight,
      cbm,
      unitPrice,
      totalAmount,
      packageName,
      model,
      grossWeight,
      length,
      height,
      width,
      destroy,
    } = lineItemDeclaration;

    return {
      id: isNew(lineItemDeclaration) ? null : extractIdFrom(id),
      caseCount,
      descriptionZh,
      descriptionEn,
      materialZh,
      materialEn,
      quantity,
      netWeight,
      cbm,
      unitPrice,
      totalAmount,
      packageName,
      model,
      grossWeight,
      length,
      height,
      width,
      _destroy: destroy,
    };
  });

  return {
    exporterName,
    exporterAddress,
    exporterPhone,
    exporterEmail,
    consigneeName,
    consigneeAddress,
    consigneePhone,
    consigneeEmail,
    invoiceHouseBillNumber,
    invoiceCurrency,
    invoiceNumber,
    invoiceCountryOfOrigin,
    fromAtr,
    datePackTime,
    remarks,
    to,
    by,
    deliveryAndPaymentTerm,
    noAndDateOfInvoice,
    noAndDateOfPackingList,
    lineItemDeclarationsAttributes,
  }
};


