import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_ORDER_PACK,
  DELETE_ORDER_PACK,
  FETCH_ORDER_PACK, MERGE_ORDER_PACKS, SPLIT_ORDER_PACK,
  UPDATE_ORDER_PACK
} from "redux/model/orderPacks/orderPack/constants";
import {
  apiCreateOrderPack,
  apiDeleteOrderPack,
  apiFetchOrderPack, apiMergeOrderPacks, apiSplitOrderPack,
  apiUpdateOrderPack
} from "api/orderPack/apiOrderPack";
import {
  createOrderPackFailed,
  createOrderPackSuccess,
  deleteOrderPackFailed,
  deleteOrderPackSuccess,
  fetchOrderPackFailed,
  fetchOrderPackSuccess,
  initOrderPack,
  updateOrderPackFailed,
  updateOrderPackSuccess
} from "redux/model/orderPacks/orderPack/orderPackActions";
import {
  toastDefault,
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeFirstLayerModal,
  closeSecondLayerModal, startFirstLayerModalListening,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_ORDER_PACKS_PATH } from "routes/orderPack/constants";
import { fetchClientSuccess } from "redux/model/clients/client/clientActions";
import { listLineItemsSuccess } from "redux/model/lineItems/lineItems/lineItemsActions";
import { listPacksSuccess } from "redux/model/packs/packs/packsActions";
import { listLineAttachmentsSuccess } from "redux/model/lineAttachments/lineAttachments/lineAttachmentsActions";
import { fetchPaymentSuccess } from "redux/model/payments/payment/paymentActions";
import { listTransactionsSuccess } from "redux/model/transactions/transactions/transactionsActions";


function* orderPackSaga() {
  yield all([
    fork(watchFetchOrderPack),
    fork(watchUpdateOrderPack),
    fork(watchCreateOrderPack),
    fork(watchDeleteOrderPack),
    fork(watchSplitOrderPack),
    fork(watchMergeOrderPacks),
  ]);
}

// Fetch OrderPack
export function* watchFetchOrderPack() {
  yield takeEvery(FETCH_ORDER_PACK, sagaFetchOrderPack);
}

export function* sagaFetchOrderPack({ payload }) {
  try {
    const response = yield call(apiFetchOrderPack, payload);

    const { orderPack, client, lineItems, packs, lineAttachments, payment, transactions } = response.data;
    yield put(fetchOrderPackSuccess({ orderPack }));
    yield put(fetchClientSuccess({ client }));
    yield put(listLineItemsSuccess({ lineItems }));
    yield put(listPacksSuccess({ packs }));
    yield put(listLineAttachmentsSuccess({ lineAttachments }));
    yield put(fetchPaymentSuccess({ payment }));
    yield put(listTransactionsSuccess({ transactions }));

  } catch (e) {
    yield put(fetchOrderPackFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create OrderPack
export function* watchCreateOrderPack() {
  yield takeLeading(CREATE_ORDER_PACK, sagaCreateOrderPack);
}

export function* sagaCreateOrderPack({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateOrderPack, payload);

    const { orderPack } = response.data;
    yield put(createOrderPackSuccess({ orderPack }));
    yield put(toastSaved({ name: `OrderPack` }));


    history.push(generateRoute(INDEX_ORDER_PACKS_PATH));
    yield put(initOrderPack());


  } catch (e) {
    yield put(createOrderPackFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update OrderPack
export function* watchUpdateOrderPack() {
  yield takeLeading(UPDATE_ORDER_PACK, sagaUpdateOrderPack);
}

export function* sagaUpdateOrderPack({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateOrderPack, payload);

    const { orderPack } = response.data;
    yield put(updateOrderPackSuccess({ orderPack }));
    yield put(toastSaved({ name: 'OrderPack' }));

  } catch (e) {
    yield put(updateOrderPackFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete OrderPack
export function* watchDeleteOrderPack() {
  yield takeLeading(DELETE_ORDER_PACK, sagaDeleteOrderPack);
}

export function* sagaDeleteOrderPack({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteOrderPack, payload);

    const { orderPack } = response.data;
    yield put(deleteOrderPackSuccess({ orderPack }));
    yield put(toastDeleted({ name: 'OrderPack' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteOrderPackFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Split OrderPack
export function* watchSplitOrderPack() {
  yield takeLeading(SPLIT_ORDER_PACK, sagaSplitOrderPack);
}

export function* sagaSplitOrderPack({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiSplitOrderPack, payload);

    const { orderPack, lineItems, packs } = response.data;

    yield put(updateOrderPackSuccess({ orderPack }));
    yield put(listLineItemsSuccess({ lineItems }));
    yield put(listPacksSuccess({ packs }));

    yield put(startFirstLayerModalListening());
    yield put(closeFirstLayerModal());
    yield put(toastDefault({ message: 'Order has been split' }))

  } catch (e) {
    yield put(updateOrderPackFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Merge OrderPacks
export function* watchMergeOrderPacks() {
  yield takeLeading(MERGE_ORDER_PACKS, sagaMergeOrderPacks);
}

export function* sagaMergeOrderPacks({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiMergeOrderPacks, payload);

    const { orderPack, lineItems, packs } = response.data;

    yield put(updateOrderPackSuccess({ orderPack }));
    yield put(listLineItemsSuccess({ lineItems }));
    yield put(listPacksSuccess({ packs }));

    yield put(startFirstLayerModalListening());
    yield put(closeFirstLayerModal());
    yield put(toastDefault({ message: 'Order has been merged' }));

  } catch (e) {
    yield put(updateOrderPackFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default orderPackSaga;
