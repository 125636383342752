import _ from 'lodash';
import {
  CREATE_ORDER_PACK_SUCCESS,
  DELETE_ORDER_PACK_SUCCESS,
  UPDATE_ORDER_PACK_SUCCESS
} from "redux/model/orderPacks/orderPack/constants";
import {
  DOWNLOAD_ALL_ORDERS_CSV,
  DOWNLOAD_ALL_ORDERS_CSV_FAILED,
  DOWNLOAD_ALL_ORDERS_CSV_SUCCESS,
  INIT_ORDER_PACKS,
  LIST_ORDER_PACKS,
  LIST_ORDER_PACKS_FAILED,
  LIST_ORDER_PACKS_SUCCESS
} from "redux/model/orderPacks/orderPacks/constants";

const INIT_STATE = {
  orderPacks: {},
  totalSize: 0,
  loading: true,
  error: null,
};

const orderPacksReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_ORDER_PACKS:
      return INIT_STATE;

    case LIST_ORDER_PACKS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_ORDER_PACKS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        orderPacks: _.mapKeys(action.payload.orderPacks, 'id'),
        totalSize: action.payload.totalSize,
      };

    case LIST_ORDER_PACKS_FAILED:
      return {
        ...state,
        orderPacks: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_ORDER_PACK_SUCCESS:
      return {
        ...state,
        orderPacks: {
          [action.payload.orderPack.id]: action.payload.orderPack,
          ...state.orderPacks,
        },
      };

    case UPDATE_ORDER_PACK_SUCCESS:
      return {
        ...state,
        orderPacks: {
          ...state.orderPacks,
          [action.payload.orderPack.id]: action.payload.orderPack,
        },
      };

    case DELETE_ORDER_PACK_SUCCESS:
      return {
        ...state,
        orderPacks: _.omit(state.orderPacks, action.payload.orderPack.id)
      };
    
    case DOWNLOAD_ALL_ORDERS_CSV:
      return {
        ...state,
        loading: true,
        error: null,
      }
    case DOWNLOAD_ALL_ORDERS_CSV_SUCCESS:
      return {
        ...state,
        loading: false,
      }

    case DOWNLOAD_ALL_ORDERS_CSV_FAILED: 
    return {
      ...state,
      loading: false,
      error: action.payload.error,
    }
    default:
      return { ...state };
  }
};

export default orderPacksReducers;
