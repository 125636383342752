import {
  INIT_SEA_FREIGHT_CONSIGNEES,
  LIST_SEA_FREIGHT_CONSIGNEES,
  LIST_SEA_FREIGHT_CONSIGNEES_FAILED,
  LIST_SEA_FREIGHT_CONSIGNEES_SUCCESS
} from "redux/model/seaFreightConsignees/seaFreightConsignees/constants";

export const initSeaFreightConsignees = () => ({
  type: INIT_SEA_FREIGHT_CONSIGNEES,
  payload: {}
});

export const listSeaFreightConsignees = () => ({
  type: LIST_SEA_FREIGHT_CONSIGNEES,
  payload: {}
});

export const listSeaFreightConsigneesSuccess = ({ seaFreightConsignees }) => ({
  type: LIST_SEA_FREIGHT_CONSIGNEES_SUCCESS,
  payload: { seaFreightConsignees }
});

export const listSeaFreightConsigneesFailed = ({ error }) => ({
  type: LIST_SEA_FREIGHT_CONSIGNEES_FAILED,
  payload: { error }
});
