import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_PAYMENTS } from "redux/model/payments/payments/constants";
import { apiListPayments } from "api/payment/apiPayment";
import { listPaymentsFailed, listPaymentsSuccess } from "redux/model/payments/payments/paymentsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* paymentsSaga() {
  yield all([
    fork(watchListPayments),
  ]);
}

// List Payments
export function* watchListPayments() {
  yield takeEvery(LIST_PAYMENTS, sagaListPayments);
}

export function* sagaListPayments() {
  try {
    const response = yield call(apiListPayments);

    const { payments } = response.data;

    yield put(listPaymentsSuccess({ payments }));

  } catch (e) {
    yield put(listPaymentsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default paymentsSaga;
