import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  CREATE_EXPORT_DIMENSION_BATCH_UPLOAD,
  DELETE_EXPORT_DIMENSION_BATCH_UPLOAD,
  DOWNLOAD_EXPORT_DIMENSION_BATCH_UPLOAD_CSV,
  FETCH_EXPORT_DIMENSION_BATCH_UPLOAD,
  UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD
} from "redux/model/exportDimensionBatchUploads/exportDimensionBatchUpload/constants";
import {
  apiCreateExportDimensionBatchUpload,
  apiDeleteExportDimensionBatchUpload, apiDownloadExportDimensionBatchUploadCsv,
  apiFetchExportDimensionBatchUpload,
  apiUpdateExportDimensionBatchUpload
} from "api/exportDimensionBatchUpload/apiExportDimensionBatchUpload";
import {
  createExportDimensionBatchUploadFailed,
  createExportDimensionBatchUploadSuccess,
  deleteExportDimensionBatchUploadFailed,
  deleteExportDimensionBatchUploadSuccess,
  fetchExportDimensionBatchUploadFailed,
  fetchExportDimensionBatchUploadSuccess,
  initExportDimensionBatchUpload,
  updateExportDimensionBatchUploadFailed,
  updateExportDimensionBatchUploadSuccess
} from "redux/model/exportDimensionBatchUploads/exportDimensionBatchUpload/exportDimensionBatchUploadActions";
import { toastDeleted, toastError, toastSaved } from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeSecondLayerModal,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_EXPORT_DIMENSION_BATCH_UPLOADS_PATH } from "routes/exportDimensionBatchUpload/constants";
import fileDownload from "js-file-download";


function* exportDimensionBatchUploadSaga() {
  yield all([
    fork(watchFetchExportDimensionBatchUpload),
    fork(watchUpdateExportDimensionBatchUpload),
    fork(watchCreateExportDimensionBatchUpload),
    fork(watchDeleteExportDimensionBatchUpload),
    fork(watchDownloadExportDimensionBatchUploadCsv),
  ]);
}

// Fetch ExportDimensionBatchUpload
export function* watchFetchExportDimensionBatchUpload() {
  yield takeEvery(FETCH_EXPORT_DIMENSION_BATCH_UPLOAD, sagaFetchExportDimensionBatchUpload);
}

export function* sagaFetchExportDimensionBatchUpload({ payload }) {
  try {
    const response = yield call(apiFetchExportDimensionBatchUpload, payload);

    const { exportDimensionBatchUpload } = response.data;
    yield put(fetchExportDimensionBatchUploadSuccess({ exportDimensionBatchUpload }));

  } catch (e) {
    yield put(fetchExportDimensionBatchUploadFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create ExportDimensionBatchUpload
export function* watchCreateExportDimensionBatchUpload() {
  yield takeLeading(CREATE_EXPORT_DIMENSION_BATCH_UPLOAD, sagaCreateExportDimensionBatchUpload);
}

export function* sagaCreateExportDimensionBatchUpload({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateExportDimensionBatchUpload, payload);

    const { exportDimensionBatchUpload } = response.data;
    yield put(createExportDimensionBatchUploadSuccess({ exportDimensionBatchUpload }));
    yield put(toastSaved({ name: `ExportDimensionBatchUpload` }));


    history.push(generateRoute(INDEX_EXPORT_DIMENSION_BATCH_UPLOADS_PATH));
    yield put(initExportDimensionBatchUpload());


  } catch (e) {
    yield put(createExportDimensionBatchUploadFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update ExportDimensionBatchUpload
export function* watchUpdateExportDimensionBatchUpload() {
  yield takeLeading(UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD, sagaUpdateExportDimensionBatchUpload);
}

export function* sagaUpdateExportDimensionBatchUpload({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateExportDimensionBatchUpload, payload);

    const { exportDimensionBatchUpload } = response.data;
    yield put(updateExportDimensionBatchUploadSuccess({ exportDimensionBatchUpload }));
    yield put(toastSaved({ name: 'ExportDimensionBatchUpload' }));
    history.push(generateRoute(INDEX_EXPORT_DIMENSION_BATCH_UPLOADS_PATH));
    yield put(initExportDimensionBatchUpload());

  } catch (e) {
    yield put(updateExportDimensionBatchUploadFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete ExportDimensionBatchUpload
export function* watchDeleteExportDimensionBatchUpload() {
  yield takeLeading(DELETE_EXPORT_DIMENSION_BATCH_UPLOAD, sagaDeleteExportDimensionBatchUpload);
}

export function* sagaDeleteExportDimensionBatchUpload({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteExportDimensionBatchUpload, payload);

    const { exportDimensionBatchUpload } = response.data;
    yield put(deleteExportDimensionBatchUploadSuccess({ exportDimensionBatchUpload }));
    yield put(toastDeleted({ name: 'ExportDimensionBatchUpload' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteExportDimensionBatchUploadFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


// Download Attachment
export function* watchDownloadExportDimensionBatchUploadCsv() {
  yield takeEvery(DOWNLOAD_EXPORT_DIMENSION_BATCH_UPLOAD_CSV, sagaDownloadExportDimensionBatchUploadCsv);
}

export function* sagaDownloadExportDimensionBatchUploadCsv({ payload }) {
  try {
    const response = yield call(apiDownloadExportDimensionBatchUploadCsv, payload);
    yield call(
      fileDownload,
      response.data,
      `出库重量体积表上传.csv`
    );

  } catch (e) {
    console.log(generateErrorMessage(e));
    throwFrontError(e);
  }
}


export default exportDimensionBatchUploadSaga;
