import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_COUPON_RULES } from "redux/model/couponRules/couponRules/constants";
import { apiListCouponRules } from "api/couponRule/apiCouponRule";
import { listCouponRulesFailed, listCouponRulesSuccess } from "redux/model/couponRules/couponRules/couponRulesActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* couponRulesSaga() {
  yield all([
    fork(watchListCouponRules),
  ]);
}

// List CouponRules
export function* watchListCouponRules() {
  yield takeEvery(LIST_COUPON_RULES, sagaListCouponRules);
}

export function* sagaListCouponRules() {
  try {
    const response = yield call(apiListCouponRules);

    const { couponRules } = response.data;

    yield put(listCouponRulesSuccess({ couponRules }));

  } catch (e) {
    yield put(listCouponRulesFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default couponRulesSaga;
