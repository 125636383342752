import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_LINE_ITEM_DECLARATIONS } from "redux/model/lineItemDeclarations/lineItemDeclarations/constants";
import { apiListLineItemDeclarations } from "api/lineItemDeclaration/apiLineItemDeclaration";
import { listLineItemDeclarationsFailed, listLineItemDeclarationsSuccess } from "redux/model/lineItemDeclarations/lineItemDeclarations/lineItemDeclarationsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* lineItemDeclarationsSaga() {
  yield all([
    fork(watchListLineItemDeclarations),
  ]);
}

// List LineItemDeclarations
export function* watchListLineItemDeclarations() {
  yield takeEvery(LIST_LINE_ITEM_DECLARATIONS, sagaListLineItemDeclarations);
}

export function* sagaListLineItemDeclarations() {
  try {
    const response = yield call(apiListLineItemDeclarations);

    const { lineItemDeclarations } = response.data;

    yield put(listLineItemDeclarationsSuccess({ lineItemDeclarations }));

  } catch (e) {
    yield put(listLineItemDeclarationsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default lineItemDeclarationsSaga;
