export const INIT_ORDER_PACKS = 'INIT_ORDER_PACKS';

export const LIST_ORDER_PACKS = 'LIST_ORDER_PACKS';
export const LIST_ORDER_PACKS_CAN_BE_MERGED = 'LIST_ORDER_PACKS_CAN_BE_MERGED';
export const LIST_ORDER_PACKS_SUCCESS = 'LIST_ORDER_PACKS_SUCCESS';
export const LIST_ORDER_PACKS_FAILED = 'LIST_ORDER_PACKS_FAILED';

export const DOWNLOAD_PROCESSING_ORDERS_CSV = 'DOWNLOAD_PROCESSING_ORDERS_CSV';
export const DOWNLOAD_DISPATCHING_ORDERS_CSV = 'DOWNLOAD_DISPATCHING_ORDERS_CSV';

export const DOWNLOAD_ALL_ORDERS_CSV = 'DOWNLOAD_ALL_ORDERS_CSV';
export const DOWNLOAD_ALL_ORDERS_CSV_SUCCESS = 'DOWNLOAD_ALL_ORDERS_CSV_SUCCESS';
export const DOWNLOAD_ALL_ORDERS_CSV_FAILED = 'DOWNLOAD_ALL_ORDERS_CSV_FAILED';