import React from "react";
import { PrivateRoute } from "@tmatt-tech/react_common";
import { BASE_SEA_FREIGHT_CONTAINER_PATH, INDEX_SEA_FREIGHT_CONTAINERS_PATH, NEW_SEA_FREIGHT_CONTAINER_PATH, SHOW_SEA_FREIGHT_CONTAINER_PATH } from "routes/seaFreightContainer/constants";

const SeaFreightContainers = React.lazy(() => import('pages/seaFreightContainers/index/SeaFreightContainers'));
const NewSeaFreightContainer = React.lazy(() => import('pages/seaFreightContainers/new/NewSeaFreightContainer'));
const ShowSeaFreightContainer = React.lazy(() => import('pages/seaFreightContainers/show/ShowSeaFreightContainer'));

export const seaFreightContainerRoutes = {
  path: BASE_SEA_FREIGHT_CONTAINER_PATH,
  name: '集装箱',
  icon: 'far fa-container-storage',
  children: [
    {
      path: INDEX_SEA_FREIGHT_CONTAINERS_PATH,
      exact: true,
      name: '集装箱列表',
      component: SeaFreightContainers,
      route: PrivateRoute,
    },
    {
      path: NEW_SEA_FREIGHT_CONTAINER_PATH,
      exact: true,
      name: '新建',
      component: NewSeaFreightContainer,
      route: PrivateRoute,
    },
  ],
};

export const hiddenSeaFreightContainerRoutes = {
  children: [
    {
      path: SHOW_SEA_FREIGHT_CONTAINER_PATH,
      exact: true,
      name: '集装箱',
      component: ShowSeaFreightContainer,
      route: PrivateRoute,
    }
  ]
}
