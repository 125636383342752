import _ from 'lodash';
import {
  CREATE_COUPON_RULE_SUCCESS,
  DELETE_COUPON_RULE_SUCCESS,
  UPDATE_COUPON_RULE_SUCCESS
} from "redux/model/couponRules/couponRule/constants";
import {
  INIT_COUPON_RULES,
  LIST_COUPON_RULES,
  LIST_COUPON_RULES_FAILED,
  LIST_COUPON_RULES_SUCCESS
} from "redux/model/couponRules/couponRules/constants";

const INIT_STATE = {
  couponRules: {},
  loading: true,
  error: null,
};

const couponRulesReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_COUPON_RULES:
      return INIT_STATE;

    case LIST_COUPON_RULES:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_COUPON_RULES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        couponRules: _.mapKeys(action.payload.couponRules, 'id'),
      };

    case LIST_COUPON_RULES_FAILED:
      return {
        ...state,
        couponRules: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_COUPON_RULE_SUCCESS:
      return {
        ...state,
        couponRules: {
          [action.payload.couponRule.id]: action.payload.couponRule,
          ...state.couponRules,
        },
      };

    case UPDATE_COUPON_RULE_SUCCESS:
      return {
        ...state,
        couponRules: {
          ...state.couponRules,
          [action.payload.couponRule.id]: action.payload.couponRule,
        },
      };

    case DELETE_COUPON_RULE_SUCCESS:
      return {
        ...state,
        couponRules: _.omit(state.couponRules, action.payload.couponRule.id)
      };

    default:
      return { ...state };
  }
};

export default couponRulesReducers;
