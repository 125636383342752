import {
  INIT_SEA_FREIGHT_CONTAINERS,
  LIST_SEA_FREIGHT_CONTAINERS, LIST_SEA_FREIGHT_CONTAINERS_CAN_BE_LOADED,
  LIST_SEA_FREIGHT_CONTAINERS_FAILED,
  LIST_SEA_FREIGHT_CONTAINERS_SUCCESS
} from "redux/model/seaFreightContainers/seaFreightContainers/constants";

export const initSeaFreightContainers = () => ({
  type: INIT_SEA_FREIGHT_CONTAINERS,
  payload: {}
});

export const listSeaFreightContainers = () => ({
  type: LIST_SEA_FREIGHT_CONTAINERS,
  payload: {}
});

export const listSeaFreightContainersSuccess = ({ seaFreightContainers }) => ({
  type: LIST_SEA_FREIGHT_CONTAINERS_SUCCESS,
  payload: { seaFreightContainers }
});

export const listSeaFreightContainersFailed = ({ error }) => ({
  type: LIST_SEA_FREIGHT_CONTAINERS_FAILED,
  payload: { error }
});

export const listSeaFreightContainersCanBeLoaded = () => ({
  type: LIST_SEA_FREIGHT_CONTAINERS_CAN_BE_LOADED,
  payload: {}
});