export const INIT_SEA_FREIGHT_CONTAINER = 'INIT_SEA_FREIGHT_CONTAINER';

export const FETCH_SEA_FREIGHT_CONTAINER = 'FETCH_SEA_FREIGHT_CONTAINER';
export const FETCH_SEA_FREIGHT_CONTAINER_SUCCESS = 'FETCH_SEA_FREIGHT_CONTAINER_SUCCESS';
export const FETCH_SEA_FREIGHT_CONTAINER_FAILED = 'FETCH_SEA_FREIGHT_CONTAINER_FAILED';

export const CREATE_SEA_FREIGHT_CONTAINER = 'CREATE_SEA_FREIGHT_CONTAINER';
export const CREATE_SEA_FREIGHT_CONTAINER_SUCCESS = 'CREATE_SEA_FREIGHT_CONTAINER_SUCCESS';
export const CREATE_SEA_FREIGHT_CONTAINER_FAILED = 'CREATE_SEA_FREIGHT_CONTAINER_FAILED';

export const UPDATE_SEA_FREIGHT_CONTAINER = 'UPDATE_SEA_FREIGHT_CONTAINER';
export const UPDATE_SEA_FREIGHT_CONTAINER_SUCCESS = 'UPDATE_SEA_FREIGHT_CONTAINER_SUCCESS';
export const UPDATE_SEA_FREIGHT_CONTAINER_FAILED = 'UPDATE_SEA_FREIGHT_CONTAINER_FAILED';

export const DELETE_SEA_FREIGHT_CONTAINER = 'DELETE_SEA_FREIGHT_CONTAINER';
export const DELETE_SEA_FREIGHT_CONTAINER_SUCCESS = 'DELETE_SEA_FREIGHT_CONTAINER_SUCCESS';
export const DELETE_SEA_FREIGHT_CONTAINER_FAILED = 'DELETE_SEA_FREIGHT_CONTAINER_FAILED';

export const NEW_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT = 'NEW_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT';
export const ASSIGN_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT = 'ASSIGN_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT';
export const PULL_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT = 'PULL_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT';

export const ASSIGN_SEA_FREIGHT_CONTAINER_STATUS = 'ASSIGN_SEA_FREIGHT_CONTAINER_STATUS';

export const DOWNLOAD_SEA_FREIGHT_CONTAINER = 'DOWNLOAD_SEA_FREIGHT_CONTAINER';