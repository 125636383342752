import {
  FETCH_CURRENCY_EXCHANGE,
  FETCH_CURRENCY_EXCHANGE_FAILED,
  FETCH_CURRENCY_EXCHANGE_SUCCESS,
  INIT_CURRENCY_EXCHANGE
} from "redux/model/currencyExchanges/currencyExchange/constants";

const INIT_STATE = {
  currencyExchange: {},
  loading: true,
  error: null,
};

const currencyExchangeReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_CURRENCY_EXCHANGE:
      return INIT_STATE;

    case FETCH_CURRENCY_EXCHANGE:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CURRENCY_EXCHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        currencyExchange: action.payload.currencyExchange,
      };

    case FETCH_CURRENCY_EXCHANGE_FAILED:
      return {
        ...state,
        currencyExchange: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default currencyExchangeReducers;
