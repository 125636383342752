import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_WECHAT_NOTICES } from "redux/model/wechatNotices/wechatNotices/constants";
import { apiListWechatNotices } from "api/wechatNotice/apiWechatNotice";
import { listWechatNoticesFailed, listWechatNoticesSuccess } from "redux/model/wechatNotices/wechatNotices/wechatNoticesActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* wechatNoticesSaga() {
  yield all([
    fork(watchListWechatNotices),
  ]);
}

// List WechatNotices
export function* watchListWechatNotices() {
  yield takeEvery(LIST_WECHAT_NOTICES, sagaListWechatNotices);
}

export function* sagaListWechatNotices() {
  try {
    const response = yield call(apiListWechatNotices);

    const { wechatNotices } = response.data;

    yield put(listWechatNoticesSuccess({ wechatNotices }));

  } catch (e) {
    yield put(listWechatNoticesFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default wechatNoticesSaga;
