import {
  ASSIGN_EXPORT_DIMENSION_BATCH_UPLOAD_DATA_FILE,
  FETCH_EXPORT_DIMENSION_BATCH_UPLOAD,
  FETCH_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED,
  FETCH_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS,
  INIT_EXPORT_DIMENSION_BATCH_UPLOAD
} from "redux/model/exportDimensionBatchUploads/exportDimensionBatchUpload/constants";

const INIT_STATE = {
  exportDimensionBatchUpload: {},
  loading: false,
  error: null,
};

const exportDimensionBatchUploadReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_EXPORT_DIMENSION_BATCH_UPLOAD:
      return INIT_STATE;

    case FETCH_EXPORT_DIMENSION_BATCH_UPLOAD:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        exportDimensionBatchUpload: action.payload.exportDimensionBatchUpload,
      };

    case FETCH_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED:
      return {
        ...state,
        exportDimensionBatchUpload: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_EXPORT_DIMENSION_BATCH_UPLOAD_DATA_FILE:
      return {
        ...state,
        exportDimensionBatchUpload: {
          ...state.exportDimensionBatchUpload,
          dataFile: action.payload.dataFile,
        },
      };

    default:
      return { ...state };
  }
};

export default exportDimensionBatchUploadReducers;
