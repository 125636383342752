import {
  ASSIGN_COUPON_RULE_AMOUNT,
  ASSIGN_COUPON_RULE_AMOUNT_TYPE,
  ASSIGN_COUPON_RULE_CODE,
  ASSIGN_COUPON_RULE_DATE_FROM,
  ASSIGN_COUPON_RULE_DATE_TO,
  ASSIGN_COUPON_RULE_DATE_TYPE,
  ASSIGN_COUPON_RULE_IS_ACTIVE,
  ASSIGN_COUPON_RULE_IS_MULTIPLE,
  ASSIGN_COUPON_RULE_IS_PUBLIC,
  ASSIGN_COUPON_RULE_LIMIT_PAYMENT,
  ASSIGN_COUPON_RULE_NAME,
  FETCH_COUPON_RULE,
  FETCH_COUPON_RULE_FAILED,
  FETCH_COUPON_RULE_SUCCESS,
  INIT_COUPON_RULE, NEW_COUPON_RULE
} from "redux/model/couponRules/couponRule/constants";
import { FIXED_AMOUNT, FIXED_DATE } from "constants/couponRule";
import moment from "moment";

const INIT_STATE = {
  couponRule: {},
  loading: true,
  error: null,
};

const couponRuleReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_COUPON_RULE:
      return INIT_STATE;

    case FETCH_COUPON_RULE:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_COUPON_RULE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        couponRule: action.payload.couponRule,
      };

    case FETCH_COUPON_RULE_FAILED:
      return {
        ...state,
        couponRule: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_COUPON_RULE_NAME:
      return {
        ...state,
        couponRule: {
          ...state.couponRule,
          name: action.payload.name,
        },
      };

    case ASSIGN_COUPON_RULE_CODE:
      return {
        ...state,
        couponRule: {
          ...state.couponRule,
          code: action.payload.code,
        },
      };

    case ASSIGN_COUPON_RULE_DATE_TYPE:
      return {
        ...state,
        couponRule: {
          ...state.couponRule,
          dateType: action.payload.dateType,
          dateFrom: action.payload.dateType === FIXED_DATE ? moment().startOf('day').unix() : null,
          dateTo: action.payload.dateType === FIXED_DATE ? moment().endOf('day').unix() : null,
        },
      };

    case ASSIGN_COUPON_RULE_DATE_FROM:
      return {
        ...state,
        couponRule: {
          ...state.couponRule,
          dateFrom: action.payload.dateFrom,
        },
      };

    case ASSIGN_COUPON_RULE_DATE_TO:
      return {
        ...state,
        couponRule: {
          ...state.couponRule,
          dateTo: action.payload.dateTo,
        },
      };

    case ASSIGN_COUPON_RULE_AMOUNT_TYPE:
      return {
        ...state,
        couponRule: {
          ...state.couponRule,
          amountType: action.payload.amountType,
          amount: null,
        },
      };

    case ASSIGN_COUPON_RULE_AMOUNT:
      return {
        ...state,
        couponRule: {
          ...state.couponRule,
          amount: action.payload.amount,
        },
      };

    case ASSIGN_COUPON_RULE_LIMIT_PAYMENT:
      return {
        ...state,
        couponRule: {
          ...state.couponRule,
          limitPayment: action.payload.limitPayment,
        },
      };

    case ASSIGN_COUPON_RULE_IS_MULTIPLE:
      return {
        ...state,
        couponRule: {
          ...state.couponRule,
          isMultiple: action.payload.isMultiple,
        },
      };

    case ASSIGN_COUPON_RULE_IS_ACTIVE:
      return {
        ...state,
        couponRule: {
          ...state.couponRule,
          isActive: action.payload.isActive,
        },
      };

    case ASSIGN_COUPON_RULE_IS_PUBLIC:
      return {
        ...state,
        couponRule: {
          ...state.couponRule,
          isPublic: action.payload.isPublic,
        },
      };

    case NEW_COUPON_RULE:
      return {
        ...state,
        loading: false,
        couponRule: {
          dateType: FIXED_DATE,
          amountType: FIXED_AMOUNT,
          isMultiple: false,
          isPublic: true,
          isActive: true,
          dateFrom: moment().startOf('day').unix(),
          dateTo: moment().endOf('day').unix(),
        }
      }

    default:
      return { ...state };
  }
};

export default couponRuleReducers;
