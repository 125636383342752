import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_PACK_DECLARATION_API,
  INDEX_PACK_DECLARATIONS_API,
  FETCH_PACK_DECLARATION_API,
  DELETE_PACK_DECLARATION_API,
  UPDATE_PACK_DECLARATION_API
} from "api/packDeclaration/constants";

export const apiListPackDeclarations = () => {
  const { url, method } = INDEX_PACK_DECLARATIONS_API;

  return api({
    method,
    url,
  });
};

export const apiCreatePackDeclaration = ({ packDeclaration }) => {
  const { url, method } = CREATE_PACK_DECLARATION_API;

  return api({
    method,
    url,
    data: packDeclarationParams({ packDeclaration }),
  });
};

export const apiFetchPackDeclaration = ({ packDeclarationId }) => {
  const { url, method } = FETCH_PACK_DECLARATION_API;

  return api({
    method,
    url: generateRoute(url, packDeclarationId),
  });
};

export const apiUpdatePackDeclaration = ({ packDeclaration }) => {
  const { url, method } = UPDATE_PACK_DECLARATION_API;

  return api({
    method,
    url: generateRoute(url, packDeclaration.id),
    data: packDeclarationParams({ packDeclaration }),
  });
};

export const apiDeletePackDeclaration = ({ packDeclaration }) => {
  const { url, method } = DELETE_PACK_DECLARATION_API;

  return api({
    method,
    url: generateRoute(url, packDeclaration.id),
  });
};

const packDeclarationParams = ({ packDeclaration }) => {
  const {} = packDeclaration;

  return {
  }
};
