export const INIT_ORDER_SEA_FREIGHT = 'INIT_ORDER_SEA_FREIGHT';

export const FETCH_ORDER_SEA_FREIGHT = 'FETCH_ORDER_SEA_FREIGHT';
export const FETCH_ORDER_SEA_FREIGHT_SUCCESS = 'FETCH_ORDER_SEA_FREIGHT_SUCCESS';
export const FETCH_ORDER_SEA_FREIGHT_FAILED = 'FETCH_ORDER_SEA_FREIGHT_FAILED';

export const CREATE_ORDER_SEA_FREIGHT = 'CREATE_ORDER_SEA_FREIGHT';
export const CREATE_ORDER_SEA_FREIGHT_SUCCESS = 'CREATE_ORDER_SEA_FREIGHT_SUCCESS';
export const CREATE_ORDER_SEA_FREIGHT_FAILED = 'CREATE_ORDER_SEA_FREIGHT_FAILED';

export const UPDATE_ORDER_SEA_FREIGHT = 'UPDATE_ORDER_SEA_FREIGHT';
export const UPDATE_ORDER_SEA_FREIGHT_SUCCESS = 'UPDATE_ORDER_SEA_FREIGHT_SUCCESS';
export const UPDATE_ORDER_SEA_FREIGHT_FAILED = 'UPDATE_ORDER_SEA_FREIGHT_FAILED';

export const DELETE_ORDER_SEA_FREIGHT = 'DELETE_ORDER_SEA_FREIGHT';
export const DELETE_ORDER_SEA_FREIGHT_SUCCESS = 'DELETE_ORDER_SEA_FREIGHT_SUCCESS';
export const DELETE_ORDER_SEA_FREIGHT_FAILED = 'DELETE_ORDER_SEA_FREIGHT_FAILED';

export const SPLIT_ORDER_SEA_FREIGHT = 'SPLIT_ORDER_SEA_FREIGHT';

export const MERGE_ORDER_SEA_FREIGHTS = 'MERGE_ORDER_SEA_FREIGHTS';

export const ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_NAME = 'ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_NAME';
export const ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_MOBILE = 'ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_MOBILE';
export const ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_EMAIL = 'ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_EMAIL';
export const ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_COUNTRY = 'ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_COUNTRY';
export const ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_CITY = 'ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_CITY';
export const ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_STATE = 'ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_STATE';
export const ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_ADDRESS = 'ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_ADDRESS';
export const ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_POSTCODE = 'ASSIGN_ORDER_SEA_FREIGHT_RECEIPT_POSTCODE';
export const ASSIGN_ORDER_SEA_FREIGHT_NOTE = 'ASSIGN_ORDER_SEA_FREIGHT_NOTE';
export const ASSIGN_ORDER_SEA_FREIGHT_TITLE = 'ASSIGN_ORDER_SEA_FREIGHT_TITLE';
export const ASSIGN_ORDER_SEA_FREIGHT_LENGTH = 'ASSIGN_ORDER_SEA_FREIGHT_LENGTH';
export const ASSIGN_ORDER_SEA_FREIGHT_WIDTH = 'ASSIGN_ORDER_SEA_FREIGHT_WIDTH';
export const ASSIGN_ORDER_SEA_FREIGHT_HEIGHT = 'ASSIGN_ORDER_SEA_FREIGHT_HEIGHT';
export const ASSIGN_ORDER_SEA_FREIGHT_GROSS_WEIGHT = 'ASSIGN_ORDER_SEA_FREIGHT_GROSS_WEIGHT';
export const ASSIGN_ORDER_SEA_FREIGHT_STATUS = 'ASSIGN_ORDER_SEA_FREIGHT_STATUS';
export const ASSIGN_ORDER_SEA_FREIGHT_DISCOUNT = 'ASSIGN_ORDER_SEA_FREIGHT_DISCOUNT';
export const ASSIGN_ORDER_SEA_FREIGHT_ADDITIONAL_FEE = 'ASSIGN_ORDER_SEA_FREIGHT_ADDITIONAL_FEE';

export const ASSIGN_ORDER_SEA_FREIGHT_TRACKING_NUMBER = 'ASSIGN_ORDER_SEA_FREIGHT_TRACKING_NUMBER';
export const ASSIGN_ORDER_SEA_FREIGHT_INTERNATIONAL_WAYBILL_NUMBER = 'ASSIGN_ORDER_SEA_FREIGHT_INTERNATIONAL_WAYBILL_NUMBER';
export const ASSIGN_ORDER_SEA_FREIGHT_VOLUME_WEIGHT = 'ASSIGN_ORDER_SEA_FREIGHT_VOLUME_WEIGHT';
export const ASSIGN_ORDER_SEA_FREIGHT_BILLABLE_WEIGHT = 'ASSIGN_ORDER_SEA_FREIGHT_BILLABLE_WEIGHT';
export const ASSIGN_ORDER_SEA_FREIGHT_DISCOUNT_RATE = 'ASSIGN_ORDER_SEA_FREIGHT_DISCOUNT_RATE';
export const ASSIGN_ORDER_SEA_FREIGHT_DEDUCTION_AMOUNT = 'ASSIGN_ORDER_SEA_FREIGHT_DEDUCTION_AMOUNT';
export const ASSIGN_ORDER_SEA_FREIGHT_QUOTATION = 'ASSIGN_ORDER_SEA_FREIGHT_QUOTATION';
export const ASSIGN_ORDER_SEA_FREIGHT_ESTIMATED_COST = 'ASSIGN_ORDER_SEA_FREIGHT_ESTIMATED_COST';
export const ASSIGN_ORDER_SEA_FREIGHT_ORDER_FEE = 'ASSIGN_ORDER_SEA_FREIGHT_ORDER_FEE';
export const ASSIGN_ORDER_SEA_FREIGHT_EXPORT_DATE = 'ASSIGN_ORDER_SEA_FREIGHT_EXPORT_DATE';
export const ASSIGN_ORDER_SEA_FREIGHT_DELIVERY_OR_PICK_UP = 'ASSIGN_ORDER_SEA_FREIGHT_DELIVERY_OR_PICK_UP';

export const TRANSFER_ORDER_SEA_FREIGHT = 'TRANSFER_ORDER_SEA_FREIGHT';
export const TRANSFER_ORDER_SEA_FREIGHT_SUCCESS = 'TRANSFER_ORDER_SEA_FREIGHT_SUCCESS';
export const TRANSFER_ORDER_SEA_FREIGHT_FAILED = 'TRANSFER_ORDER_SEA_FREIGHT_FAILED';

export const DOWNLOAD_ORDER_SEA_FREIGHT = 'DOWNLOAD_ORDER_SEA_FREIGHT';
