
export const CREATE_WECHAT_NOTICE_API = {
  url: `/wechat_notices`,
  method: 'post'
};

export const INDEX_WECHAT_NOTICES_API = {
  url: `/wechat_notices`,
  method: 'get',
};

export const FETCH_WECHAT_NOTICE_API = {
  url: `/wechat_notices/:id`,
  method: 'get',
};

export const UPDATE_WECHAT_NOTICE_API = {
  url: `/wechat_notices/:id`,
  method: 'put'
};

export const DELETE_WECHAT_NOTICE_API = {
  url: `/wechat_notices/:id`,
  method: 'delete'
};
