import {
  INIT_LINE_ITEMS,
  LIST_LINE_ITEMS,
  LIST_LINE_ITEMS_FAILED,
  LIST_LINE_ITEMS_SUCCESS
} from "redux/model/lineItems/lineItems/constants";

export const initLineItems = () => ({
  type: INIT_LINE_ITEMS,
  payload: {}
});

export const listLineItems = () => ({
  type: LIST_LINE_ITEMS,
  payload: {}
});

export const listLineItemsSuccess = ({ lineItems }) => ({
  type: LIST_LINE_ITEMS_SUCCESS,
  payload: { lineItems }
});

export const listLineItemsFailed = ({ error }) => ({
  type: LIST_LINE_ITEMS_FAILED,
  payload: { error }
});
