
export const CREATE_CURRENCY_EXCHANGE_API = {
  url: `/currencyExchanges`,
  method: 'post'
};

export const INDEX_CURRENCY_EXCHANGES_API = {
  url: `/currencyExchanges`,
  method: 'get',
};

export const FETCH_CURRENCY_EXCHANGE_API = {
  url: `/currencyExchanges/:id`,
  method: 'get',
};

export const UPDATE_CURRENCY_EXCHANGE_API = {
  url: `/currencyExchanges/:id`,
  method: 'put'
};

export const DELETE_CURRENCY_EXCHANGE_API = {
  url: `/currencyExchanges/:id`,
  method: 'delete'
};
