import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_WECHAT_NOTICE_API,
  INDEX_WECHAT_NOTICES_API,
  FETCH_WECHAT_NOTICE_API,
  DELETE_WECHAT_NOTICE_API,
  UPDATE_WECHAT_NOTICE_API
} from "api/wechatNotice/constants";

export const apiListWechatNotices = () => {
  const { url, method } = INDEX_WECHAT_NOTICES_API;

  return api({
    method,
    url,
  });
};

export const apiCreateWechatNotice = ({ wechatNotice }) => {
  const { url, method } = CREATE_WECHAT_NOTICE_API;

  return api({
    method,
    url,
    data: wechatNoticeParams({ wechatNotice }),
  });
};

export const apiFetchWechatNotice = ({ wechatNoticeId }) => {
  const { url, method } = FETCH_WECHAT_NOTICE_API;

  return api({
    method,
    url: generateRoute(url, wechatNoticeId),
  });
};

export const apiUpdateWechatNotice = ({ wechatNotice }) => {
  const { url, method } = UPDATE_WECHAT_NOTICE_API;

  return api({
    method,
    url: generateRoute(url, wechatNotice.id),
    data: wechatNoticeParams({ wechatNotice }),
  });
};

export const apiDeleteWechatNotice = ({ wechatNotice }) => {
  const { url, method } = DELETE_WECHAT_NOTICE_API;

  return api({
    method,
    url: generateRoute(url, wechatNotice.id),
  });
};

const wechatNoticeParams = ({ wechatNotice }) => {
  const {
    title,
    description,
    active,
  } = wechatNotice;

  return {
    wechatNotice: {
      title,
      description,
      active,
    }
  }
};
