import _ from 'lodash';
import {
  CREATE_WECHAT_NOTICE_SUCCESS,
  DELETE_WECHAT_NOTICE_SUCCESS,
  UPDATE_WECHAT_NOTICE_SUCCESS
} from "redux/model/wechatNotices/wechatNotice/constants";
import {
  INIT_WECHAT_NOTICES,
  LIST_WECHAT_NOTICES,
  LIST_WECHAT_NOTICES_FAILED,
  LIST_WECHAT_NOTICES_SUCCESS
} from "redux/model/wechatNotices/wechatNotices/constants";

const INIT_STATE = {
  wechatNotices: {},
  loading: true,
  error: null,
};

const wechatNoticesReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_WECHAT_NOTICES:
      return INIT_STATE;

    case LIST_WECHAT_NOTICES:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_WECHAT_NOTICES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        wechatNotices: _.mapKeys(action.payload.wechatNotices, 'id'),
      };

    case LIST_WECHAT_NOTICES_FAILED:
      return {
        ...state,
        wechatNotices: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_WECHAT_NOTICE_SUCCESS:
      return {
        ...state,
        wechatNotices: {
          [action.payload.wechatNotice.id]: action.payload.wechatNotice,
          ...state.wechatNotices,
        },
      };

    case UPDATE_WECHAT_NOTICE_SUCCESS:
      return {
        ...state,
        wechatNotices: {
          ...state.wechatNotices,
          [action.payload.wechatNotice.id]: action.payload.wechatNotice,
        },
      };

    case DELETE_WECHAT_NOTICE_SUCCESS:
      return {
        ...state,
        wechatNotices: _.omit(state.wechatNotices, action.payload.wechatNotice.id)
      };

    default:
      return { ...state };
  }
};

export default wechatNoticesReducers;
