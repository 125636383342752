import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_LINE_ITEM_DECLARATION_API,
  INDEX_LINE_ITEM_DECLARATIONS_API,
  FETCH_LINE_ITEM_DECLARATION_API,
  DELETE_LINE_ITEM_DECLARATION_API,
  UPDATE_LINE_ITEM_DECLARATION_API
} from "api/lineItemDeclaration/constants";

export const apiListLineItemDeclarations = () => {
  const { url, method } = INDEX_LINE_ITEM_DECLARATIONS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateLineItemDeclaration = ({ lineItemDeclaration }) => {
  const { url, method } = CREATE_LINE_ITEM_DECLARATION_API;

  return api({
    method,
    url,
    data: lineItemDeclarationParams({ lineItemDeclaration }),
  });
};

export const apiFetchLineItemDeclaration = ({ lineItemDeclarationId }) => {
  const { url, method } = FETCH_LINE_ITEM_DECLARATION_API;

  return api({
    method,
    url: generateRoute(url, lineItemDeclarationId),
  });
};

export const apiUpdateLineItemDeclaration = ({ lineItemDeclaration }) => {
  const { url, method } = UPDATE_LINE_ITEM_DECLARATION_API;

  return api({
    method,
    url: generateRoute(url, lineItemDeclaration.id),
    data: lineItemDeclarationParams({ lineItemDeclaration }),
  });
};

export const apiDeleteLineItemDeclaration = ({ lineItemDeclaration }) => {
  const { url, method } = DELETE_LINE_ITEM_DECLARATION_API;

  return api({
    method,
    url: generateRoute(url, lineItemDeclaration.id),
  });
};

const lineItemDeclarationParams = ({ lineItemDeclaration }) => {
  const {} = lineItemDeclaration;

  return {
  }
};
