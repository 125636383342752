import { api, extractIdFrom, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_COUPON_RULE_API,
  INDEX_COUPON_RULES_API,
  FETCH_COUPON_RULE_API,
  DELETE_COUPON_RULE_API,
  UPDATE_COUPON_RULE_API, BATCH_REDEEM_COUPON_RULE_API
} from "api/couponRule/constants";
import _ from 'lodash';

export const apiListCouponRules = () => {
  const { url, method } = INDEX_COUPON_RULES_API;

  return api({
    method,
    url,
  });
};

export const apiCreateCouponRule = ({ couponRule }) => {
  const { url, method } = CREATE_COUPON_RULE_API;

  return api({
    method,
    url,
    data: couponRuleParams({ couponRule }),
  });
};

export const apiFetchCouponRule = ({ couponRuleId }) => {
  const { url, method } = FETCH_COUPON_RULE_API;

  return api({
    method,
    url: generateRoute(url, couponRuleId),
  });
};

export const apiUpdateCouponRule = ({ couponRule }) => {
  const { url, method } = UPDATE_COUPON_RULE_API;

  return api({
    method,
    url: generateRoute(url, couponRule.id),
    data: couponRuleParams({ couponRule }),
  });
};

export const apiDeleteCouponRule = ({ couponRule }) => {
  const { url, method } = DELETE_COUPON_RULE_API;

  return api({
    method,
    url: generateRoute(url, couponRule.id),
  });
};

export const apiBatchRedeemCouponRule = ({ couponRule, clients }) => {
  const { url, method } = BATCH_REDEEM_COUPON_RULE_API;

  return api({
    method,
    url: generateRoute(url, couponRule.id),
    data: {
      clientIds: _.map(clients, (client) => {
        return extractIdFrom(client.id)
      }),
    }
  });
};

const couponRuleParams = ({ couponRule }) => {
  const {
    name,
    code,
    dateType,
    dateFrom,
    dateTo,
    amountType,
    amount,
    limitPayment,
    isMultiple,
    isActive,
    isPublic,
  } = couponRule;

  return {
    name,
    code,
    dateType,
    dateFrom,
    dateTo,
    amountType,
    amount,
    limitPayment,
    isMultiple,
    isActive,
    isPublic,
  }
};
