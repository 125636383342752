import {
  FETCH_RECEIPT_ADDRESS,
  FETCH_RECEIPT_ADDRESS_FAILED,
  FETCH_RECEIPT_ADDRESS_SUCCESS,
  INIT_RECEIPT_ADDRESS
} from "redux/model/receiptAddresses/receiptAddress/constants";

const INIT_STATE = {
  receiptAddress: {},
  loading: true,
  error: null,
};

const receiptAddressReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_RECEIPT_ADDRESS:
      return INIT_STATE;

    case FETCH_RECEIPT_ADDRESS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_RECEIPT_ADDRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        receiptAddress: action.payload.receiptAddress,
      };

    case FETCH_RECEIPT_ADDRESS_FAILED:
      return {
        ...state,
        receiptAddress: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default receiptAddressReducers;
