
export const CREATE_PACK_API = {
  url: `/packs`,
  method: 'post'
};

export const INDEX_PACKS_API = {
  url: `/packs`,
  method: 'get',
};

export const FETCH_PACK_API = {
  url: `/packs/:id`,
  method: 'get',
};

export const UPDATE_PACK_API = {
  url: `/packs/:id`,
  method: 'put'
};

export const DELETE_PACK_API = {
  url: `/packs/:id`,
  method: 'delete'
};

export const SPLIT_PACK_API = {
  url: `/packs/:id/split`,
  method: 'post',
};

export const DOWNLOAD_UNCLAIMED_PACKS_CSV_API = {
  url: `/packs/download_unclaimed_csv`,
  method: 'get',
  responseType: 'arraybuffer',
}