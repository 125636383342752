import {
  ASSIGN_EXPORT_INTERNATIONAL_BATCH_UPLOAD_DATA_FILE,
  CREATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD,
  CREATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_FAILED,
  CREATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS,
  DELETE_EXPORT_INTERNATIONAL_BATCH_UPLOAD,
  DELETE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_FAILED,
  DELETE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS,
  DOWNLOAD_EXPORT_INTERNATIONAL_BATCH_UPLOAD_CSV,
  FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD,
  FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD_FAILED,
  FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS,
  INIT_EXPORT_INTERNATIONAL_BATCH_UPLOAD,
  UPDATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD,
  UPDATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_FAILED,
  UPDATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS
} from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUpload/constants";

export const initExportInternationalBatchUpload = () => ({
  type: INIT_EXPORT_INTERNATIONAL_BATCH_UPLOAD,
  payload: {}
});

export const createExportInternationalBatchUpload = ({ exportInternationalBatchUpload }) => ({
  type: CREATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD,
  payload: { exportInternationalBatchUpload }
});

export const createExportInternationalBatchUploadSuccess = ({ exportInternationalBatchUpload }) => ({
  type: CREATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS,
  payload: { exportInternationalBatchUpload }
});

export const createExportInternationalBatchUploadFailed = ({ error }) => ({
  type: CREATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_FAILED,
  payload: { error }
});

export const fetchExportInternationalBatchUpload = ({ exportInternationalBatchUploadId }) => ({
  type: FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD,
  payload: { exportInternationalBatchUploadId }
});

export const fetchExportInternationalBatchUploadSuccess = ({ exportInternationalBatchUpload }) => ({
  type: FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS,
  payload: { exportInternationalBatchUpload }
});

export const fetchExportInternationalBatchUploadFailed = ({ error }) => ({
  type: FETCH_EXPORT_INTERNATIONAL_BATCH_UPLOAD_FAILED,
  payload: { error }
});

export const updateExportInternationalBatchUpload = ({ exportInternationalBatchUpload }) => ({
  type: UPDATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD,
  payload: { exportInternationalBatchUpload }
});

export const updateExportInternationalBatchUploadSuccess = ({ exportInternationalBatchUpload }) => ({
  type: UPDATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS,
  payload: { exportInternationalBatchUpload }
});

export const updateExportInternationalBatchUploadFailed = ({ error }) => ({
  type: UPDATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_FAILED,
  payload: { error }
});

export const deleteExportInternationalBatchUpload = ({ exportInternationalBatchUpload }) => ({
  type: DELETE_EXPORT_INTERNATIONAL_BATCH_UPLOAD,
  payload: { exportInternationalBatchUpload }
});

export const deleteExportInternationalBatchUploadSuccess = ({ exportInternationalBatchUpload }) => ({
  type: DELETE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS,
  payload: { exportInternationalBatchUpload }
});

export const deleteExportInternationalBatchUploadFailed = ({ error }) => ({
  type: DELETE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_FAILED,
  payload: { error }
});

export const assignExportInternationalBatchUploadDataFile = ({ dataFile }) => ({
  type: ASSIGN_EXPORT_INTERNATIONAL_BATCH_UPLOAD_DATA_FILE,
  payload: { dataFile }
});

export const downloadExportInternationalBatchUploadCsv = () => ({
  type: DOWNLOAD_EXPORT_INTERNATIONAL_BATCH_UPLOAD_CSV,
  payload: {}
});
