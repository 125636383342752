import {
  FETCH_RECEIVED_NOTICE,
  FETCH_RECEIVED_NOTICE_FAILED,
  FETCH_RECEIVED_NOTICE_SUCCESS,
  INIT_RECEIVED_NOTICE
} from "redux/model/receivedNotices/receivedNotice/constants";

const INIT_STATE = {
  receivedNotice: {},
  loading: true,
  error: null,
};

const receivedNoticeReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_RECEIVED_NOTICE:
      return INIT_STATE;

    case FETCH_RECEIVED_NOTICE:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_RECEIVED_NOTICE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        receivedNotice: action.payload.receivedNotice,
      };

    case FETCH_RECEIVED_NOTICE_FAILED:
      return {
        ...state,
        receivedNotice: {},
        loading: false,
        error: action.payload.error,
      };

    default:
      return { ...state };
  }
};

export default receivedNoticeReducers;
