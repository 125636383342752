import _ from 'lodash';
import {
  CREATE_SEA_FREIGHT_CONSIGNEE_SUCCESS,
  DELETE_SEA_FREIGHT_CONSIGNEE_SUCCESS,
  UPDATE_SEA_FREIGHT_CONSIGNEE_SUCCESS
} from "redux/model/seaFreightConsignees/seaFreightConsignee/constants";
import {
  INIT_SEA_FREIGHT_CONSIGNEES,
  LIST_SEA_FREIGHT_CONSIGNEES,
  LIST_SEA_FREIGHT_CONSIGNEES_FAILED,
  LIST_SEA_FREIGHT_CONSIGNEES_SUCCESS
} from "redux/model/seaFreightConsignees/seaFreightConsignees/constants";

const INIT_STATE = {
  seaFreightConsignees: {},
  loading: true,
  error: null,
};

const seaFreightConsigneesReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_SEA_FREIGHT_CONSIGNEES:
      return INIT_STATE;

    case LIST_SEA_FREIGHT_CONSIGNEES:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_SEA_FREIGHT_CONSIGNEES_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        seaFreightConsignees: _.mapKeys(action.payload.seaFreightConsignees, 'id'),
      };

    case LIST_SEA_FREIGHT_CONSIGNEES_FAILED:
      return {
        ...state,
        seaFreightConsignees: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_SEA_FREIGHT_CONSIGNEE_SUCCESS:
      return {
        ...state,
        seaFreightConsignees: {
          [action.payload.seaFreightConsignee.id]: action.payload.seaFreightConsignee,
          ...state.seaFreightConsignees,
        },
      };

    case UPDATE_SEA_FREIGHT_CONSIGNEE_SUCCESS:
      return {
        ...state,
        seaFreightConsignees: {
          ...state.seaFreightConsignees,
          [action.payload.seaFreightConsignee.id]: action.payload.seaFreightConsignee,
        },
      };

    case DELETE_SEA_FREIGHT_CONSIGNEE_SUCCESS:
      return {
        ...state,
        seaFreightConsignees: _.omit(state.seaFreightConsignees, action.payload.seaFreightConsignee.id)
      };

    default:
      return { ...state };
  }
};

export default seaFreightConsigneesReducers;
