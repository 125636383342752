import {
  INIT_EXPORT_INTERNATIONAL_BATCH_UPLOADS,
  LIST_EXPORT_INTERNATIONAL_BATCH_UPLOADS,
  LIST_EXPORT_INTERNATIONAL_BATCH_UPLOADS_FAILED,
  LIST_EXPORT_INTERNATIONAL_BATCH_UPLOADS_SUCCESS
} from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUploads/constants";

export const initExportInternationalBatchUploads = () => ({
  type: INIT_EXPORT_INTERNATIONAL_BATCH_UPLOADS,
  payload: {}
});

export const listExportInternationalBatchUploads = () => ({
  type: LIST_EXPORT_INTERNATIONAL_BATCH_UPLOADS,
  payload: {}
});

export const listExportInternationalBatchUploadsSuccess = ({ exportInternationalBatchUploads }) => ({
  type: LIST_EXPORT_INTERNATIONAL_BATCH_UPLOADS_SUCCESS,
  payload: { exportInternationalBatchUploads }
});

export const listExportInternationalBatchUploadsFailed = ({ error }) => ({
  type: LIST_EXPORT_INTERNATIONAL_BATCH_UPLOADS_FAILED,
  payload: { error }
});
