import _ from 'lodash';
import {
  CREATE_ORDER_SEA_FREIGHT_SUCCESS,
  DELETE_ORDER_SEA_FREIGHT_SUCCESS, TRANSFER_ORDER_SEA_FREIGHT_SUCCESS,
  UPDATE_ORDER_SEA_FREIGHT_SUCCESS
} from "redux/model/orderSeaFreights/orderSeaFreight/constants";
import {
  INIT_ORDER_SEA_FREIGHTS,
  LIST_ORDER_SEA_FREIGHTS, LIST_ORDER_SEA_FREIGHTS_CAN_BE_MERGED,
  LIST_ORDER_SEA_FREIGHTS_FAILED,
  LIST_ORDER_SEA_FREIGHTS_SUCCESS,
  LIST_UNCONTAINED_ORDER_SEA_FREIGHTS
} from "redux/model/orderSeaFreights/orderSeaFreights/constants";
import {
  NEW_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT
} from "redux/model/seaFreightContainers/seaFreightContainer/constants";

const INIT_STATE = {
  orderSeaFreights: {},
  totalSize: 0,
  loading: true,
  error: null,
};

const orderSeaFreightsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_ORDER_SEA_FREIGHTS:
      return INIT_STATE;

    case LIST_ORDER_SEA_FREIGHTS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_UNCONTAINED_ORDER_SEA_FREIGHTS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_ORDER_SEA_FREIGHTS_CAN_BE_MERGED:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_ORDER_SEA_FREIGHTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        orderSeaFreights: _.mapKeys(action.payload.orderSeaFreights, 'id'),
        totalSize: action.payload.totalSize,
      };

    case LIST_ORDER_SEA_FREIGHTS_FAILED:
      return {
        ...state,
        orderSeaFreights: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_ORDER_SEA_FREIGHT_SUCCESS:
      return {
        ...state,
        orderSeaFreights: {
          [action.payload.orderSeaFreight.id]: action.payload.orderSeaFreight,
          ...state.orderSeaFreights,
        },
      };

    case UPDATE_ORDER_SEA_FREIGHT_SUCCESS:
      return {
        ...state,
        orderSeaFreights: {
          ...state.orderSeaFreights,
          [action.payload.orderSeaFreight.id]: action.payload.orderSeaFreight,
        },
      };

    case DELETE_ORDER_SEA_FREIGHT_SUCCESS:
      return {
        ...state,
        orderSeaFreights: _.omit(state.orderSeaFreights, action.payload.orderSeaFreight.id)
      };

    case NEW_SEA_FREIGHT_CONTAINER_ORDER_SEA_FREIGHT:
      return {
        ...state,
        orderSeaFreights: {
          ...state.orderSeaFreights,
          [action.payload.dummyId]: { id: action.payload.dummyId },
        },
      };

    case TRANSFER_ORDER_SEA_FREIGHT_SUCCESS:
      return {
        ...state,
        orderSeaFreights: _.omit(state.orderSeaFreights, action.payload.orderSeaFreight.id),
      };

    default:
      return { ...state };
  }
};

export default orderSeaFreightsReducers;
