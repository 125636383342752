import {
  ASSIGN_WECHAT_NOTICE_ACTIVE,
  ASSIGN_WECHAT_NOTICE_DESCRIPTION,
  ASSIGN_WECHAT_NOTICE_PUBLIC_DATE,
  ASSIGN_WECHAT_NOTICE_TITLE,
  CREATE_WECHAT_NOTICE,
  CREATE_WECHAT_NOTICE_FAILED,
  CREATE_WECHAT_NOTICE_SUCCESS,
  DELETE_WECHAT_NOTICE, DELETE_WECHAT_NOTICE_FAILED, DELETE_WECHAT_NOTICE_SUCCESS,
  FETCH_WECHAT_NOTICE,
  FETCH_WECHAT_NOTICE_FAILED,
  FETCH_WECHAT_NOTICE_SUCCESS,
  INIT_WECHAT_NOTICE,
  UPDATE_WECHAT_NOTICE,
  UPDATE_WECHAT_NOTICE_FAILED,
  UPDATE_WECHAT_NOTICE_SUCCESS
} from "redux/model/wechatNotices/wechatNotice/constants";

export const initWechatNotice = () => ({
  type: INIT_WECHAT_NOTICE,
  payload: {}
});

export const createWechatNotice = ({ wechatNotice }) => ({
  type: CREATE_WECHAT_NOTICE,
  payload: { wechatNotice }
});

export const createWechatNoticeSuccess = ({ wechatNotice }) => ({
  type: CREATE_WECHAT_NOTICE_SUCCESS,
  payload: { wechatNotice }
});

export const createWechatNoticeFailed = ({ error }) => ({
  type: CREATE_WECHAT_NOTICE_FAILED,
  payload: { error }
});

export const fetchWechatNotice = ({ wechatNoticeId }) => ({
  type: FETCH_WECHAT_NOTICE,
  payload: { wechatNoticeId }
});

export const fetchWechatNoticeSuccess = ({ wechatNotice }) => ({
  type: FETCH_WECHAT_NOTICE_SUCCESS,
  payload: { wechatNotice }
});

export const fetchWechatNoticeFailed = ({ error }) => ({
  type: FETCH_WECHAT_NOTICE_FAILED,
  payload: { error }
});

export const updateWechatNotice = ({ wechatNotice }) => ({
  type: UPDATE_WECHAT_NOTICE,
  payload: { wechatNotice }
});

export const updateWechatNoticeSuccess = ({ wechatNotice }) => ({
  type: UPDATE_WECHAT_NOTICE_SUCCESS,
  payload: { wechatNotice }
});

export const updateWechatNoticeFailed = ({ error }) => ({
  type: UPDATE_WECHAT_NOTICE_FAILED,
  payload: { error }
});

export const deleteWechatNotice = ({ wechatNotice }) => ({
  type: DELETE_WECHAT_NOTICE,
  payload: { wechatNotice }
});

export const deleteWechatNoticeSuccess = ({ wechatNotice }) => ({
  type: DELETE_WECHAT_NOTICE_SUCCESS,
  payload: { wechatNotice }
});

export const deleteWechatNoticeFailed = ({ error }) => ({
  type: DELETE_WECHAT_NOTICE_FAILED,
  payload: { error }
});

export const assignWechatNoticeTitle = ({ title }) => ({
  type: ASSIGN_WECHAT_NOTICE_TITLE,
  payload: { title }
});

export const assignWechatNoticeDescription = ({ description }) => ({
  type: ASSIGN_WECHAT_NOTICE_DESCRIPTION,
  payload: { description }
});

export const assignWechatNoticePublicDate = ({ publicDate }) => ({
  type: ASSIGN_WECHAT_NOTICE_PUBLIC_DATE,
  payload: { publicDate }
});

export const assignWechatNoticeActive = ({ active }) => ({
  type: ASSIGN_WECHAT_NOTICE_ACTIVE,
  payload: { active }
});