import { all, call, delay, fork, put, takeEvery, takeLeading } from 'redux-saga/effects';
import { generateErrorMessage, generateRoute, history, throwFrontError } from "@tmatt-tech/react_common";
import {
  BATCH_REDEEM_COUPON_RULE,
  CREATE_COUPON_RULE,
  DELETE_COUPON_RULE,
  FETCH_COUPON_RULE,
  UPDATE_COUPON_RULE
} from "redux/model/couponRules/couponRule/constants";
import {
  apiBatchRedeemCouponRule,
  apiCreateCouponRule,
  apiDeleteCouponRule,
  apiFetchCouponRule,
  apiUpdateCouponRule
} from "api/couponRule/apiCouponRule";
import {
  batchRedeemCouponRuleFailed,
  batchRedeemCouponRuleSuccess,
  createCouponRuleFailed,
  createCouponRuleSuccess,
  deleteCouponRuleFailed,
  deleteCouponRuleSuccess,
  fetchCouponRuleFailed,
  fetchCouponRuleSuccess,
  initCouponRule,
  updateCouponRuleFailed,
  updateCouponRuleSuccess
} from "redux/model/couponRules/couponRule/couponRuleActions";
import {
  toastDefault,
  toastDeleted,
  toastError,
  toastSaved
} from "@tmatt-tech/react_common/dist/redux/ui/toast/toastActions";
import { SAGA_WAITING_TIME } from "globalConstants";
import {
  closeFirstLayerModal,
  closeSecondLayerModal, startFirstLayerModalListening,
  startSecondLayerModalListening
} from "@tmatt-tech/react_common/dist/redux/ui/modal/modalActions";
import { INDEX_COUPON_RULES_PATH } from "routes/couponRule/constants";


function* couponRuleSaga() {
  yield all([
    fork(watchFetchCouponRule),
    fork(watchUpdateCouponRule),
    fork(watchCreateCouponRule),
    fork(watchDeleteCouponRule),
    fork(watchBatchRedeemCouponRule),
  ]);
}

// Fetch CouponRule
export function* watchFetchCouponRule() {
  yield takeEvery(FETCH_COUPON_RULE, sagaFetchCouponRule);
}

export function* sagaFetchCouponRule({ payload }) {
  try {
    const response = yield call(apiFetchCouponRule, payload);

    const { couponRule } = response.data;
    yield put(fetchCouponRuleSuccess({ couponRule }));

  } catch (e) {
    yield put(fetchCouponRuleFailed(generateErrorMessage(e)));
    yield put(toastError({}));

    throwFrontError(e);
  }
}

// Create CouponRule
export function* watchCreateCouponRule() {
  yield takeLeading(CREATE_COUPON_RULE, sagaCreateCouponRule);
}

export function* sagaCreateCouponRule({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiCreateCouponRule, payload);

    const { couponRule } = response.data;
    yield put(createCouponRuleSuccess({ couponRule }));
    yield put(toastSaved({ name: `CouponRule` }));


    history.push(generateRoute(INDEX_COUPON_RULES_PATH));
    yield put(initCouponRule());


  } catch (e) {
    yield put(createCouponRuleFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Update CouponRule
export function* watchUpdateCouponRule() {
  yield takeLeading(UPDATE_COUPON_RULE, sagaUpdateCouponRule);
}

export function* sagaUpdateCouponRule({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiUpdateCouponRule, payload);

    const { couponRule } = response.data;
    yield put(updateCouponRuleSuccess({ couponRule }));
    yield put(toastSaved({ name: 'CouponRule' }));
    history.push(generateRoute(INDEX_COUPON_RULES_PATH));
    yield put(initCouponRule());

  } catch (e) {
    yield put(updateCouponRuleFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Delete CouponRule
export function* watchDeleteCouponRule() {
  yield takeLeading(DELETE_COUPON_RULE, sagaDeleteCouponRule);
}

export function* sagaDeleteCouponRule({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiDeleteCouponRule, payload);

    const { couponRule } = response.data;
    yield put(deleteCouponRuleSuccess({ couponRule }));
    yield put(toastDeleted({ name: 'CouponRule' }));
    yield put(startSecondLayerModalListening());
    yield put(closeSecondLayerModal());

  } catch (e) {
    yield put(deleteCouponRuleFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}

// Batch Redeem CouponRule
export function* watchBatchRedeemCouponRule() {
  yield takeLeading(BATCH_REDEEM_COUPON_RULE, sagaBatchRedeemCouponRule);
}

export function* sagaBatchRedeemCouponRule({ payload }) {
  try {
    yield delay(SAGA_WAITING_TIME);
    const response = yield call(apiBatchRedeemCouponRule, payload);

    yield put(toastDefault({ message: 'Coupons has been sent to clients' }))
    yield put(batchRedeemCouponRuleSuccess());
    yield put(startFirstLayerModalListening());
    yield put(closeFirstLayerModal());

  } catch (e) {
    yield put(batchRedeemCouponRuleFailed(generateErrorMessage(e)));
    yield put(toastError({}));
    throwFrontError(e);
  }
}


export default couponRuleSaga;
