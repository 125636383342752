import { extractClassNameFrom } from "@tmatt-tech/react_common";

export const extractRailsClassNameFrom = (string) => {
  if (string) {
    const reduxName = extractClassNameFrom(string);
    return railsClassNameMapper[reduxName];
  }

  return null;
};

export const railsClassNameMapper = {
  noticeChannel: 'Notification::Message::NoticeChannel',
  pack: 'Pack',
  orderPack: 'Order::Base',
  client: 'Client',
  transAlipay: 'Transaction::Alipay',
  transBankAud: 'Transaction::BankAud',
  transBankCny: 'Transaction::BankCny',
  orderDeclaration: 'OrderDeclaration',
};
