import {
  INIT_COUPON_RULES,
  LIST_COUPON_RULES,
  LIST_COUPON_RULES_FAILED,
  LIST_COUPON_RULES_SUCCESS
} from "redux/model/couponRules/couponRules/constants";

export const initCouponRules = () => ({
  type: INIT_COUPON_RULES,
  payload: {}
});

export const listCouponRules = () => ({
  type: LIST_COUPON_RULES,
  payload: {}
});

export const listCouponRulesSuccess = ({ couponRules }) => ({
  type: LIST_COUPON_RULES_SUCCESS,
  payload: { couponRules }
});

export const listCouponRulesFailed = ({ error }) => ({
  type: LIST_COUPON_RULES_FAILED,
  payload: { error }
});
