export const INIT_LINE_ATTACHMENT = 'INIT_LINE_ATTACHMENT';

export const FETCH_LINE_ATTACHMENT = 'FETCH_LINE_ATTACHMENT';
export const FETCH_LINE_ATTACHMENT_SUCCESS = 'FETCH_LINE_ATTACHMENT_SUCCESS';
export const FETCH_LINE_ATTACHMENT_FAILED = 'FETCH_LINE_ATTACHMENT_FAILED';

export const CREATE_LINE_ATTACHMENT = 'CREATE_LINE_ATTACHMENT';
export const CREATE_PACK_ATTACHMENT = 'CREATE_PACK_ATTACHMENT';
export const CREATE_ORDER_ATTACHMENT = 'CREATE_ORDER_ATTACHMENT';
export const CREATE_LINE_ATTACHMENT_SUCCESS = 'CREATE_LINE_ATTACHMENT_SUCCESS';
export const CREATE_LINE_ATTACHMENT_FAILED = 'CREATE_LINE_ATTACHMENT_FAILED';

export const UPDATE_LINE_ATTACHMENT = 'UPDATE_LINE_ATTACHMENT';
export const UPDATE_LINE_ATTACHMENT_SUCCESS = 'UPDATE_LINE_ATTACHMENT_SUCCESS';
export const UPDATE_LINE_ATTACHMENT_FAILED = 'UPDATE_LINE_ATTACHMENT_FAILED';

export const DELETE_LINE_ATTACHMENT = 'DELETE_LINE_ATTACHMENT';
export const DELETE_LINE_ATTACHMENT_SUCCESS = 'DELETE_LINE_ATTACHMENT_SUCCESS';
export const DELETE_LINE_ATTACHMENT_FAILED = 'DELETE_LINE_ATTACHMENT_FAILED';

export const DOWNLOAD_LINE_ATTACHMENT = 'DOWNLOAD_LINE_ATTACHMENT';