import {
  INIT_CLIENTS,
  LIST_CLIENTS,
  LIST_CLIENTS_FAILED,
  LIST_CLIENTS_SUCCESS
} from "redux/model/clients/clients/constants";

export const initClients = () => ({
  type: INIT_CLIENTS,
  payload: {}
});

export const listClients = () => ({
  type: LIST_CLIENTS,
  payload: {}
});

export const listClientsSuccess = ({ clients }) => ({
  type: LIST_CLIENTS_SUCCESS,
  payload: { clients }
});

export const listClientsFailed = ({ error }) => ({
  type: LIST_CLIENTS_FAILED,
  payload: { error }
});
