import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_EXPORT_INTERNATIONAL_BATCH_UPLOADS } from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUploads/constants";
import { apiListExportInternationalBatchUploads } from "api/exportInternationalBatchUpload/apiExportInternationalBatchUpload";
import { listExportInternationalBatchUploadsFailed, listExportInternationalBatchUploadsSuccess } from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUploads/exportInternationalBatchUploadsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* exportInternationalBatchUploadsSaga() {
  yield all([
    fork(watchListExportInternationalBatchUploads),
  ]);
}

// List ExportInternationalBatchUploads
export function* watchListExportInternationalBatchUploads() {
  yield takeEvery(LIST_EXPORT_INTERNATIONAL_BATCH_UPLOADS, sagaListExportInternationalBatchUploads);
}

export function* sagaListExportInternationalBatchUploads() {
  try {
    const response = yield call(apiListExportInternationalBatchUploads);

    const { exportInternationalBatchUploads } = response.data;

    yield put(listExportInternationalBatchUploadsSuccess({ exportInternationalBatchUploads }));

  } catch (e) {
    yield put(listExportInternationalBatchUploadsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default exportInternationalBatchUploadsSaga;
