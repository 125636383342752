import _ from 'lodash';
import {
  CREATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS,
  DELETE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS,
  UPDATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS
} from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUpload/constants";
import {
  INIT_EXPORT_INTERNATIONAL_BATCH_UPLOADS,
  LIST_EXPORT_INTERNATIONAL_BATCH_UPLOADS,
  LIST_EXPORT_INTERNATIONAL_BATCH_UPLOADS_FAILED,
  LIST_EXPORT_INTERNATIONAL_BATCH_UPLOADS_SUCCESS
} from "redux/model/exportInternationalBatchUploads/exportInternationalBatchUploads/constants";

const INIT_STATE = {
  exportInternationalBatchUploads: {},
  loading: true,
  error: null,
};

const exportInternationalBatchUploadsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_EXPORT_INTERNATIONAL_BATCH_UPLOADS:
      return INIT_STATE;

    case LIST_EXPORT_INTERNATIONAL_BATCH_UPLOADS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_EXPORT_INTERNATIONAL_BATCH_UPLOADS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        exportInternationalBatchUploads: _.mapKeys(action.payload.exportInternationalBatchUploads, 'id'),
      };

    case LIST_EXPORT_INTERNATIONAL_BATCH_UPLOADS_FAILED:
      return {
        ...state,
        exportInternationalBatchUploads: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS:
      return {
        ...state,
        exportInternationalBatchUploads: {
          [action.payload.exportInternationalBatchUpload.id]: action.payload.exportInternationalBatchUpload,
          ...state.exportInternationalBatchUploads,
        },
      };

    case UPDATE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS:
      return {
        ...state,
        exportInternationalBatchUploads: {
          ...state.exportInternationalBatchUploads,
          [action.payload.exportInternationalBatchUpload.id]: action.payload.exportInternationalBatchUpload,
        },
      };

    case DELETE_EXPORT_INTERNATIONAL_BATCH_UPLOAD_SUCCESS:
      return {
        ...state,
        exportInternationalBatchUploads: _.omit(state.exportInternationalBatchUploads, action.payload.exportInternationalBatchUpload.id)
      };

    default:
      return { ...state };
  }
};

export default exportInternationalBatchUploadsReducers;
