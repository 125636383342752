import {
  CREATE_RECEIVED_NOTICE,
  CREATE_RECEIVED_NOTICE_FAILED,
  CREATE_RECEIVED_NOTICE_SUCCESS,
  DELETE_RECEIVED_NOTICE, DELETE_RECEIVED_NOTICE_FAILED, DELETE_RECEIVED_NOTICE_SUCCESS,
  FETCH_RECEIVED_NOTICE,
  FETCH_RECEIVED_NOTICE_FAILED,
  FETCH_RECEIVED_NOTICE_SUCCESS,
  INIT_RECEIVED_NOTICE, RECEIVE_NOTICE_SUCCESS,
  UPDATE_RECEIVED_NOTICE,
  UPDATE_RECEIVED_NOTICE_FAILED, UPDATE_RECEIVED_NOTICE_READ,
  UPDATE_RECEIVED_NOTICE_SUCCESS
} from "redux/model/receivedNotices/receivedNotice/constants";

export const initReceivedNotice = () => ({
  type: INIT_RECEIVED_NOTICE,
  payload: {}
});

export const createReceivedNotice = ({ receivedNotice }) => ({
  type: CREATE_RECEIVED_NOTICE,
  payload: { receivedNotice }
});

export const createReceivedNoticeSuccess = ({ receivedNotice }) => ({
  type: CREATE_RECEIVED_NOTICE_SUCCESS,
  payload: { receivedNotice }
});

export const createReceivedNoticeFailed = ({ error }) => ({
  type: CREATE_RECEIVED_NOTICE_FAILED,
  payload: { error }
});

export const fetchReceivedNotice = ({ receivedNoticeId }) => ({
  type: FETCH_RECEIVED_NOTICE,
  payload: { receivedNoticeId }
});

export const fetchReceivedNoticeSuccess = ({ receivedNotice }) => ({
  type: FETCH_RECEIVED_NOTICE_SUCCESS,
  payload: { receivedNotice }
});

export const fetchReceivedNoticeFailed = ({ error }) => ({
  type: FETCH_RECEIVED_NOTICE_FAILED,
  payload: { error }
});

export const updateReceivedNotice = ({ receivedNotice }) => ({
  type: UPDATE_RECEIVED_NOTICE,
  payload: { receivedNotice }
});

export const updateReceivedNoticeSuccess = ({ receivedNotice }) => ({
  type: UPDATE_RECEIVED_NOTICE_SUCCESS,
  payload: { receivedNotice }
});

export const updateReceivedNoticeFailed = ({ error }) => ({
  type: UPDATE_RECEIVED_NOTICE_FAILED,
  payload: { error }
});

export const deleteReceivedNotice = ({ receivedNotice }) => ({
  type: DELETE_RECEIVED_NOTICE,
  payload: { receivedNotice }
});

export const deleteReceivedNoticeSuccess = ({ receivedNotice }) => ({
  type: DELETE_RECEIVED_NOTICE_SUCCESS,
  payload: { receivedNotice }
});

export const deleteReceivedNoticeFailed = ({ error }) => ({
  type: DELETE_RECEIVED_NOTICE_FAILED,
  payload: { error }
});

export const receiveNoticeSuccess = ({ receivedNotice }) => ({
  type: RECEIVE_NOTICE_SUCCESS,
  payload: { receivedNotice }
});

export const updateReceivedNoticeRead = ({ receivedNotice }) => ({
  type: UPDATE_RECEIVED_NOTICE_READ,
  payload: { receivedNotice }
});