import {
  ASSIGN_ORDER_DECLARATION_BY,
  ASSIGN_ORDER_DECLARATION_CONSIGNEE_ADDRESS,
  ASSIGN_ORDER_DECLARATION_CONSIGNEE_EMAIL,
  ASSIGN_ORDER_DECLARATION_CONSIGNEE_ID,
  ASSIGN_ORDER_DECLARATION_CONSIGNEE_NAME,
  ASSIGN_ORDER_DECLARATION_CONSIGNEE_PHONE,
  ASSIGN_ORDER_DECLARATION_DATE_PACK_TIME,
  ASSIGN_ORDER_DECLARATION_DELIVERY_AND_PAYMENT_TERM,
  ASSIGN_ORDER_DECLARATION_EXPORTER_ADDRESS,
  ASSIGN_ORDER_DECLARATION_EXPORTER_EMAIL,
  ASSIGN_ORDER_DECLARATION_EXPORTER_ID,
  ASSIGN_ORDER_DECLARATION_EXPORTER_NAME,
  ASSIGN_ORDER_DECLARATION_EXPORTER_PHONE,
  ASSIGN_ORDER_DECLARATION_FROM_ATR,
  ASSIGN_ORDER_DECLARATION_NO_AND_DATE_OF_INVOICE,
  ASSIGN_ORDER_DECLARATION_NO_AND_DATE_OF_PACKING_LIST,
  ASSIGN_ORDER_DECLARATION_REMARKS,
  ASSIGN_ORDER_DECLARATION_TO,
  FETCH_ORDER_DECLARATION,
  FETCH_ORDER_DECLARATION_FAILED,
  FETCH_ORDER_DECLARATION_SUCCESS,
  INIT_ORDER_DECLARATION
} from "redux/model/orderDeclarations/orderDeclaration/constants";
import _ from "lodash";
import {
  NEW_LINE_ITEM_DECLARATION,
  PULL_LINE_ITEM_DECLARATION
} from "redux/model/lineItemDeclarations/lineItemDeclaration/constants";
import { isNew } from "@tmatt-tech/react_common";

const INIT_STATE = {
  orderDeclaration: {},
  loading: true,
  error: null,
};

const orderDeclarationReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_ORDER_DECLARATION:
      return INIT_STATE;

    case FETCH_ORDER_DECLARATION:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_ORDER_DECLARATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        orderDeclaration: action.payload.orderDeclaration,
      };

    case FETCH_ORDER_DECLARATION_FAILED:
      return {
        ...state,
        orderDeclaration: {},
        loading: false,
        error: action.payload.error,
      };

    case NEW_LINE_ITEM_DECLARATION:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          lineItemDeclarationIds: [
            ...state.orderDeclaration.lineItemDeclarationIds,
            action.payload.lineItemDeclaration.id,
          ]
        }
      };

    case PULL_LINE_ITEM_DECLARATION:
      if (isNew(action.payload.lineItemDeclaration)) {
        return {
          ...state,
          orderDeclaration: {
            ...state.orderDeclaration,
            lineItemDeclarationIds: _.without(
              state.orderDeclaration.lineItemDeclarationIds,
              action.payload.lineItemDeclaration.id
            ),
          },
        };
      }

      return {
        ...state,
      };

    case ASSIGN_ORDER_DECLARATION_EXPORTER_NAME:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          exporterName: action.payload.exporterName,
        },
      };

    case ASSIGN_ORDER_DECLARATION_EXPORTER_ADDRESS:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          exporterAddress: action.payload.exporterAddress,
        },
      };

    case ASSIGN_ORDER_DECLARATION_EXPORTER_PHONE:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          exporterPhone: action.payload.exporterPhone,
        },
      };

    case ASSIGN_ORDER_DECLARATION_EXPORTER_EMAIL:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          exporterEmail: action.payload.exporterEmail,
        },
      };

    case ASSIGN_ORDER_DECLARATION_CONSIGNEE_NAME:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          consigneeName: action.payload.consigneeName,
        },
      };

    case ASSIGN_ORDER_DECLARATION_CONSIGNEE_ADDRESS:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          consigneeAddress: action.payload.consigneeAddress,
        },
      };

    case ASSIGN_ORDER_DECLARATION_CONSIGNEE_PHONE:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          consigneePhone: action.payload.consigneePhone,
        },
      };

    case ASSIGN_ORDER_DECLARATION_CONSIGNEE_EMAIL:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          consigneeEmail: action.payload.consigneeEmail,
        },
      };

    case ASSIGN_ORDER_DECLARATION_EXPORTER_ID:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          exporterId: action.payload.exporterId,
        },
      };

    case ASSIGN_ORDER_DECLARATION_CONSIGNEE_ID:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          consigneeId: action.payload.consigneeId,
        },
      };

    case ASSIGN_ORDER_DECLARATION_FROM_ATR:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          fromAtr: action.payload.fromAtr,
        },
      };

    case ASSIGN_ORDER_DECLARATION_DATE_PACK_TIME:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          datePackTime: action.payload.datePackTime,
        },
      };

    case ASSIGN_ORDER_DECLARATION_REMARKS:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          remarks: action.payload.remarks,
        },
      };

    case ASSIGN_ORDER_DECLARATION_TO:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          to: action.payload.to,
        },
      };

    case ASSIGN_ORDER_DECLARATION_BY:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          by: action.payload.by,
        },
      };

    case ASSIGN_ORDER_DECLARATION_DELIVERY_AND_PAYMENT_TERM:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          deliveryAndPaymentTerm: action.payload.deliveryAndPaymentTerm,
        },
      };

    case ASSIGN_ORDER_DECLARATION_NO_AND_DATE_OF_INVOICE:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          noAndDateOfInvoice: action.payload.noAndDateOfInvoice,
        },
      };

    case ASSIGN_ORDER_DECLARATION_NO_AND_DATE_OF_PACKING_LIST:
      return {
        ...state,
        orderDeclaration: {
          ...state.orderDeclaration,
          noAndDateOfPackingList: action.payload.noAndDateOfPackingList,
        },
      };

    default:
      return { ...state };
  }
};

export default orderDeclarationReducers;
