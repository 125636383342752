// @flow

import { combineReducers } from 'redux';

import layout from '@tmatt-tech/react_common/dist/redux/layout/reducers';
import appMenu from '@tmatt-tech/react_common/dist/redux/appMenu/reducers';
import auth from '@tmatt-tech/react_common/dist/redux/auth/authReducers';
import ui from 'redux/ui/uiReducers';
import model from 'redux/model/modelReducers';

export default combineReducers({
    auth,
    appMenu,
    layout,
    ui,
    model,
});
