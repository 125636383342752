import {
  INIT_RECEIPT_ADDRESSES,
  LIST_RECEIPT_ADDRESSES,
  LIST_RECEIPT_ADDRESSES_FAILED,
  LIST_RECEIPT_ADDRESSES_SUCCESS
} from "redux/model/receiptAddresses/receiptAddresses/constants";

export const initReceiptAddresses = () => ({
  type: INIT_RECEIPT_ADDRESSES,
  payload: {}
});

export const listReceiptAddresses = () => ({
  type: LIST_RECEIPT_ADDRESSES,
  payload: {}
});

export const listReceiptAddressesSuccess = ({ receiptAddresses }) => ({
  type: LIST_RECEIPT_ADDRESSES_SUCCESS,
  payload: { receiptAddresses }
});

export const listReceiptAddressesFailed = ({ error }) => ({
  type: LIST_RECEIPT_ADDRESSES_FAILED,
  payload: { error }
});
