import { all } from 'redux-saga/effects';
import uiToastSaga from "@tmatt-tech/react_common/dist/redux/ui/toast/toastSaga";

function* uiSaga() {
  yield all([
    uiToastSaga(),
  ]);
}

export default uiSaga;
