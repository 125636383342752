export const INIT_SEA_FREIGHT_CONTAINER_DECLARATION = 'INIT_SEA_FREIGHT_CONTAINER_DECLARATION';

export const FETCH_SEA_FREIGHT_CONTAINER_DECLARATION = 'FETCH_SEA_FREIGHT_CONTAINER_DECLARATION';
export const FETCH_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS = 'FETCH_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS';
export const FETCH_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED = 'FETCH_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED';

export const CREATE_SEA_FREIGHT_CONTAINER_DECLARATION = 'CREATE_SEA_FREIGHT_CONTAINER_DECLARATION';
export const CREATE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS = 'CREATE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS';
export const CREATE_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED = 'CREATE_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED';

export const UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION = 'UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION';
export const UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS = 'UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS';
export const UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED = 'UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED';

export const DELETE_SEA_FREIGHT_CONTAINER_DECLARATION = 'DELETE_SEA_FREIGHT_CONTAINER_DECLARATION';
export const DELETE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS = 'DELETE_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS';
export const DELETE_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED = 'DELETE_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED';

export const ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_VESSEL_NAME = 'ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_VESSEL_NAME';
export const ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_VOYAGE_NUMBER = 'ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_VOYAGE_NUMBER';
export const ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_CONSIGNMENT_IDENTIFIER = 'ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_CONSIGNMENT_IDENTIFIER';
export const ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_NUMERICAL_LINK = 'ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_NUMERICAL_LINK';