import {
  DOWNLOAD_DISPATCHING_ORDER_SEA_FREIGHTS,
  DOWNLOAD_PROCESSING_ORDER_SEA_FREIGHTS,
  INIT_ORDER_SEA_FREIGHTS,
  LIST_ORDER_SEA_FREIGHTS,
  LIST_ORDER_SEA_FREIGHTS_CAN_BE_MERGED,
  LIST_ORDER_SEA_FREIGHTS_FAILED,
  LIST_ORDER_SEA_FREIGHTS_SUCCESS,
  LIST_UNCONTAINED_ORDER_SEA_FREIGHTS
} from "redux/model/orderSeaFreights/orderSeaFreights/constants";

export const initOrderSeaFreights = () => ({
  type: INIT_ORDER_SEA_FREIGHTS,
  payload: {}
});

export const listOrderSeaFreights = ({ page, query }) => ({
  type: LIST_ORDER_SEA_FREIGHTS,
  payload: { page, query }
});

export const listOrderSeaFreightsSuccess = ({ orderSeaFreights, totalSize }) => ({
  type: LIST_ORDER_SEA_FREIGHTS_SUCCESS,
  payload: { orderSeaFreights, totalSize }
});

export const listOrderSeaFreightsFailed = ({ error }) => ({
  type: LIST_ORDER_SEA_FREIGHTS_FAILED,
  payload: { error }
});

export const listUncontainedOrderSeaFreights = () => ({
  type: LIST_UNCONTAINED_ORDER_SEA_FREIGHTS,
  payload: {}
});

export const listOrderSeaFreightsCanBeMerged = ({ client }) => ({
  type: LIST_ORDER_SEA_FREIGHTS_CAN_BE_MERGED,
  payload: { client }
});

export const downloadProcessingOrderSeaFreights = () => ({
  type: DOWNLOAD_PROCESSING_ORDER_SEA_FREIGHTS,
  payload: {}
});

export const downloadDispatchingOrderSeaFreights = () => ({
  type: DOWNLOAD_DISPATCHING_ORDER_SEA_FREIGHTS,
  payload: {}
});