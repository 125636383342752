import {
  INIT_LINE_ITEM_DECLARATIONS,
  LIST_LINE_ITEM_DECLARATIONS,
  LIST_LINE_ITEM_DECLARATIONS_FAILED,
  LIST_LINE_ITEM_DECLARATIONS_SUCCESS
} from "redux/model/lineItemDeclarations/lineItemDeclarations/constants";

export const initLineItemDeclarations = () => ({
  type: INIT_LINE_ITEM_DECLARATIONS,
  payload: {}
});

export const listLineItemDeclarations = () => ({
  type: LIST_LINE_ITEM_DECLARATIONS,
  payload: {}
});

export const listLineItemDeclarationsSuccess = ({ lineItemDeclarations }) => ({
  type: LIST_LINE_ITEM_DECLARATIONS_SUCCESS,
  payload: { lineItemDeclarations }
});

export const listLineItemDeclarationsFailed = ({ error }) => ({
  type: LIST_LINE_ITEM_DECLARATIONS_FAILED,
  payload: { error }
});
