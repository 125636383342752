import {
  DOWNLOAD_DISPATCHING_ORDERS_CSV,
  DOWNLOAD_PROCESSING_ORDERS_CSV,
  DOWNLOAD_ALL_ORDERS_CSV,
  INIT_ORDER_PACKS,
  LIST_ORDER_PACKS,
  LIST_ORDER_PACKS_CAN_BE_MERGED,
  LIST_ORDER_PACKS_FAILED,
  LIST_ORDER_PACKS_SUCCESS,
  DOWNLOAD_ALL_ORDERS_CSV_SUCCESS,
  DOWNLOAD_ALL_ORDERS_CSV_FAILED,

} from "redux/model/orderPacks/orderPacks/constants";

export const initOrderPacks = () => ({
  type: INIT_ORDER_PACKS,
  payload: {}
});

export const listOrderPacks = ({ page, query }) => ({
  type: LIST_ORDER_PACKS,
  payload: { page, query }
});

export const listOrderPacksCanBeMerged = ({ client }) => ({
  type: LIST_ORDER_PACKS_CAN_BE_MERGED,
  payload: { client }
});

export const listOrderPacksSuccess = ({ orderPacks, totalSize }) => ({
  type: LIST_ORDER_PACKS_SUCCESS,
  payload: { orderPacks, totalSize }
});

export const listOrderPacksFailed = ({ error }) => ({
  type: LIST_ORDER_PACKS_FAILED,
  payload: { error }
});

export const downloadAllOrdersCsv = () => ({
  type: DOWNLOAD_ALL_ORDERS_CSV,
  payload: {}
});

export const downloadAllOrdersCsvSuccess = () => ({
  type: DOWNLOAD_ALL_ORDERS_CSV_SUCCESS,
  payload: {}
});

export const downloadAllOrdersCsvFailed = ({ error }) => ({
  type: DOWNLOAD_ALL_ORDERS_CSV_FAILED,
  payload: { error }
});


export const downloadProcessingOrdersCsv = () => ({
  type: DOWNLOAD_PROCESSING_ORDERS_CSV,
  payload: {}
});

export const downloadDispatchingOrdersCsv = () => ({
  type: DOWNLOAD_DISPATCHING_ORDERS_CSV,
  payload: {}
});