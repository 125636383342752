import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_SEA_FREIGHT_EXPORTER_API,
  INDEX_SEA_FREIGHT_EXPORTERS_API,
  FETCH_SEA_FREIGHT_EXPORTER_API,
  DELETE_SEA_FREIGHT_EXPORTER_API,
  UPDATE_SEA_FREIGHT_EXPORTER_API
} from "api/seaFreightExporter/constants";

export const apiListSeaFreightExporters = () => {
  const { url, method } = INDEX_SEA_FREIGHT_EXPORTERS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateSeaFreightExporter = ({ seaFreightExporter }) => {
  const { url, method } = CREATE_SEA_FREIGHT_EXPORTER_API;

  return api({
    method,
    url,
    data: seaFreightExporterParams({ seaFreightExporter }),
  });
};

export const apiFetchSeaFreightExporter = ({ seaFreightExporterId }) => {
  const { url, method } = FETCH_SEA_FREIGHT_EXPORTER_API;

  return api({
    method,
    url: generateRoute(url, seaFreightExporterId),
  });
};

export const apiUpdateSeaFreightExporter = ({ seaFreightExporter }) => {
  const { url, method } = UPDATE_SEA_FREIGHT_EXPORTER_API;

  return api({
    method,
    url: generateRoute(url, seaFreightExporter.id),
    data: seaFreightExporterParams({ seaFreightExporter }),
  });
};

export const apiDeleteSeaFreightExporter = ({ seaFreightExporter }) => {
  const { url, method } = DELETE_SEA_FREIGHT_EXPORTER_API;

  return api({
    method,
    url: generateRoute(url, seaFreightExporter.id),
  });
};

const seaFreightExporterParams = ({ seaFreightExporter }) => {
  const {} = seaFreightExporter;

  return {
  }
};
