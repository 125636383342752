
export const CREATE_ORDER_PACK_API = {
  url: `/order/packs`,
  method: 'post'
};

export const INDEX_ORDER_PACKS_API = {
  url: `/order/packs`,
  method: 'get',
};

export const LIST_ORDER_PACKS_CAM_BE_MERGED_API = {
  url: `/order/packs/list_can_be_merged`,
  method: 'post',
};

export const FETCH_ORDER_PACK_API = {
  url: `/order/packs/:id`,
  method: 'get',
};

export const UPDATE_ORDER_PACK_API = {
  url: `/order/packs/:id`,
  method: 'put'
};

export const DELETE_ORDER_PACK_API = {
  url: `/order/packs/:id`,
  method: 'delete'
};

export const SPLIT_ORDER_PACK_API = {
  url: `/order/packs/:id/split`,
  method: 'post',
};

export const MERGE_ORDER_PACKS_API = {
  url: `/order/packs/:id/merge`,
  method: 'post',
};

export const DOWNLOAD_ALL_ORDERS_CSV_API = {
  url: `/order/packs/download_all_summary_csv`,
  method: 'get',
  responseType: 'arraybuffer',
}

export const DOWNLOAD_PROCESSING_ORDERS_CSV_API = {
  url: `/order/packs/download_processing_summary_csv`,
  method: 'get',
  responseType: 'arraybuffer',
}

export const DOWNLOAD_DISPATCHING_ORDERS_CSV_API = {
  url: `/order/packs/download_dispatching_summary_csv`,
  method: 'get',
  responseType: 'arraybuffer',
}