import _ from 'lodash';
import {
  CREATE_ORDER_DECLARATION_SUCCESS,
  DELETE_ORDER_DECLARATION_SUCCESS,
  UPDATE_ORDER_DECLARATION_SUCCESS
} from "redux/model/orderDeclarations/orderDeclaration/constants";
import {
  INIT_ORDER_DECLARATIONS,
  LIST_ORDER_DECLARATIONS,
  LIST_ORDER_DECLARATIONS_FAILED,
  LIST_ORDER_DECLARATIONS_SUCCESS
} from "redux/model/orderDeclarations/orderDeclarations/constants";

const INIT_STATE = {
  orderDeclarations: {},
  loading: true,
  error: null,
};

const orderDeclarationsReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_ORDER_DECLARATIONS:
      return INIT_STATE;

    case LIST_ORDER_DECLARATIONS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_ORDER_DECLARATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        orderDeclarations: _.mapKeys(action.payload.orderDeclarations, 'id'),
      };

    case LIST_ORDER_DECLARATIONS_FAILED:
      return {
        ...state,
        orderDeclarations: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_ORDER_DECLARATION_SUCCESS:
      return {
        ...state,
        orderDeclarations: {
          [action.payload.orderDeclaration.id]: action.payload.orderDeclaration,
          ...state.orderDeclarations,
        },
      };

    case UPDATE_ORDER_DECLARATION_SUCCESS:
      return {
        ...state,
        orderDeclarations: {
          ...state.orderDeclarations,
          [action.payload.orderDeclaration.id]: action.payload.orderDeclaration,
        },
      };

    case DELETE_ORDER_DECLARATION_SUCCESS:
      return {
        ...state,
        orderDeclarations: _.omit(state.orderDeclarations, action.payload.orderDeclaration.id)
      };

    default:
      return { ...state };
  }
};

export default orderDeclarationsReducers;
