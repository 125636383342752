import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { DOWNLOAD_UNCLAIMED_PACKS_CSV, LIST_PACKS } from "redux/model/packs/packs/constants";
import { apiDownloadUnclaimedPacksCsv, apiListPacks } from "api/pack/apiPack";
import { listPacksFailed, listPacksSuccess } from "redux/model/packs/packs/packsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";
import fileDownload from "js-file-download";


function* packsSaga() {
  yield all([
    fork(watchListPacks),
    fork(watchDownloadUnclaimedPacksCsv),
  ]);
}

// List Packs
export function* watchListPacks() {
  yield takeEvery(LIST_PACKS, sagaListPacks);
}

export function* sagaListPacks({ payload }) {
  try {
    const response = yield call(apiListPacks, payload);

    const { packs, totalSize } = response.data;

    yield put(listPacksSuccess({ packs, totalSize }));

  } catch (e) {
    yield put(listPacksFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}

// Download Unclaimed Packs Csv
export function* watchDownloadUnclaimedPacksCsv() {
  yield takeEvery(DOWNLOAD_UNCLAIMED_PACKS_CSV, sagaDownloadUnclaimedPacksCsv);
}

export function* sagaDownloadUnclaimedPacksCsv({ payload }) {
  try {
    const response = yield call(apiDownloadUnclaimedPacksCsv, payload);
    yield call(
      fileDownload,
      response.data,
      `异常件.csv`
    );

  } catch (e) {
    console.log(generateErrorMessage(e));
    throwFrontError(e);
  }
}


export default packsSaga;
