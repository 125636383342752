import _ from 'lodash';
import {
  CREATE_SEA_FREIGHT_CONTAINER_SUCCESS,
  DELETE_SEA_FREIGHT_CONTAINER_SUCCESS,
  UPDATE_SEA_FREIGHT_CONTAINER_SUCCESS
} from "redux/model/seaFreightContainers/seaFreightContainer/constants";
import {
  INIT_SEA_FREIGHT_CONTAINERS,
  LIST_SEA_FREIGHT_CONTAINERS,
  LIST_SEA_FREIGHT_CONTAINERS_FAILED,
  LIST_SEA_FREIGHT_CONTAINERS_SUCCESS
} from "redux/model/seaFreightContainers/seaFreightContainers/constants";

const INIT_STATE = {
  seaFreightContainers: {},
  loading: true,
  error: null,
};

const seaFreightContainersReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_SEA_FREIGHT_CONTAINERS:
      return INIT_STATE;

    case LIST_SEA_FREIGHT_CONTAINERS:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case LIST_SEA_FREIGHT_CONTAINERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        seaFreightContainers: _.mapKeys(action.payload.seaFreightContainers, 'id'),
      };

    case LIST_SEA_FREIGHT_CONTAINERS_FAILED:
      return {
        ...state,
        seaFreightContainers: {},
        loading: false,
        error: action.payload.error,
      };

    case CREATE_SEA_FREIGHT_CONTAINER_SUCCESS:
      return {
        ...state,
        seaFreightContainers: {
          [action.payload.seaFreightContainer.id]: action.payload.seaFreightContainer,
          ...state.seaFreightContainers,
        },
      };

    case UPDATE_SEA_FREIGHT_CONTAINER_SUCCESS:
      return {
        ...state,
        seaFreightContainers: {
          ...state.seaFreightContainers,
          [action.payload.seaFreightContainer.id]: action.payload.seaFreightContainer,
        },
      };

    case DELETE_SEA_FREIGHT_CONTAINER_SUCCESS:
      return {
        ...state,
        seaFreightContainers: _.omit(state.seaFreightContainers, action.payload.seaFreightContainer.id)
      };

    default:
      return { ...state };
  }
};

export default seaFreightContainersReducers;
