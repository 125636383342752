export const INIT_EXPORT_DIMENSION_BATCH_UPLOAD = 'INIT_EXPORT_DIMENSION_BATCH_UPLOAD';

export const FETCH_EXPORT_DIMENSION_BATCH_UPLOAD = 'FETCH_EXPORT_DIMENSION_BATCH_UPLOAD';
export const FETCH_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS = 'FETCH_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS';
export const FETCH_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED = 'FETCH_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED';

export const CREATE_EXPORT_DIMENSION_BATCH_UPLOAD = 'CREATE_EXPORT_DIMENSION_BATCH_UPLOAD';
export const CREATE_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS = 'CREATE_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS';
export const CREATE_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED = 'CREATE_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED';

export const UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD = 'UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD';
export const UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS = 'UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS';
export const UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED = 'UPDATE_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED';

export const DELETE_EXPORT_DIMENSION_BATCH_UPLOAD = 'DELETE_EXPORT_DIMENSION_BATCH_UPLOAD';
export const DELETE_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS = 'DELETE_EXPORT_DIMENSION_BATCH_UPLOAD_SUCCESS';
export const DELETE_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED = 'DELETE_EXPORT_DIMENSION_BATCH_UPLOAD_FAILED';

export const ASSIGN_EXPORT_DIMENSION_BATCH_UPLOAD_DATA_FILE = 'ASSIGN_EXPORT_DIMENSION_BATCH_UPLOAD_DATA_FILE';

export const DOWNLOAD_EXPORT_DIMENSION_BATCH_UPLOAD_CSV = 'DOWNLOAD_EXPORT_DIMENSION_BATCH_UPLOAD_CSV';
