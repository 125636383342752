import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_SEA_FREIGHT_CONTAINER_DECLARATION_API,
  INDEX_SEA_FREIGHT_CONTAINER_DECLARATIONS_API,
  FETCH_SEA_FREIGHT_CONTAINER_DECLARATION_API,
  DELETE_SEA_FREIGHT_CONTAINER_DECLARATION_API,
  UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION_API
} from "api/seaFreightContainerDeclaration/constants";

export const apiListSeaFreightContainerDeclarations = () => {
  const { url, method } = INDEX_SEA_FREIGHT_CONTAINER_DECLARATIONS_API;

  return api({
    method,
    url,
  });
};

export const apiCreateSeaFreightContainerDeclaration = ({ seaFreightContainerDeclaration }) => {
  const { url, method } = CREATE_SEA_FREIGHT_CONTAINER_DECLARATION_API;

  return api({
    method,
    url,
    data: seaFreightContainerDeclarationParams({ seaFreightContainerDeclaration }),
  });
};

export const apiFetchSeaFreightContainerDeclaration = ({ seaFreightContainerDeclarationId }) => {
  const { url, method } = FETCH_SEA_FREIGHT_CONTAINER_DECLARATION_API;

  return api({
    method,
    url: generateRoute(url, seaFreightContainerDeclarationId),
  });
};

export const apiUpdateSeaFreightContainerDeclaration = ({ seaFreightContainerDeclaration }) => {
  const { url, method } = UPDATE_SEA_FREIGHT_CONTAINER_DECLARATION_API;

  return api({
    method,
    url: generateRoute(url, seaFreightContainerDeclaration.id),
    data: seaFreightContainerDeclarationParams({ seaFreightContainerDeclaration }),
  });
};

export const apiDeleteSeaFreightContainerDeclaration = ({ seaFreightContainerDeclaration }) => {
  const { url, method } = DELETE_SEA_FREIGHT_CONTAINER_DECLARATION_API;

  return api({
    method,
    url: generateRoute(url, seaFreightContainerDeclaration.id),
  });
};

const seaFreightContainerDeclarationParams = ({ seaFreightContainerDeclaration }) => {
  const {} = seaFreightContainerDeclaration;

  return {
  }
};
