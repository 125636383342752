import { api, generateRoute } from "@tmatt-tech/react_common";
import {
  CREATE_PAYMENT_API,
  INDEX_PAYMENTS_API,
  FETCH_PAYMENT_API,
  DELETE_PAYMENT_API,
  UPDATE_PAYMENT_API
} from "api/payment/constants";

export const apiListPayments = () => {
  const { url, method } = INDEX_PAYMENTS_API;

  return api({
    method,
    url,
  });
};

export const apiCreatePayment = ({ payment }) => {
  const { url, method } = CREATE_PAYMENT_API;

  return api({
    method,
    url,
    data: paymentParams({ payment }),
  });
};

export const apiFetchPayment = ({ paymentId }) => {
  const { url, method } = FETCH_PAYMENT_API;

  return api({
    method,
    url: generateRoute(url, paymentId),
  });
};

export const apiUpdatePayment = ({ payment }) => {
  const { url, method } = UPDATE_PAYMENT_API;

  return api({
    method,
    url: generateRoute(url, payment.id),
    data: paymentParams({ payment }),
  });
};

export const apiDeletePayment = ({ payment }) => {
  const { url, method } = DELETE_PAYMENT_API;

  return api({
    method,
    url: generateRoute(url, payment.id),
  });
};

const paymentParams = ({ payment }) => {
  const {} = payment;

  return {
  }
};
