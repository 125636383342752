import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { LIST_SEA_FREIGHT_CONTAINER_DECLARATIONS } from "redux/model/seaFreightContainerDeclarations/seaFreightContainerDeclarations/constants";
import { apiListSeaFreightContainerDeclarations } from "api/seaFreightContainerDeclaration/apiSeaFreightContainerDeclaration";
import { listSeaFreightContainerDeclarationsFailed, listSeaFreightContainerDeclarationsSuccess } from "redux/model/seaFreightContainerDeclarations/seaFreightContainerDeclarations/seaFreightContainerDeclarationsActions";
import { generateErrorMessage, throwFrontError } from "@tmatt-tech/react_common";


function* seaFreightContainerDeclarationsSaga() {
  yield all([
    fork(watchListSeaFreightContainerDeclarations),
  ]);
}

// List SeaFreightContainerDeclarations
export function* watchListSeaFreightContainerDeclarations() {
  yield takeEvery(LIST_SEA_FREIGHT_CONTAINER_DECLARATIONS, sagaListSeaFreightContainerDeclarations);
}

export function* sagaListSeaFreightContainerDeclarations() {
  try {
    const response = yield call(apiListSeaFreightContainerDeclarations);

    const { seaFreightContainerDeclarations } = response.data;

    yield put(listSeaFreightContainerDeclarationsSuccess({ seaFreightContainerDeclarations }));

  } catch (e) {
    yield put(listSeaFreightContainerDeclarationsFailed(generateErrorMessage(e)));
    throwFrontError(e);
  }
}


export default seaFreightContainerDeclarationsSaga;
