import {
  ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_CONSIGNMENT_IDENTIFIER,
  ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_NUMERICAL_LINK,
  ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_VESSEL_NAME,
  ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_VOYAGE_NUMBER,
  FETCH_SEA_FREIGHT_CONTAINER_DECLARATION,
  FETCH_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED,
  FETCH_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS,
  INIT_SEA_FREIGHT_CONTAINER_DECLARATION
} from "redux/model/seaFreightContainerDeclarations/seaFreightContainerDeclaration/constants";

const INIT_STATE = {
  seaFreightContainerDeclaration: {},
  loading: true,
  error: null,
};

const seaFreightContainerDeclarationReducers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_SEA_FREIGHT_CONTAINER_DECLARATION:
      return INIT_STATE;

    case FETCH_SEA_FREIGHT_CONTAINER_DECLARATION:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_SEA_FREIGHT_CONTAINER_DECLARATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        seaFreightContainerDeclaration: action.payload.seaFreightContainerDeclaration,
      };

    case FETCH_SEA_FREIGHT_CONTAINER_DECLARATION_FAILED:
      return {
        ...state,
        seaFreightContainerDeclaration: {},
        loading: false,
        error: action.payload.error,
      };

    case ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_VESSEL_NAME:
      return {
        ...state,
        seaFreightContainerDeclaration: {
          ...state.seaFreightContainerDeclaration,
          vesselName: action.payload.vesselName,
        },
      };

    case ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_VOYAGE_NUMBER:
      return {
        ...state,
        seaFreightContainerDeclaration: {
          ...state.seaFreightContainerDeclaration,
          voyageNumber: action.payload.voyageNumber,
        },
      };

    case ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_CONSIGNMENT_IDENTIFIER:
      return {
        ...state,
        seaFreightContainerDeclaration: {
          ...state.seaFreightContainerDeclaration,
          consignmentIdentifier: action.payload.consignmentIdentifier,
        },
      };

    case ASSIGN_SEA_FREIGHT_CONTAINER_DECLARATION_NUMERICAL_LINK:
      return {
        ...state,
        seaFreightContainerDeclaration: {
          ...state.seaFreightContainerDeclaration,
          numericalLink: action.payload.numericalLink,
        },
      };

    default:
      return { ...state };
  }
};

export default seaFreightContainerDeclarationReducers;
